import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const BusinessSearch = lazy(() =>
  import(/* webpackChunkName: "BusinessSearch" */ '../components/index').then(
    ({ BusinessSearch }) => ({
      default: BusinessSearch,
    })
  )
);

const BusinessDetail = lazy(() =>
  import(
    /* webpackChunkName: "BusinessDetail" */ '../components/business-detail'
  ).then(({ BusinessDetail }) => ({
    default: BusinessDetail,
  }))
);

export const BusinessRoutes = [
  {
    component: BusinessSearch,
    isExact: true,
    key: 'businesses',
    path: '/businesses',
    flag: FEATURE_FLAGS.MANAGE_COMMISSION,
  },
  {
    component: BusinessDetail,
    isExact: true,
    key: 'business-detail',
    path: '/businesses/:businessId',
    flag: FEATURE_FLAGS.MANAGE_COMMISSION,
  },
];
