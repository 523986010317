import * as React from 'react';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { BaseDialogDisplayComponent } from 'features/generics/dialogs/base-dialog-display-component';
import { useNotifications } from 'providers/NotificationsProvider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import RoutineNotification from '../../../../../client/images/png/RoutineNotification.png';
import { useHistory } from 'react-router-dom';

const NotificationCard = ({
  notification,
  cancel,
  hideTopCloseButton = false,
}) => {
  const notificationObject = Map.isMap(notification)
    ? notification.toJS()
    : notification;

  const { clearNotification } = useNotifications();

  const history = useHistory();

  let imageUrl = notificationObject.imageUrl;
  let cta = notificationObject.cta;

  if (notificationObject.id.startsWith('routine-')) {
    imageUrl = RoutineNotification;
    const routineId = notificationObject.id.substring('routine-'.length);

    cta = () => {
      cancel();
      history.push(`/treatment-room/routines?routineId=${routineId}`);
    };
  }

  return (
    <>
      <BaseDialogDisplayComponent
        cancel={cancel}
        onClose={() => clearNotification(notificationObject.id)}
        header={notificationObject.title}
        secondaryAction={null}
        hideTopCloseButton={hideTopCloseButton}
        headerProps={{
          titleTypographyProps: {
            variant: 'h5',
          },
        }}
      >
        {imageUrl && (
          <CardMedia
            component="img"
            image={imageUrl}
            alt="notification image"
            sx={{ maxHeight: 400 }}
          />
        )}
        <Typography
          sx={{ my: 2, textAlign: 'center' }}
          variant="body2"
          color="text.secondary"
          dangerouslySetInnerHTML={{ __html: notificationObject.description }}
        />
        {notificationObject.id.startsWith('routine-') && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant="contained" color="primary" onClick={cta}>
              View Routine
            </Button>
          </Box>
        )}
      </BaseDialogDisplayComponent>
    </>
  );
};

NotificationCard.propTypes = {
  notification: PropTypes.object,
  cancel: PropTypes.func,
  hideTopCloseButton: PropTypes.bool,
};

export default NotificationCard;
