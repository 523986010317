export const CREATE_TAG = 'CREATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';
export const EDIT_TAG = 'EDIT_TAG';

export const ADD_PRODUCTS_TO_TAG = 'ADD_PRODUCTS_TO_TAG';
export const DELETE_PRODUCT_TAG = 'DELETE_PRODUCT_TAG';
export const ADD_TAG_TO_PRODUCTS = 'ADD_TAG_TO_PRODUCTS';

export const SEARCH_TAGS = 'SEARCH_TAGS';
export const LOAD_TAG = 'LOAD_TAG';
