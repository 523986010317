import * as productPropTypes from './products';
import * as userPropTypes from './users';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const productSearchFilters = ImmutablePropTypes.contains({
  query: PropTypes.string,
  vendorId: PropTypes.string,
  categoryId: PropTypes.string
});

export const productSearch = ImmutablePropTypes.contains({
  filters: productSearchFilters,
  isSearching: PropTypes.bool.isRequired,
  products: productPropTypes.products,
  productToEdit: productPropTypes.product,
  selectedUser: userPropTypes.user,
  users: userPropTypes.users
});
