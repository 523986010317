import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const EstheticianMessaging = lazy(() =>
  import(
    /* webpackChunkName: "EstheticianMessaging" */ 'components/pages/admin/EstheticianMessaging'
  )
);

export const MessagingRoutes = [
  {
    component: EstheticianMessaging,
    isExact: true,
    key: 'esthetician-messaging',
    path: '/messaging',
    flag: FEATURE_FLAGS.MESSAGING,
  },
];
