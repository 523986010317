import graphql from 'lib/api/graphql';
import ordersLoadTrackingNumbers from '../graphql/load-tracking-numbers';
import createShipment from '../graphql/create-shipment';
import updateShipment from '../graphql/update-shipment';
import deleteShipment from '../graphql/delete-shipment';
import { generateAsyncAction } from 'lib/action-generators';
import {
  ORDERS__DELETE_SHIPMENT,
  ORDERS__LOAD_TRACKING_NUMBERS,
  ORDERS__UPDATE_SHIPMENTS
} from './actions';

export const ShipmentActions = {
  loadTrackingNumbers: () => ({
    type: ORDERS__LOAD_TRACKING_NUMBERS,
    payload: graphql(ordersLoadTrackingNumbers)
  }),

  createShipment: orderId =>
    generateAsyncAction(
      ORDERS__UPDATE_SHIPMENTS,
      { orderId },
      graphql(createShipment, { orderId }),
      'createShipment'
    ),

  updateShipment: (id, shipment) =>
    generateAsyncAction(
      ORDERS__UPDATE_SHIPMENTS,
      { id, shipment },
      graphql(updateShipment, { id, shipment }),
      'updateShipment'
    ),

  deleteShipment: (shipmentId, orderId) =>
    generateAsyncAction(
      ORDERS__DELETE_SHIPMENT,
      { orderId, shipmentId },
      graphql(deleteShipment, { shipmentId }),
      'deleteShipment'
    )
};
