/**
 * @typedef AsyncActionStatus
 * @description An enumeration of possible async action statuses
 * @property {string} REQUEST - The action has been requested
 * @property {string} SUCCESS - The action has been fulfilled
 * @property {string} FAIL - The action has failed
 * @property {string} INTERCEPTED - The action has been intercepted
 */

/** @type {AsyncActionStatus} */
export const asyncActionStatuses = {
  REQUEST: 'request',
  SUCCESS: 'success',
  FAIL: 'fail',
  INTERCEPTED: 'intercepted'
};
