import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Snackbar } from '@mui/material';

/**
 * Create a ToastProvider that allows child components
 * to open a toast that is resoled via a promise.
 */
function CreateToastProvider() {
  const Context = createContext({
    alerts: [],
    open: false,
    show: () => undefined,
    showSuccess: () => undefined,
    showError: () => undefined,
    showInfo: () => undefined,
    showWarning: () => undefined,
  });

  const { Provider } = Context;

  // eslint-disable-next-line react/prop-types
  const ToastProvider = ({ children }) => {
    const [alerts, setAlerts] = useState([]);
    const [open, setOpen] = useState(false);

    const show = alert => {
      if (!alert) {
        throw new Error('Invalid Alert');
      }

      // If the alert is a string, convert it into an object, otherwise, append it.
      if (typeof alert === 'string') {
        // Add the alert to the list
        setAlerts([...alerts, { content: alert }]);
      } else {
        setAlerts([...alerts, alert]);
      }

      setOpen(true);
    };

    const showSuccess = (content, props = {}) =>
      show({ severity: 'success', content, ...props });
    const showError = (content, props = {}) =>
      show({ severity: 'error', content, ...props });
    const showInfo = (content, props = {}) =>
      show({ severity: 'info', content, ...props });
    const showWarning = (content, props = {}) =>
      show({ severity: 'warning', content, ...props });

    // Remove the first alert
    const handleClose = (event, reason) => {
      // Ignore Click Away behaviour
      // See: https://github.com/mui-org/material-ui/issues/5184#issuecomment-569418493
      if (reason === 'clickaway') return;
      const updated = alerts.slice(1);
      setAlerts(updated);
      setOpen(false);

      if (updated.length) {
        setTimeout(() => setOpen(true), 300);
      }
    };

    // TODO: handle multiple Alerts
    return (
      <Provider
        value={{
          alerts,
          show,
          open,
          showSuccess,
          showError,
          showInfo,
          showWarning,
        }}
      >
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ maxWidth: { xs: 1, sm: 300 } }}
        >
          <If condition={alerts.length}>
            <Alert
              severity={alerts[0]?.severity ?? 'info'}
              icon={false}
              action={alerts[0]?.action ?? null}
              onClick={handleClose}
              sx={{
                flex: 1,
                '& .MuiAlert-message': {
                  width: 1,
                },
                ...(alerts[0]?.props?.sx || {}),
              }}
              {...(alerts[0].props || {})}
            >
              {alerts[0].content}
            </Alert>
          </If>
        </Snackbar>
        {children}
      </Provider>
    );
  };

  const useAlerts = () => useContext(Context);

  return {
    ToastProvider,
    useAlerts,
  };
}

const { ToastProvider, useAlerts } = CreateToastProvider();

export { ToastProvider, useAlerts };

/**
 * A wrapper component that can wrap existing class components and allow
 * them to use the alert functions
 * @param Component
 * @returns {function(*)}
 */
// eslint-disable-next-line react/display-name
export const ToastAwareWrapper = Component => props => {
  const { show } = useAlerts(); // eslint-disable-line react-hooks/rules-of-hooks -- This is a false positive, this looks like a callback, but it's actually a wrapped component
  return <Component showAlert={show} {...props} />;
};

ToastAwareWrapper.propTypes = {
  Component: PropTypes.element,
};
