import {
  COMMISSION_PLAN_BULK_UPDATE,
  COMMISSION_PLAN_UPDATE,
} from '../actions';
import { CommissionReducerInitialState } from './initialState';

export const CommissionReducer = (
  state = CommissionReducerInitialState,
  { type, payload, status }
) => {
  switch (type) {
    case COMMISSION_PLAN_UPDATE: {
      if (status !== 'success') return state;
      const { bonuses = [], ...plan } = payload;

      if (!plan) return state;

      return {
        ...state,
        // Add plans to the reducer
        plansById: {
          ...state.plansById,
          [plan.id]: plan,
        },
        // Add Bonuses to the reducer
        planBonusesById: bonuses.reduce((acc, bonus) => {
          acc[bonus.id] = bonus;
          return acc;
        }, state.planBonusesById),
      };
    }

    case COMMISSION_PLAN_BULK_UPDATE: {
      if (status !== 'success') return state;
      const { data } = payload;

      if (!data.length) return state;

      return {
        ...state,
        // Add plans to the reducer
        plansById: data.reduce(
          (acc, plan) => ({ ...acc, [plan.id]: plan }),
          state.plansById
        ),
        // Add Bonuses to the reducer
        planBonusesById: data
          // Convert to a single array of bonuses
          .reduce((acc, { bonuses = [] }) => [...acc, ...bonuses], [])
          // Map the array of bonuses to an object
          .reduce(
            (acc, bonus) => ({ ...acc, [bonus.id]: bonus }),
            state.planBonusesById
          ),
      };
    }

    default:
      return state;
  }
};
