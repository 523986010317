export const APP__START = 'APP__START';
export const APP__START_FULFILLED = `${APP__START}_FULFILLED`;
export const APP__START_PENDING = `${APP__START}_PENDING`;

export const ADDRESS__CREATE = 'ADDRESS__CREATE';
export const ADDRESS__CREATE_FULFILLED = `${ADDRESS__CREATE}_FULFILLED`;
export const ADDRESS__CREATE_PENDING = `${ADDRESS__CREATE}_PENDING`;

export const ADDRESS__DELETE = 'ADDRESS__DELETE';
export const ADDRESS__DELETE_FULFILLED = `${ADDRESS__DELETE}_FULFILLED`;
export const ADDRESS__DELETE_PENDING = `${ADDRESS__DELETE}_PENDING`;

export const CHECKOUT_SET_SUBSCRIPTION_INTERVALS =
  'CHECKOUT_SET_SUBSCRIPTION_INTERVALS';

export const TWILIO_SET_DATA = 'TWILIO_SET_DATA';
export const TWILIO_PUT_IN_UNCONSUMED = 'TWILIO_PUT_IN_UNCONSUMED';
export const TWILIO_REMOVE_FROM_UNCONSUMED = 'TWILIO_REMOVE_FROM_UNCONSUMED';
export const TWILIO_SET_NEWMESSAGE = 'TWILIO_SET_NEWMESSAGE';
export const TWILIO_SET_ESTHETICIAN_ID = 'TWILIO_SET_ESTHETICIAN_ID';
export const TWILIO_SET_ESTHETICIAN_HAS_CLIENTS =
  'TWILIO_SET_ESTHETICIAN_HAS_CLIENTS';
export const TWILIO_SET_OPEN = 'TWILIO_SET_OPEN';
export const TWILIO_ADD_CONVERSATION = 'TWILIO_ADD_CONVERSATION';

export const AUTH_USER__REFRESH_TWILIO_TOKEN =
  'AUTH_USER__REFRESH_TWILIO_TOKEN';
export const AUTH_USER__GET_METADATA = 'AUTH_USER__GET_METADATA';

export const AUTH_USER__GET_REFERRALS = 'AUTH_USER__GET_REFERRALS';
export const AUTH_USER__GET_REFERRALS_FULFILLED = `${AUTH_USER__GET_REFERRALS}_FULFILLED`;

export const AUTH_USER__REGISTER = 'AUTH_USER__REGISTER';
export const AUTH_USER__REGISTER_FULFILLED = `${AUTH_USER__REGISTER}_FULFILLED`;
export const AUTH_USER__REGISTER_PENDING = `${AUTH_USER__REGISTER}_PENDING`;
export const AUTH_USER__CAPTURE = 'AUTH_USER__CAPTURE';
export const AUTH_USER__CAPTURE_FULLFILLED = `${AUTH_USER__CAPTURE}_FULLFILLED`;
export const AUTH_USER__DELETE_UNCAPTURED_USER =
  'AUTH_USER__DELETE_UNCAPTURED_USER';

export const AUTH_USER__LOGIN = 'AUTH_USER__LOGIN';
export const AUTH_USER__LOGIN_FULFILLED = `${AUTH_USER__LOGIN}_FULFILLED`;
export const AUTH_USER__LOGIN_PENDING = `${AUTH_USER__LOGIN}_PENDING`;

export const AUTH_USER__LOGOUT = 'AUTH_USER__LOGOUT';
export const AUTH_USER__LOGOUT_FULFILLED = `${AUTH_USER__LOGOUT}_FULFILLED`;
export const AUTH_USER__LOGOUT_PENDING = `${AUTH_USER__LOGOUT}_PENDING`;

export const AUTH_USER__SET_SURVEY_COMPLETED =
  'AUTH_USER__SET_SURVEY_COMPLETED';

export const CHECKOUT__SET_PRODUCT_QUANTITY = 'CHECKOUT__SET_PRODUCT_QUANTITY';
export const CHECKOUT__SET_PRODUCT_QUANTITY_FULFILLED = `${CHECKOUT__SET_PRODUCT_QUANTITY}_FULFILLED`;
export const CHECKOUT__SET_PRODUCT_QUANTITY_PENDING = `${CHECKOUT__SET_PRODUCT_QUANTITY}_PENDING`;

export const CHECKOUT__SET_ADDRESS = 'CHECKOUT__SET_ADDRESS';

export const CHECKOUT__PLACE_ORDER = 'CHECKOUT__PLACE_ORDER';
export const CHECKOUT__PLACE_ORDER_FULFILLED = `${CHECKOUT__PLACE_ORDER}_FULFILLED`;
export const CHECKOUT__PLACE_ORDER_PENDING = `${CHECKOUT__PLACE_ORDER}_PENDING`;

export const CHECKOUT__CALC_SALES_TAX = 'CHECKOUT__CALC_SALES_TAX';
export const CHECKOUT__CALC_SALES_TAX_FULFILLED = `${CHECKOUT__CALC_SALES_TAX}_FULFILLED`;
export const CHECKOUT__CALC_SALES_TAX_PENDING = `${CHECKOUT__CALC_SALES_TAX}_PENDING`;

export const CHECKOUT__APPLY_PROMO_CODE = 'CHECKOUT__APPLY_PROMO_CODE';
export const CHECKOUT__APPLY_PROMO_CODE_FULFILLED = `${CHECKOUT__APPLY_PROMO_CODE}_FULFILLED`;
export const CHECKOUT__APPLY_PROMO_CODE_PENDING = `${CHECKOUT__APPLY_PROMO_CODE}_PENDING`;

export const CHECKOUT__REMOVE_PROMO_CODE = 'CHECKOUT__REMOVE_PROMO_CODE';
export const CHECKOUT__REMOVE_PROMO_CODE_FULFILLED = `${CHECKOUT__REMOVE_PROMO_CODE}_FULFILLED`;
export const CHECKOUT__REMOVE_PROMO_CODE_PENDING = `${CHECKOUT__REMOVE_PROMO_CODE}_PENDING`;

export const CHECKOUT__LOAD_ADJUSTMENTS = 'CHECKOUT__LOAD_ADJUSTMENTS';

export const CART_VALIDATE_PROMO_CODE = 'CART_VALIDATE_PROMO_CODE';
export const CART_VALIDATE_PROMO_CODE_FULFILLED = `${CART_VALIDATE_PROMO_CODE}_FULFILLED`;

export const ORDER__LOAD = 'ORDER__LOAD';
export const ORDER__LOAD_FULFILLED = `${ORDER__LOAD}_FULFILLED`;
export const ORDER__LOAD_PENDING = `${ORDER__LOAD}_PENDING`;

export const LOAD__PRODUCT_UPDATED = 'LOAD__PRODUCT_UPDATED';
export const LOAD__SHOW_ALL = 'LOAD__SHOW_ALL';
export const LOAD__FILTER = 'LOAD__FILTER';
export const LOAD__TYPE = 'LOAD__TYPE';

export const ORDERS__LOAD_ALL = 'ORDERS__LOAD_ALL';
export const ORDERS__LOAD_ALL_FULFILLED = `${ORDERS__LOAD_ALL}_FULFILLED`;
export const ORDERS__LOAD_ALL_PENDING = `${ORDERS__LOAD_ALL}_PENDING`;

export const ORDERS__LOAD_ALL_BY_USER = 'ORDERS__LOAD_ALL_BY_USER';

export const ORDERS__SEARCH = 'ORDERS__SEARCH';
export const ORDERS__SEARCH_FULFILLED = `${ORDERS__SEARCH}_FULFILLED`;

export const ORDERS__TOGGLE_TRANSACTIONS = 'ORDERS__TOGGLE_TRANSACTIONS';

export const PAYMENT_METHOD__CREATE = 'PAYMENT_METHOD__CREATE';
export const PAYMENT_METHOD__CREATE_FULFILLED = `${PAYMENT_METHOD__CREATE}_FULFILLED`;
export const PAYMENT_METHOD__CREATE_PENDING = `${PAYMENT_METHOD__CREATE}_PENDING`;

export const PAYMENT_METHOD__DELETE = 'PAYMENT_METHOD__DELETE';
export const PAYMENT_METHOD__DELETE_FULFILLED = `${PAYMENT_METHOD__DELETE}_FULFILLED`;
export const PAYMENT_METHOD__DELETE_PENDING = `${PAYMENT_METHOD__DELETE}_PENDING`;

export const PRODUCT__ADD_FOR_USER = 'PRODUCT__ADD_FOR_USER';
export const PRODUCT__ADD_FOR_USER_FULFILLED = `${PRODUCT__ADD_FOR_USER}_FULFILLED`;
export const PRODUCT__ADD_FOR_USER_PENDING = `${PRODUCT__ADD_FOR_USER}_PENDING`;

export const PRODUCT__LOAD_TO_EDIT = 'PRODUCT__LOAD_TO_EDIT';
export const PRODUCT__LOAD_TO_EDIT_FULFILLED = `${PRODUCT__LOAD_TO_EDIT}_FULFILLED`;
export const PRODUCT__LOAD_TO_EDIT_PENDING = `${PRODUCT__LOAD_TO_EDIT}_PENDING`;

export const PRODUCT__CLEAR_LOAD_TO_EDIT = 'PRODUCT__CLEAR_LOAD_TO_EDIT';

export const PRODUCT__LOAD = 'PRODUCT__LOAD';

export const PRODUCT__REMOVE_FOR_USER = 'PRODUCT__REMOVE_FOR_USER';
export const PRODUCT__REMOVE_FOR_USER_FULFILLED = `${PRODUCT__REMOVE_FOR_USER}_FULFILLED`;
export const PRODUCT__REMOVE_FOR_USER_PENDING = `${PRODUCT__REMOVE_FOR_USER}_PENDING`;

export const PRODUCT__SAVE = 'PRODUCT__SAVE';
export const PRODUCT__SAVE_FULFILLED = `${PRODUCT__SAVE}_FULFILLED`;
export const PRODUCT__SAVE_PENDING = `${PRODUCT__SAVE}_PENDING`;

export const PRODUCT__ADD_FAVORITE = 'PRODUCT__ADD_FAVORITE';
export const PRODUCT__REMOVE_FAVORITE = 'PRODUCT__REMOVE_FAVORITE';

export const PRODUCT__SET_TOTALS = 'PRODUCT__SET_TOTALS';

export const PRODUCT__SEARCH = 'PRODUCT__SEARCH';
export const PRODUCT__SEARCH_FULFILLED = `${PRODUCT__SEARCH}_FULFILLED`;
export const PRODUCT__SEARCH_PENDING = `${PRODUCT__SEARCH}_PENDING`;

export const PRODUCT__SEARCH_BASIC = 'PRODUCT__SEARCH_BASIC';

export const PRODUCT__DELETE = 'PRODUCT__DELETE';
export const PRODUCT__DELETE_FULFILLED = `${PRODUCT__DELETE}_FULFILLED`;
export const PRODUCT__DELETE_PENDING = `${PRODUCT__DELETE}_PENDING`;

export const PRODUCT_CATEGORIES__LOAD_ALL = 'PRODUCT_CATEGORIES__LOAD_ALL';
export const PRODUCT_CATEGORIES__LOAD_ALL_FULFILLED = `${PRODUCT_CATEGORIES__LOAD_ALL}_FULFILLED`;
export const PRODUCT_CATEGORIES__LOAD_ALL_PENDING = `${PRODUCT_CATEGORIES__LOAD_ALL}_PENDING`;
export const PRODUCT_CATEGORY__DELETE = 'PRODUCT_CATEGORY__DELETE';

export const BULK_ADD_DELETE_PRODUCTS_FROM_CATEGORIES =
  'BULK_ADD_DELETE_PRODUCTS_FROM_CATEGORIES';

export const PRODUCT__GIFT_CARD_PRODUCTS = 'PRODUCT__GIFT_CARD_PRODUCTS';
export const PRODUCT__GIFT_CARD_PRODUCTS_FULFILLED = `${PRODUCT__GIFT_CARD_PRODUCTS}_FULFILLED`;
export const PRODUCT__GIFT_CARD_PRODUCTS_PENDING = `${PRODUCT__GIFT_CARD_PRODUCTS}_PENDING`;

export const PRODUCT__EDIT_GIFTCARD = 'PRODUCT__EDIT_GIFTCARD';

export const PRODUCT_CATEGORY__SAVE = 'PRODUCT_CATEGORY__SAVE';
export const PRODUCT_CATEGORY__SAVE_FULFILLED = `${PRODUCT_CATEGORY__SAVE}_FULFILLED`;
export const PRODUCT_CATEGORY__SAVE_PENDING = `${PRODUCT_CATEGORY__SAVE}_PENDING`;

export const SURVEY__DELETE_ANSWER = 'SURVEY__DELETE_ANSWER';
export const SURVEY__DELETE_ANSWER_FULFILLED = `${SURVEY__DELETE_ANSWER}_FULFILLED`;
export const SURVEY__LOAD = 'SURVEY__LOAD';
export const SURVEY__LOAD_FULFILLED = `${SURVEY__LOAD}_FULFILLED`;
export const SURVEY__LOAD_PENDING = `${SURVEY__LOAD}_PENDING`;
export const SURVEY__SAVE_STEP = 'SURVEY__SAVE_STEP';
export const SURVEY__IS_OPEN = 'SURVEY__IS_OPEN';
export const SURVEY__IS_CLOSE = 'SURVEY__IS_CLOSE';

export const SURVEY__SET_ANSWER = 'SURVEY__SET_ANSWER';

export const SURVEY__SAVE_ANSWERS = 'SURVEY__SAVE_ANSWERS';
export const SURVEY__SAVE_ANSWERS_FULFILLED = `${SURVEY__SAVE_ANSWERS}_FULFILLED`;
export const SURVEY__SAVE_ANSWERS_PENDING = `${SURVEY__SAVE_ANSWERS}_PENDING`;

export const VENDORS__LOAD_ALL = 'VENDORS__LOAD_ALL';
export const VENDORS__LOAD_ALL_FULFILLED = `${VENDORS__LOAD_ALL}_FULFILLED`;
export const VENDORS__LOAD_ALL_PENDING = `${VENDORS__LOAD_ALL}_PENDING`;

export const VENDORS__SAVE = 'VENDORS__SAVE';
export const VENDORS__SAVE_FULFILLED = `${VENDORS__SAVE}_FULFILLED`;
export const VENDORS__SAVE_PENDING = `${VENDORS__SAVE}_PENDING`;

export const LOADER__START = 'LOADER__START';
export const LOADER__STOP = 'LOADER__STOP';

export const NOTES__CREATE = 'NOTES__CREATE';
export const NOTES__LOAD_NOTES_ABOUT_USER = 'NOTES__LOAD_NOTES_ABOUT_USER';
export const NOTES__UPDATE = 'NOTES__UPDATE';
export const NOTES__DELETE = 'NOTES__DELETE';

export const ORDERS__UPDATE_ESTHETICIAN = 'ORDERS__UPDATE_ESTHETICIAN';
export const ORDERS__UPDATE_ESTHETICIAN_FULFILLED = `${ORDERS__UPDATE_ESTHETICIAN}_FULFILLED`;
export const ORDERS__UPDATE_ESTHETICIAN_PENDING = `${ORDERS__UPDATE_ESTHETICIAN}_PENDING`;

export const ORDERS__UPDATE_COMMISSION_PAID = 'ORDERS__UPDATE_COMMISSION_PAID';
export const ORDERS__UPDATE_COMMISSION_PAID_FULFILLED = `${ORDERS__UPDATE_COMMISSION_PAID}_FULFILLED`;
export const ORDERS__UPDATE_COMMISSION_PAID_PENDING = `${ORDERS__UPDATE_COMMISSION_PAID}_PENDING`;

export const SMS__SEND = 'SMS__SEND';
export const SMS__SEND_FULFILLED = `${SMS__SEND}_FULFILLED`;
export const SMS__SEND_PENDING = `${SMS__SEND}_PENDING`;

export const REFERRALS__UPDATE_PRO_REFERRER = 'REFERRALS__UPDATE_PRO_REFERRER';
export const REFERRALS__UPDATE_PRO_REFERRER_FULFILLED = `${REFERRALS__UPDATE_PRO_REFERRER}_FULFILLED`;
export const REFERRALS__UPDATE_PRO_REFERRER_PENDING = `${REFERRALS__UPDATE_PRO_REFERRER}_PENDING`;

export const ORDERS__ADDED = 'ORDERS__ADDED';
export const ORDERS__SHOW_ALL = 'ORDERS__SHOW_ALL';
export const ORDERS__FILTER = 'ORDERS__FILTER';
export const ORDERS__TYPE = 'ORDERS__TYPE';

export const CREDITS_AVAILABLE = 'CREDITS_AVAILABLE';

export const REPORT_LOADED = 'REPORT_LOADED';

export const DASHBOARD_LOADED = 'DASHBOARD_LOADED';

export const SET__MESSAGE = 'SET__MESSAGE';
export const REMOVE__MESSAGE = 'REMOVE__MESSAGE';

// Just in case anything is still relying on this.
export * from 'features/users/actions/action-types';
