import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDialog } from './DialogProvider';
import NotificationCarousel from '../components/pages/admin/NotificationSearch/NotificationList/NotificationCarousel';

/**
 * Create a NotificationsProvider that allows child components
 * to open a dialog that is resolved via a promise.
 */
function CreateNotificationsProvider() {
  const Context = createContext({
    notifications: [],
    open: false,
    show: () => undefined,
    clearNotification: () => undefined,
  });

  const { Provider } = Context;

  // eslint-disable-next-line react/prop-types
  const NotificationsProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const { open: openDialog } = useDialog();
    const [open, setOpen] = useState(false);

    const handleNotifications = async notifications => {
      const oldNotificationIds = JSON.parse(
        localStorage.getItem('notificationIds')
      );
      const newNotifications = !oldNotificationIds
        ? notifications.getIn(['all', 'data'])
        : notifications
            .getIn(['all', 'data'])
            .filter(
              notification =>
                !oldNotificationIds.includes(notification.get('id'))
            );
      const notificationIds = newNotifications.map(notification =>
        notification.get('id')
      );

      if (newNotifications.size) {
        setOpen(true);
        const res = await openDialog({
          component: NotificationCarousel,
          containerProps: {
            PaperProps: {
              style: {
                maxWidth: '400px',
                borderRadius: '10px',
              },
            },
          },
          props: {
            notifications: newNotifications,
          },
        });

        if (res.wasCancelled) {
          const allIds = oldNotificationIds
            ? [...new Set([...notificationIds, ...oldNotificationIds])]
            : notificationIds;
          localStorage.setItem('notificationIds', JSON.stringify(allIds));
          localStorage.setItem(
            'notificationsOffTimestamp',
            new Date().toISOString()
          );
        }
      }
    };

    const clearNotification = notificationId => {
      setNotifications(
        notifications.filter(notification => notification.id !== notificationId)
      );

      const storedNotifications =
        JSON.parse(localStorage.getItem('notifications')) || [];
      localStorage.setItem(
        'notifications',
        JSON.stringify(
          storedNotifications.filter(
            notification => notification.id !== notificationId
          )
        )
      );
    };

    const show = notifications => {
      if (!notifications) {
        return;
      }
      handleNotifications(notifications);
    };

    return (
      <Provider value={{ notifications, show, open, clearNotification }}>
        {children}
      </Provider>
    );
  };

  const useNotifications = () => useContext(Context);

  return {
    NotificationsProvider,
    useNotifications,
  };
}

const { NotificationsProvider, useNotifications } =
  CreateNotificationsProvider();

export { NotificationsProvider, useNotifications };

/**
 * A wrapper component that can wrap existing class components and allow
 * them to use the notifications dialog functions
 * @param Component
 * @returns {function(*)}
 */
// eslint-disable-next-line react/display-name
export const NotificationsAwareWrapper = Component => props => {
  const { show } = useNotifications(); // eslint-disable-line react-hooks/rules-of-hooks -- This is a false positive, this looks like a callback, but it's actually a wrapped component
  return <Component showNotifications={show} {...props} />;
};

NotificationsAwareWrapper.propTypes = {
  Component: PropTypes.element,
};
