import * as actionTypes from 'constants/actionTypes';

export default {
  setUpdatedProductId: updatedProductId => ({
    type: actionTypes.LOAD__PRODUCT_UPDATED,
    payload: {
      updatedProductId
    }
  }),

  setShowAll: showAll => ({
    type: actionTypes.LOAD__SHOW_ALL,
    payload: {
      showAll
    }
  }),

  setFilter: filter => ({
    type: actionTypes.LOAD__FILTER,
    payload: {
      filter
    }
  }),

  setType: type => ({
    type: actionTypes.LOAD__TYPE,
    payload: {
      type
    }
  })
};
