import { combineReducers } from 'redux-immutable';
import authUser from '../features/auth/reducer';
import notes from './notes';
import orders from './orders';
import productSearch from './productSearch';
import productCategories from './productCategories';
import promoCodes from './promoCodes';
import survey from './survey';
import checkout from './checkout';
import loadProducts from './loadProducts';
import vendors from '../features/vendors/reducers';
import loader from './loader';
import { UserReducer as users } from '../features/users/reducers';
import credits from './credits';
import { RoutineReducer as routines } from 'features/routines/reducers';
import { CartReducer as carts } from 'features/carts/reducers';
import { InterceptedRequestReducer as interceptedRequests } from 'features/intercepted-requests/reducers';
import { ProductsReducer as products } from 'features/products/reducers';
import { CommissionReducer as commission } from 'features/commission/reducers';
import notifications from 'features/notifications/reducers';
import customLinks from 'features/customLinks/reducers';
import { DocumentsReducer as documents } from 'features/documents/reducers';
import { ProStoreReducer as proStore } from 'features/pro-stores/reducers';
import { MessagesReducer as messaging } from 'features/messaging/reducers';
import { TagsReducer as tags } from 'features/tags/reducers';
import { BrandEducationReducer as brandEducations } from 'features/brand-educations/reducers';
import { TasksReducer as tasks } from 'features/tasks/reducers';
import { IntegrationsReducer as integrations } from 'features/integrations/reducers';
import { WishlistsReducer as wishlists } from 'features/wishlists/reducers';
import { RetailReducer as retail } from 'features/retail/reducers';
import { BusinessReducer as businesses } from 'features/businesses/reducers';
import { CollectionsReducer as collections } from 'features/collections/reducers';
import { StoreLandingPageReducer as storeLandingPage } from 'features/enhancements-store/reducers';
import { ProgressPhotosReducer as progressPhotos } from 'features/progress-photos/reducers';

export default combineReducers({
  authUser,
  brandEducations,
  businesses,
  carts,
  checkout,
  credits,
  collections,
  commission,
  customLinks,
  documents,
  integrations,
  interceptedRequests,
  loader,
  loadProducts,
  messaging,
  notes,
  notifications,
  orders,
  products,
  productSearch,
  productCategories,
  progressPhotos,
  promoCodes,
  proStore,
  retail,
  routines,
  storeLandingPage,
  survey,
  tags,
  tasks,
  users,
  vendors,
  wishlists,
});
