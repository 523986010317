import sanitizeHTML from 'sanitize-html';

export const isEmpty = html =>
  !html
    .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '')
    .replace(/[\u200B-\u200D\uFEFF]/g, '')
    .trim();

export const removeEmpty = html =>
  html
    .replace(/&nbsp;+/g, '')
    .replace(/[\u200B-\u200D\uFEFF]/g, '')
    .trim();

export const replaceBreaks = html =>
  removeEmpty(html).replace(/<br ?\/?>/g, '\r\n');

/**
 * Replace new Line characters with break tags.
 * @param text
 */
export const convertPlainTextToHtml = text =>
  isHTML(text) ? text : convertUrlsToLinks(convertBreaksToHtml(text));

export const convertBreaksToHtml = html =>
  html.replace(/(\r\n|\r|\n)/g, '<br />');

export const convertUrlsToLinks = text =>
  text.replace(/(https?:\/\/[^\s]+)/g, function (url) {
    return '<a href="' + url + '">' + url + '</a>';
  });

export const isHTML = str => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return [].slice.call(doc.body.childNodes).some(node => node.nodeType === 1);
};

export const getLinks = html => {
  let links = [];
  if (html) {
    let tmp = document.createElement('div');
    tmp.innerHTML = html;
    links = [
      ...new Set(
        Array.from(tmp.getElementsByTagName('a')).map(
          a => a.attributes.href?.value
        )
      )
    ];
  }

  return links;
};

export const stripHtml = html =>
  sanitizeHTML(replaceBreaks(html), { allowedTags: [] });

export const sanitizePastedHTML = html =>
  sanitizeHTML(html, {
    allowedTags: sanitizeHTML.defaults.allowedTags.concat([
      'img',
      'b',
      'i',
      'em',
      'strong',
      'a'
    ]),
    allowedClasses: {
      img: ['*__RemoveBtn--*', '*__ImgTag--*']
    },
    allowedAttributes: {
      '*': ['id', 'style'],
      a: ['src', 'target', 'href'],
      img: ['src']
    },
    allowedSchemes: ['data', 'http', 'https', 'ftp', 'mailto', 'tel'],
    allowedStyles: {
      '*': {
        'font-style': [/^italic$/]
      }
    },
    transformTags: {
      h1: sanitizeHTML.simpleTransform('strong', true),
      h2: sanitizeHTML.simpleTransform('strong', true),
      h3: sanitizeHTML.simpleTransform('strong', true),
      h4: sanitizeHTML.simpleTransform('strong', true),
      h5: sanitizeHTML.simpleTransform('strong', true),
      h6: sanitizeHTML.simpleTransform('strong', true)
    }
  });

export const htmlParser = (tool, html, newMessage = null, hyperLinks = []) => {
  const attachArticles = () => {
    if (hyperLinks.length) {
      const links = hyperLinks.join('#');
      return `${newMessage}<--->${links}`;
    }
  };

  switch (tool) {
    case 'removeEmpty':
      return removeEmpty(html);
    case 'isEmpty':
      return isEmpty(html);
    case 'isHTML':
      return isHTML(html);
    case 'getLinks':
      return getLinks(html);
    case 'sanitizedHTML':
      return sanitizePastedHTML(html);
    case 'stripHTML':
      return replaceBreaks(html);
    case 'convertPlainTextToHtml':
      return convertPlainTextToHtml(html);
    case 'attachArticles':
      return attachArticles(newMessage, hyperLinks);
    default:
      break;
  }
};
