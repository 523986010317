import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const ProductTagsSearch = lazy(() =>
  import(
    /* webpackChunkName: "ProductTagsSearch" */ '../../tags/components/product-tags-search'
  ).then(({ ProductTagsSearch }) => ({
    default: ProductTagsSearch,
  }))
);

const ProductTagsEditPage = lazy(() =>
  import(
    /* webpackChunkName: "ProductTagsEditPage" */ '../../tags/components/product-tags-edit'
  ).then(({ ProductTagsEditPage }) => ({
    default: ProductTagsEditPage,
  }))
);

export const TagRoutes = [
  {
    component: ProductTagsSearch,
    isExact: true,
    key: 'manage-tags',
    path: '/manage-tags',
    flag: FEATURE_FLAGS.MANAGE_PRODUCTS,
  },
  {
    component: ProductTagsEditPage,
    isExact: true,
    key: 'tags',
    path: '/tag/:tagId',
    flag: FEATURE_FLAGS.MANAGE_PRODUCTS,
  },
];
