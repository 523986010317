import { makeStyles } from '@mui/styles';

const useStyles = ({ capitalize, loading, fixedWidth }) =>
  makeStyles(theme => ({
    Button: {
      '&.MuiButton-root': {
        textTransform: capitalize ? 'capitalize' : 'none',
        minWidth: loading ? 100 : 'fit-content',
      },
      [theme.breakpoints.down('sm')]: fixedWidth
        ? {}
        : {
            width: '100%',
          },
    },
  }));

export { useStyles };
