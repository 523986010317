import * as actionTypes from 'features/promos/action-creators';
import createReducer from 'reducers/createReducer';
import Immutable from 'immutable';
import initialState from './initialState';

export default createReducer(initialState, {
  [actionTypes.PROMO_CODES__LOAD_ALL_PENDING]: state =>
    state.set('areLoading', true),

  [actionTypes.PROMO_CODES__LOAD_ALL_FULFILLED]: (
    state,
    { payload: { data } }
  ) =>
    state.merge({
      all: Immutable.fromJS(data.promoCodes),
      areLoading: false,
    }),

  [actionTypes.PROMO__SEARCH]: (state, { payload: { data } }) =>
    state.merge({
      all: data.promoCodes,
      areLoading: false,
    }),
});
