import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const BrandEducationSearch = lazy(() =>
  import(
    /* webpackChunkName: "BrandEducationSearch" */ '../components/admin/brand-education-search'
  ).then(({ BrandEducationSearch }) => ({
    default: BrandEducationSearch,
  }))
);

const BrandEducationEditPage = lazy(() =>
  import(
    /* webpackChunkName: "BrandEducationEditPage" */ '../components/admin/brand-educations-edit'
  ).then(({ BrandEducationEditPage }) => ({
    default: BrandEducationEditPage,
  }))
);

const EstheticianEducationSearch = lazy(() =>
  import(
    /* webpackChunkName: "EstheticianEducationSearch" */ '../components/esthetician/esthetician-education-search'
  ).then(({ EstheticianEducationSearch }) => ({
    default: EstheticianEducationSearch,
  }))
);

const BrandEducationDetailView = lazy(() =>
  import(
    /* webpackChunkName: "BrandEducationDetailView" */
    '../components/esthetician/brand-detail/brand-education-detail-view'
  ).then(({ BrandEducationDetailView }) => ({
    default: BrandEducationDetailView,
  }))
);

export const BrandEducationRoutes = [
  {
    component: BrandEducationSearch,
    isExact: true,
    key: 'manage-brand-education',
    path: '/manage-brand-education',
    flag: FEATURE_FLAGS.MANAGE_PRODUCTS,
  },
  {
    component: BrandEducationEditPage,
    isExact: true,
    key: 'edit-brand-education',
    path: '/brand-education/edit/:brandId',
    flag: FEATURE_FLAGS.MANAGE_PRODUCTS,
  },
  {
    component: EstheticianEducationSearch,
    isExact: true,
    key: 'edit-brand-education',
    path: '/brand-education',
    flag: FEATURE_FLAGS.BRAND_EDUCATION,
  },
  {
    component: BrandEducationDetailView,
    isExact: true,
    key: 'edit-brand-education',
    path: '/brand-education/:brandId',
    flag: FEATURE_FLAGS.BRAND_EDUCATION,
  },
];
