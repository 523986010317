import { lazy } from 'react';
const ROICalculatoEarn = lazy(() =>
  import(
    /* webpackChunkName: "ROICalculatoEarn" */ '../pages/roi-calculator-earn'
  ).then(({ ROICalculatorEarn }) => ({
    default: ROICalculatorEarn,
  }))
);
const ROICalculatorSave = lazy(() =>
  import(
    /* webpackChunkName: "ROICalculatorSave" */ '../pages/roi-calculator-save'
  ).then(({ ROICalculatorSave }) => ({
    default: ROICalculatorSave,
  }))
);

export const ToolRoutes = [
  {
    component: ROICalculatoEarn,
    isExact: true,
    key: 'roi-calculator-earn',
    path: '/tools/roi-calculator/earn',
  },
  {
    component: ROICalculatorSave,
    isExact: true,
    key: 'roi-calculator-save',
    path: '/tools/roi-calculator/save',
  },
];
