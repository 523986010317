import React, { useEffect, useState } from 'react';
import { Box, Fab } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { SCROLLABLE_CONTENT_ID } from 'constants/ids';

export const ScrollToTopFab = () => {
  const [isVisible, setIsVisible] = useState(false);
  const scrollingElement = document.getElementById(SCROLLABLE_CONTENT_ID);

  const scrollToTop = () => {
    scrollingElement?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Handle visibility of the FAB based on scroll position
  const handleScroll = () => {
    const { scrollTop } = scrollingElement;

    if (scrollTop > 200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (!scrollingElement) return;
    scrollingElement.addEventListener('scroll', handleScroll);

    return () => scrollingElement.removeEventListener('scroll', handleScroll);
  }, [scrollingElement]);

  return (
    <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'center' }}>
      <If condition={isVisible}>
        <Fab
          color="primary"
          aria-label="Scroll to Top"
          sx={{
            position: 'fixed',
            bottom: { xs: 62, sm: 25 },
            backgroundColor: 'transparent',
            width: '40px',
            height: '40px',
            '&:hover': { backgroundColor: 'background.paper' },
          }}
          onClick={scrollToTop}
        >
          <ArrowUpwardIcon color="primary" />
        </Fab>
      </If>
    </Box>
  );
};
