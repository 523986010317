import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const promoCode = ImmutablePropTypes.contains({
  id: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  numUses: PropTypes.number,
  maxNumUses: PropTypes.number,
  amount: PropTypes.number.isRequired,
  isFixed: PropTypes.bool.isRequired,
  updated: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired
});

export const promoCodes = ImmutablePropTypes.listOf(promoCode);

export const promoCodesState = ImmutablePropTypes.contains({
  all: promoCodes,
  areLoading: PropTypes.bool.isRequired
});
