import * as actionTypes from 'constants/actionTypes';
import graphql from 'lib/api/graphql';
import productCategorySearch from 'graphQL/queries/productCategorySearch';
import productCategorySave from 'graphQL/mutations/admin/productCategory';
import productCategoryDelete from 'graphQL/mutations/admin/product-category-delete';
import addOrDeleteBulkProducts from 'graphQL/mutations/admin/bulk-add-or-delete-products';
import { generateAsyncAction } from '../lib/action-generators';

export default {
  /**
   * @param {{
   * query?: string,
   * filters?: {
   *  id?: string,
   *  hasVendorId?: string,
   *  displayInStore?: boolean,
   *  hide?: boolean
   * },
   * sort?: {
   *  field: string,
   *  direction: 'asc' | 'desc'
   * },
   * pagination?: {
   *   page: number,
   *   size: number
   * }} searchParams
   */
  loadAll: searchParams => dispatch =>
    dispatch({
      type: actionTypes.PRODUCT_CATEGORIES__LOAD_ALL,
      payload: graphql(productCategorySearch, searchParams),
    }),

  save: productCategory => dispatch =>
    dispatch({
      type: actionTypes.PRODUCT_CATEGORY__SAVE,
      payload: graphql(productCategorySave, { productCategory }),
    }),

  deleteCategory: productCategoryId =>
    generateAsyncAction(
      actionTypes.PRODUCT_CATEGORY__DELETE,
      { productCategoryId },
      graphql(productCategoryDelete, { productCategoryId }),
      'productCategoryDelete'
    ),

  addOrDeleteBulkProducts: values =>
    generateAsyncAction(
      actionTypes.BULK_ADD_DELETE_PRODUCTS_FROM_CATEGORIES,
      { values },
      graphql(addOrDeleteBulkProducts, { values }),
      'addOrDeleteBulkProducts'
    ),
};
