import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const ProductManagement = lazy(() =>
  import(
    /* webpackChunkName: "ProductManagement" */ 'features/products/routes/product-management-subrouter'
  )
);
const ProductCategoryManagement = lazy(() =>
  import(
    /* webpackChunkName: "ProductCategoryManagement" */ 'components/pages/admin/ProductCategoryManagement'
  )
);
const ProductSearch = lazy(() =>
  import(
    /* webpackChunkName: "ProductSearch" */ 'features/products/pages/product-search'
  )
);

const RecommendProduct = lazy(() =>
  import(
    /* webpackChunkName: "RecommendProduct" */ 'components/pages/admin/RecommendProduct'
  )
);

const ProductVariantGroupSearchPage = lazy(() =>
  import(
    /* webpackChunkName: "ProductVariantGroupSearchPage" */ '../pages/product-variant-group-search-page'
  ).then(({ ProductVariantGroupSearchPage }) => ({
    default: ProductVariantGroupSearchPage,
  }))
);

const EditProductVariantGroupPage = lazy(() =>
  import(
    /* webpackChunkName: "EditProductVariantGroupPage" */ '../pages/edit-product-variant-group-page'
  ).then(({ EditProductVariantGroupPage }) => ({
    default: EditProductVariantGroupPage,
  }))
);

export const ProductRoutes = [
  {
    component: ProductSearch,
    isExact: true,
    key: 'product-search',
    path: '/product-search',
    flag: FEATURE_FLAGS.PRODUCT_SEARCH,
  },
  {
    component: ProductVariantGroupSearchPage,
    isExact: true,
    key: 'manage-variants',
    path: '/products/variants',
    flag: FEATURE_FLAGS.MANAGE_PRODUCTS,
  },
  {
    component: EditProductVariantGroupPage,
    isExact: true,
    key: 'manage-variant',
    path: '/products/variants/:id',
    flag: FEATURE_FLAGS.MANAGE_PRODUCTS,
  },
  {
    component: ProductManagement,
    isExact: false,
    key: 'products',
    path: '/products',
    flag: FEATURE_FLAGS.MANAGE_PRODUCTS,
  },
  {
    component: ProductCategoryManagement,
    isExact: false,
    key: 'manage-product-categories',
    path: '/manage-product-categories',
    flag: FEATURE_FLAGS.MANAGE_PRODUCTS,
  },
  // TODO: Remove this in a little bit. After we know it's not being used anymore and live links are updated.
  {
    component: RecommendProduct,
    isExact: true,
    key: 'recommend-product-deprecated',
    path: '/user/:userId/recommend-products',
    flag: FEATURE_FLAGS.MANAGE_CLIENT_CART,
  },
  {
    component: RecommendProduct,
    isExact: true,
    key: 'recommend-product',
    path: '/users/:userId/recommend-products',
    flag: FEATURE_FLAGS.MANAGE_CLIENT_CART,
  },
];
