export const POMP_CLASSIC_THEME = {
  mode: 'light',
  primary: {
    main: '#293d4d',
  },
  secondary: {
    main: '#a03439',
  },
  tertiary: {
    main: '#849a8f',
  },
  error: {
    main: '#e2242a',
    dark: '#9e191d',
    light: '#e74f54',
    contrastText: '#ffffff',
  },
  warning: {
    main: '#f6c823',
    dark: '#ac8c18',
    light: '#f7d34f',
    contrastText: '#4c4c4c',
  },
  info: {
    main: '#809dbd',
    dark: '#496788',
    light: '#bfcede',
    contrastText: '#0F2743',
  },
  success: {
    main: '#008464',
    dark: '#005c46',
    light: '#339c83',
    contrastText: '#ffffff',
  },
  text: {
    primary: '#3C4142',
    secondary: 'rgba(60,65,66,0.75)',
    disabled: 'rgba(60,65,66,0.38)',
  },
  background: {
    default: '#eceeee',
    paper: '#FFFFFF',

    // Not generic MUI values, but using it as the background for inputs
    input: '#FFFFFF',
    tableOddRow: '#bfcede11',
  },
  backdrop: {
    overlay: '#00000080',
  },
};
