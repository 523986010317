import React, { createContext, Suspense, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { BaseDialog, BaseSidecar } from './components';

/**
 * Create a DialogProvider that allows child components
 * to open a dialog that is resolved via a promise.
 * @param BaseDialogComponent
 */
function CreateDialogProvider(BaseDialogComponent = BaseDialog) {
  const Context = createContext({
    dialog: null,
    // eslint-disable-next-line no-empty-pattern
    open: ({}) => undefined,
    close: () => undefined,
  });

  const { Provider } = Context;

  // eslint-disable-next-line react/prop-types
  const DialogProvider = ({ children }) => {
    const [dialogs, setDialogs] = useState([]);

    /**
     * Open the dialog that was passed in the format of
     * {
     * component: React Component
     * props: Props for the react component
     * }
     * @param newDialog
     */
    const open = newDialog => {
      if (!newDialog || !newDialog.component) {
        throw new Error('Invalid Dialog');
      }

      // Configure the promise, so that the dialog can return the results
      newDialog.closePromise = new Promise(resolve => {
        newDialog.closeResolve = resolve;
      });

      setDialogs([
        ...dialogs,
        {
          id: uuid(),
          ...newDialog,
          props: {
            ...(newDialog.props || {}),
          },
          containerProps: {
            ...(newDialog.containerProps || { maxWidth: 'sm', p: 2 }),
            open: true,
          },
        },
      ]);

      return newDialog.closePromise;
    };

    /**
     * Close the dialog
     * @param id
     * @param cancel
     * @param result
     */
    const close = (id, cancel, result) => {
      const dialog = dialogs.find(d => d.id === id);

      if (!dialog) return;

      // Resolve the promise
      dialog.closeResolve({ wasCancelled: cancel, result });

      setDialogs(
        dialogs.map(d => {
          if (d.id === id) {
            d.containerProps.open = false;
          }
          return d;
        })
      );

      setTimeout(() => {
        // After the animation is complete, set the dialog to null
        setDialogs(dialogs.filter(d => d.id !== id));
      }, 300);
    };

    const DialogContainer = (
      <>
        {dialogs.map(dialog => {
          const DialogComponent = dialog.component;
          const props = dialog.props || {};
          const containerProps = dialog.containerProps || {};
          const { hideCloseButton } = dialog;

          return (
            <BaseDialogComponent
              key={dialog.id}
              cancel={result => close(dialog.id, true, result)}
              hideCloseButton={hideCloseButton}
              onBackdropClick={() => close(dialog.id, true, null)}
              {...containerProps}
            >
              <DialogComponent
                ok={result => close(dialog.id, false, result)}
                cancel={result => close(dialog.id, true, result)}
                {...props}
              />
            </BaseDialogComponent>
          );
        })}
      </>
    );

    return (
      <Provider value={{ dialogs, open, close }}>
        {/* Allow the Lazy Loading of Dialogs */}
        <Suspense fallback={null}>
          {DialogContainer}
          {children}
        </Suspense>
      </Provider>
    );
  };

  const useDialog = () => useContext(Context);

  return {
    DialogProvider,
    useDialog,
  };
}

const { DialogProvider, useDialog } = CreateDialogProvider();
const { DialogProvider: SidecarProvider, useDialog: useSidecar } =
  CreateDialogProvider(BaseSidecar);

export { DialogProvider, useDialog, SidecarProvider, useSidecar };

/**
 * A wrapper component that can wrap existing class components and allow
 * them to use the dialog functions
 * @param Component
 * @returns {function(*)}
 */
// eslint-disable-next-line react/display-name
export const DialogAwareWrapper = Component => props => {
  const { open, close } = useDialog(); // eslint-disable-line react-hooks/rules-of-hooks -- This is a false positive, this looks like a callback, but it's actually a wrapped component
  return <Component openDialog={open} closeDialog={close} {...props} />;
};

DialogAwareWrapper.propTypes = {
  Component: PropTypes.element,
};
