import axios from 'axios';
import getHeaders from './getHeaders';
import { logout } from '../../index';

export default (query, variables, abortController = new AbortController()) =>
  axios({
    data: { query, variables },
    headers: getHeaders(),
    signal: abortController.signal,
    method: 'POST',
    url: process.env.API_URL || '/api/graphql',
    withCredentials: true,
  })
    .then(response => response.data)
    .catch(error => {
      if (error?.response?.status === 401) {
        logout();
      }
    });
