export const CartReducerInitialState = {
  cartsById: {
    guest: {
      id: 'guest',
      userId: 'guest',
      products: []
    }
  },
  cartsByUserId: {
    guest: 'guest'
  }
};
