import { generateAsyncAction } from 'lib/action-generators';
import {
  ADD__WISHLIST_PRODUCT,
  LOAD__WISHLIST_PRODUCTS,
  DELETE__WISHLIST_PRODUCT,
} from './action-types';
import graphql from 'lib/api/graphql';
import addWishlistProduct from '../graphql/mutations/add-wishlist-product';
import deleteWishlistProduct from '../graphql/mutations/delete-wishlist-product';
import loadWishlistProducts from '../graphql/queries/load-wishlist-products';

const WishlistActions = {
  addWishlistProduct: (productId, source) =>
    generateAsyncAction(
      ADD__WISHLIST_PRODUCT,
      { productId, source },
      graphql(addWishlistProduct, { productId, source }),
      'addWishlistProduct'
    ),

  loadWishlistProducts: () =>
    generateAsyncAction(
      LOAD__WISHLIST_PRODUCTS,
      {},
      graphql(loadWishlistProducts),
      'loadWishlistProducts'
    ),

  deleteWishlistProduct: wishlistProductId =>
    generateAsyncAction(
      DELETE__WISHLIST_PRODUCT,
      { wishlistProductId },
      graphql(deleteWishlistProduct, { wishlistProductId }),
      'deleteWishlistProduct'
    ),
};

export default WishlistActions;
