export const CREATE_BRAND_EDUCATION = 'CREATE_BRAND_EDUCATION';
export const DELETE_BRAND_EDUCATION = 'DELETE_BRAND_EDUCATION';
export const EDIT_BRAND_EDUCATION = 'EDIT_BRAND_EDUCATION';

export const SEARCH_BRAND_EDUCATIONS = 'SEARCH_BRAND_EDUCATIONS';
export const LOAD_BRAND_EDUCATION = 'LOAD_BRAND_EDUCATION';

export const CREATE_BRAND_RESOURCE = 'CREATE_BRAND_RESOURCE';
export const DELETE_BRAND_RESOURCE = 'DELETE_BRAND_RESOURCE';
export const EDIT_BRAND_RESOURCE = 'EDIT_BRAND_RESOURCE';

export const COMPLETE_BRAND_EDUCATION = 'COMPLETE_BRAND_EDUCATION';
