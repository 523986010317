import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const surveyAnswerChoice = ImmutablePropTypes.contains({
  answer: PropTypes.string,
  id: PropTypes.string
});

export const surveyAnswerChoices = ImmutablePropTypes.mapOf(
  surveyAnswerChoice,
  PropTypes.string
);

export const surveyAnswer = ImmutablePropTypes.contains({
  answer: PropTypes.string,
  choices: surveyAnswerChoices,
  question: PropTypes.string.isRequired
});

export const surveyAnswers = ImmutablePropTypes.mapOf(
  surveyAnswer,
  PropTypes.string
);

export const surveyQuestionChoice = ImmutablePropTypes.contains({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
});

export const surveyQuestionChoices =
  ImmutablePropTypes.listOf(surveyQuestionChoice);

export const surveyQuestion = ImmutablePropTypes.contains({
  choices: surveyQuestionChoices,
  id: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
});

export const surveyQuestions = ImmutablePropTypes.listOf(surveyQuestion);

export const surveyState = ImmutablePropTypes.contains({
  answers: surveyAnswers,
  areLoading: PropTypes.bool.isRequired,
  questions: surveyQuestions,
  savedAnswers: PropTypes.bool.isRequired,
  open: PropTypes.bool,
  step: PropTypes.number
});
