import * as addressPropTypes from './addresses';
import * as paymentMethodPropTypes from './paymentMethods';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const jwt = PropTypes.string;

export const authUser = ImmutablePropTypes.contains({
  addresses: addressPropTypes.addresses,
  email: PropTypes.string,
  firstName: PropTypes.string,
  id: PropTypes.string,
  jwt: PropTypes.string,
  twilioAccessToken: PropTypes.string,
  lastName: PropTypes.string,
  paymentMethods: paymentMethodPropTypes.paymentMethods,
  role: PropTypes.string
});
