import { generateAsyncAction } from 'lib/action-generators';
import {
  LOAD__PRO_STORE,
  SUBMIT_PRO_PROFILE,
  PRO_STORE_SEARCH,
} from './action-types';
import loadStore from '../graphql/queries/load-pro-store.graphql';
import proStoreSearch from '../graphql/queries/pro-store-search.graphql';
import submitProProfile from '../graphql/mutations/submit-pro-profile.graphql';
import graphql from 'lib/api/graphql';

export default {
  loadProStore: userId =>
    generateAsyncAction(
      LOAD__PRO_STORE,
      { userId },
      graphql(loadStore, { userId })
    ),

  storeSearch: search =>
    generateAsyncAction(
      PRO_STORE_SEARCH,
      { search },
      graphql(proStoreSearch, search),
      'proStoreSearch'
    ),

  submitProProfile: profile =>
    generateAsyncAction(
      SUBMIT_PRO_PROFILE,
      { profile },
      graphql(submitProProfile, { profile }),
      'submitProProfile'
    ),
};
