// COMMISSION EVENTS
export const COMMISSION_BULK_UPDATE = 'COMMISSION_BULK_UPDATE';

//PAYMENT EVENTS
export const COMMISSION_PAYMENTS_BULK_UPDATE =
  'COMMISSION_PAYMENTS_BULK_UPDATE';

//COMMISSION PLAN EVENTS
export const COMMISSION_PLAN_UPDATE = 'COMMISSION_PLAN_UPDATE';
export const COMMISSION_PLAN_BULK_UPDATE = 'COMMISSION_PLAN_BULK_UPDATE';

// COMMISSION USER PLAN EVENTS
export const COMMISSION_USER_PLAN_BULK_UPDATE =
  'COMMISSION_USER_PLAN_BULK_UPDATE';
