import { PRODUCT__EDIT_GIFTCARD } from 'constants/actionTypes';
import { v4 as uuid } from 'uuid';

export const GUEST_EDIT_GIFTCARD_INTERCEPTOR = {
  // The function that intercepts the request
  interceptFunc: ({ query, variables, store }) => {
    const product = store?.products?.productsById?.[variables?.productId];
    const response = { ...variables, product };

    const request = {
      id: uuid(),
      query,
      type: PRODUCT__EDIT_GIFTCARD,
      interceptorName: 'GUEST_EDIT_GIFTCARD_INTERCEPTOR',
      args: variables,
      response,
      requestVariables: [],
      responseVariables: []
    };

    return {
      response,
      request,
      variables: []
    };
  },
  // The function that is called when the response is received
  replaceFunc: () => null,
  // A function that is run to cleanup redundant requests (returns null if the request should be removed)
  normalizeFunc: () => null // Always remove the request, as it's only modifying a previous request
};
