import * as actionTypes from 'constants/actionTypes';
import graphql from 'lib/api/graphql';
import loadOrder from 'graphQL/queries/order';
import loadOrderRestricted from 'graphQL/queries/orderRestricted';
import loadOrderAdjustments from 'graphQL/queries/adjustments';
import ordersLoadAll from 'graphQL/queries/ordersLoadAll';
import updateOrderEsthetician from 'graphQL/mutations/admin/updateOrderEsthetician';
import updateCommissionPaid from 'graphQL/mutations/admin/updateCommissionPaid';
import orderSearch from 'graphQL/queries/orderSearch';
import { generateAsyncAction } from '../lib/action-generators';

export default {
  load: n => dispatch => {
    return new Promise(resolve => {
      graphql(loadOrder, { orderNumber: n }).then(function (result) {
        var d = dispatch({
          type: actionTypes.ORDER__LOAD,
          payload: result
        });
        resolve(d);
      });
    });
  },

  loadRestricted: n => dispatch => {
    return new Promise(resolve => {
      graphql(loadOrderRestricted, { orderNumber: n }).then(function (result) {
        var d = dispatch({
          type: actionTypes.ORDER__LOAD,
          payload: result
        });
        resolve(d);
      });
    });
  },

  loadOrderAdjustments: n => dispatch => {
    return new Promise(resolve => {
      graphql(loadOrderAdjustments, { orderNumber: n }).then(function (result) {
        var d = dispatch({
          type: actionTypes.ORDER__LOAD,
          payload: result
        });
        resolve(d);
      });
    });
  },

  loadAll: () => ({
    type: actionTypes.ORDERS__LOAD_ALL,
    payload: graphql(ordersLoadAll)
  }),

  search: searchQuery => ({
    type: actionTypes.ORDERS__SEARCH,
    payload: graphql(orderSearch, searchQuery)
  }),

  toggleTransactions: toggle => ({
    type: actionTypes.ORDERS__TOGGLE_TRANSACTIONS,
    payload: { toggle }
  }),

  updateOrderEsthetician: (orderId, estheticianId) => ({
    type: actionTypes.ORDERS__UPDATE_ESTHETICIAN,
    payload: graphql(updateOrderEsthetician, { orderId, estheticianId })
  }),

  updateCommissionPaid: (orderId, commissionPaid) => ({
    type: actionTypes.ORDERS__UPDATE_COMMISSION_PAID,
    payload: graphql(updateCommissionPaid, { orderId, commissionPaid })
  }),

  setAdded: added => ({
    type: actionTypes.ORDERS__ADDED,
    payload: {
      added
    }
  }),

  setShowAll: showAll => ({
    type: actionTypes.ORDERS__SHOW_ALL,
    payload: {
      showAll
    }
  }),

  setFilter: filter => ({
    type: actionTypes.ORDERS__FILTER,
    payload: {
      filter
    }
  }),

  setType: type => ({
    type: actionTypes.ORDERS__TYPE,
    payload: {
      type
    }
  }),

  loadOrdersByUser: ({ userId, sortKey, searchQuery }) =>
    generateAsyncAction(
      actionTypes.ORDERS__LOAD_ALL_BY_USER,
      { userId, sortKey, searchQuery },
      graphql(orderSearch, searchQuery)
    )
};
