import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const productCategory = ImmutablePropTypes.contains({
  id: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired
});

export const productCategories = ImmutablePropTypes.listOf(productCategory);

export const productCategoriesState = ImmutablePropTypes.contains({
  all: productCategories,
  areLoading: PropTypes.bool.isRequired
});
