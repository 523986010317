import { POMP_LIGHT_THEME } from './light';

const invertPalette = palette => ({
  main: palette.main,
  dark: palette.light,
  light: palette.dark,
  contrastText: palette.contrastText,
});

// TODO: This is just for Patrick fucking around in spare time
export const POMP_DARK_THEME = {
  ...POMP_LIGHT_THEME,
  mode: 'dark',
  primary: invertPalette(POMP_LIGHT_THEME.secondary),
  secondary: invertPalette(POMP_LIGHT_THEME.primary),
  tertiary: invertPalette(POMP_LIGHT_THEME.tertiary),
  success: invertPalette(POMP_LIGHT_THEME.success),
  info: invertPalette(POMP_LIGHT_THEME.info),
  warning: invertPalette(POMP_LIGHT_THEME.warning),
  error: invertPalette(POMP_LIGHT_THEME.error),
  background: {
    default: '#0e1114',
    paper: '#222222',
    // Not a generic MUI value, but using it as the background for inputs
    input: '#222222',
    tableOddRow: '#293d4d11',
  },
  text: {
    primary: '#c3bebd',
    secondary: 'rgba(165,160,160,0.75)',
    disabled: 'rgba(165,160,160,0.38)',
  },
  backdrop: {
    overlay: '#FFFFFF55',
  },
};
