import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const PromoCodes = lazy(() =>
  import(
    /* webpackChunkName: "PromoCodes" */ 'components/pages/admin/PromoCodes'
  )
);

export const PromoRoutes = [
  {
    component: PromoCodes,
    isExact: false,
    key: 'promo-codes',
    path: '/promo-codes',
    flag: FEATURE_FLAGS.MANAGE_PROMOS,
  },
];
