import { generateAsyncAction } from 'lib/action-generators';
import { LOAD_COLLECTIONS } from './action-types';
import loadCollections from '../graphql/queries/load-collections.graphql';
import graphql from 'lib/api/graphql';

export default {
  loadCollections: () =>
    generateAsyncAction(
      LOAD_COLLECTIONS,
      {},
      graphql(loadCollections),
      'loadCollections'
    ),
};
