import * as actionTypes from 'constants/actionTypes';
import appStart from 'graphQL/queries/appStart';
import graphql from 'lib/api/graphql';

export default {
  start: () => ({
    type: actionTypes.APP__START,
    payload: graphql(appStart)
  })
};
