import { ProgressPhoto } from '@pomp-libs/core';
import {
  ADD__PROGRESS_PHOTO,
  DELETE__PROGRESS_PHOTO,
  LOAD__PROGRESS_PHOTOS,
} from '../actions/action-types';

type ProgressPhotosReducerState = {
  progressPhotos: ProgressPhoto[];
  progressPhotosById: {
    [id: string]: ProgressPhoto;
  };
};

type ProgressPhotoActionType =
  | typeof ADD__PROGRESS_PHOTO
  | typeof DELETE__PROGRESS_PHOTO
  | typeof LOAD__PROGRESS_PHOTOS;

type ProgressPhotoReducerAction = {
  type: ProgressPhotoActionType;
  status: 'request' | 'success' | 'error';
  payload: any;
};

const initialState: ProgressPhotosReducerState = {
  progressPhotos: [],
  progressPhotosById: {},
};

export const ProgressPhotosReducer = (
  state: ProgressPhotosReducerState = initialState,
  { type, payload, status }: ProgressPhotoReducerAction
) => {
  switch (type) {
    case LOAD__PROGRESS_PHOTOS: {
      if (status !== 'success') return state;

      const progressPhotosById = payload.reduce(
        (
          accumulator: { [key: string]: ProgressPhoto },
          photo: ProgressPhoto
        ) => {
          accumulator[photo.id] = photo;
          return accumulator;
        },
        {} as { [key: string]: ProgressPhoto }
      );

      return {
        ...state,
        progressPhotos: payload,
        progressPhotosById,
      };
    }

    case ADD__PROGRESS_PHOTO: {
      if (status !== 'success') return state;

      const updatedPhotosById = {
        ...state.progressPhotosById,
        [payload.id]: payload,
      };

      const updated = Object.values(updatedPhotosById);
      return {
        progressPhotosById: updatedPhotosById,
        progressPhotos: updated,
      };
    }

    case DELETE__PROGRESS_PHOTO: {
      if (status !== 'success') return state;
      const updated = state.progressPhotos.filter(
        photo => photo.id !== payload
      );
      return {
        progressPhotos: updated,
      };
    }

    default:
      return state;
  }
};
