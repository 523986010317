import { generateAsyncAction } from 'lib/action-generators';
import {
  CREATE_TAG,
  DELETE_TAG,
  LOAD_TAG,
  SEARCH_TAGS,
  EDIT_TAG,
  ADD_PRODUCTS_TO_TAG,
  DELETE_PRODUCT_TAG,
  ADD_TAG_TO_PRODUCTS,
} from './action-types';
import graphql from 'lib/api/graphql';

import searchTags from '../graphql/queries/search-tags.graphql';
import loadTag from '../graphql/queries/load-tag';
import createTag from '../graphql/mutations/create-tag';
import deleteTag from '../graphql/mutations/delete-tag';
import editTag from '../graphql/mutations/edit-tag';

import addProductsToTag from '../graphql/mutations/add-products-to-tag';
import deleteProductTag from '../graphql/mutations/delete-product-tag';
import addTagsToProduct from '../graphql/mutations/add-tags-to-product';

const TagActions = {
  searchTags: searchData =>
    generateAsyncAction(
      SEARCH_TAGS,
      searchData,
      graphql(searchTags, searchData),
      'searchTags'
    ),

  createTag: (name, type) =>
    generateAsyncAction(
      CREATE_TAG,
      { name, type },
      graphql(createTag, { name, type }),
      'createTag'
    ),

  deleteTag: id =>
    generateAsyncAction(
      DELETE_TAG,
      { id },
      graphql(deleteTag, { id }),
      'deleteTag'
    ),

  editTag: (id, name, type) =>
    generateAsyncAction(
      EDIT_TAG,
      { id },
      graphql(editTag, { id, name, type }),
      'editTag'
    ),

  loadTag: id =>
    generateAsyncAction(LOAD_TAG, { id }, graphql(loadTag, { id }), 'loadTag'),

  // Adds multiple products to a tag
  addProductsToTag: (id, productIds) =>
    generateAsyncAction(
      ADD_PRODUCTS_TO_TAG,
      { id, productIds },
      graphql(addProductsToTag, { id, productIds }),
      'addProductsToTag'
    ),

  deleteProductTag: (id, productId) =>
    generateAsyncAction(
      DELETE_PRODUCT_TAG,
      { id, productId },
      graphql(deleteProductTag, { id, productId }),
      'deleteProductTag'
    ),

  // Adds multiple tags to a product
  addTagsToProduct: (id, tagIds) =>
    generateAsyncAction(
      ADD_TAG_TO_PRODUCTS,
      { id, tagIds },
      graphql(addTagsToProduct, { id, tagIds }),
      'addTagsToProduct'
    ),
};

export default TagActions;
