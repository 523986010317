import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from 'components/App';
import authUserActions from 'actionCreators/authUser';
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import store from './store';
import {
  DialogProvider,
  SideBarAwareThemeProvider,
  SidecarProvider,
  ToastProvider,
  NotificationsProvider,
} from 'providers';
import * as Sentry from '@sentry/react';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ErrorBoundary } from './error-boundary';
import { AuthProvider } from 'features/auth';
import { InterceptedRequestProvider } from 'features/intercepted-requests';

const TwilioProvider = lazy(() =>
  import(
    /* webpackChunkName: "TwilioProvider" */ 'providers/TwilioProvider'
  ).then(({ TwilioProvider }) => ({
    default: TwilioProvider,
  }))
);

Sentry.init({
  dsn: 'https://7cb4a3f9553749dcba076acaf0917e90@o1057720.ingest.sentry.io/6044717',
  environment: process.env.NODE_ENV || 'local',
  integrations: [
    // Add browser profiling integration to the list of integrations
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],
  // Let's have custom sample rates for each env.
  tracesSampler: () => {
    switch (process.env.NODE_ENV) {
      case 'production':
        return 0.05;
      case 'staging':
      case 'dev':
      case 'local':
      default:
        return 0.2; // We have way less traffic on staging and dev so let's sample more.
    }
  },
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/([a-z0-9]+[.])*pompbeauty[.]com/g,
  ],
  // Capture api call details
  networkDetailAllowUrls: [
    /^https:\/\/([a-z0-9]+[.])*pompbeauty[.]com\/api\/graphql/g,
    /^http:\/\/localhost:(\d)+\/api\/graphql/g,
  ],
  profilesSampleRate: 0.1, // This is relative to tracesSampleRate so this will profile 0.5% of total sessions on production and less.
  replaysSessionSampleRate: 0.0001, // Let's sample 0.1% of sessions for replay
  replaysOnErrorSampleRate: 0.1, // When an error happens, let's replay 1% of sessions
});

let authUserFromLocalStorage = window.localStorage.getItem('pomp-authUser');
if (authUserFromLocalStorage)
  Sentry.setUser(JSON.parse(authUserFromLocalStorage));

export const logout = () => {
  store.dispatch(authUserActions.logout());
};

ReactDOM.render(
  <Suspense fallback={null}>
    <ErrorBoundary sentry={Sentry}>
      <BrowserRouter>
        <Provider store={store}>
          <AuthProvider>
            <InterceptedRequestProvider>
              <SideBarAwareThemeProvider>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <ToastProvider>
                    <TwilioProvider>
                      <DialogProvider>
                        <SidecarProvider>
                          <NotificationsProvider>
                            <App />
                          </NotificationsProvider>
                        </SidecarProvider>
                      </DialogProvider>
                    </TwilioProvider>
                  </ToastProvider>
                </LocalizationProvider>
              </SideBarAwareThemeProvider>
            </InterceptedRequestProvider>
          </AuthProvider>
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>
  </Suspense>,
  document.getElementById('react-root')
);
