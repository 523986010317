import {
  ADD_INTERCEPTED_REQUEST,
  ADD_INTERCEPTED_VARIABLES,
  SET_INTERCEPTED_REQUESTS,
  SET_INTERCEPTORS,
  UPDATE_PROCESSED_REQUESTS
} from './action-types';

export const InterceptedRequestActions = {
  addInterceptedRequest: payload => dispatch =>
    dispatch({
      type: ADD_INTERCEPTED_REQUEST,
      payload
    }),
  addInterceptedVariables: payload => dispatch =>
    dispatch({
      type: ADD_INTERCEPTED_VARIABLES,
      payload
    }),
  setInterceptedRequests: payload => dispatch =>
    dispatch({
      type: SET_INTERCEPTED_REQUESTS,
      payload
    }),
  setInterceptors: payload => dispatch =>
    dispatch({
      type: SET_INTERCEPTORS,
      payload
    }),
  updateProcessedRequests: payload => dispatch =>
    dispatch({
      type: UPDATE_PROCESSED_REQUESTS,
      payload
    })
};
