import { generateAsyncAction } from 'lib/action-generators';
import { RECOMMENDATION_UPDATE, RECOMMENDATION_DELETE } from './action-types';
import graphql from 'lib/api/graphql';
import editRecommendation from '../graphql/mutations/edit';
import deleteRecommendation from '../graphql/mutations/delete';
import addRecommendation from '../graphql/mutations/add';

export const RecommendationActions = {
  addRecommendation: recommendation =>
    generateAsyncAction(
      RECOMMENDATION_UPDATE,
      { recommendation },
      graphql(addRecommendation, { recommendation }),
      'addRecommendation'
    ),
  editRecommendation: (id, recommendation) =>
    generateAsyncAction(
      RECOMMENDATION_UPDATE,
      { id, recommendation },
      graphql(editRecommendation, { id, recommendation }),
      'editRecommendation'
    ),
  deleteRecommendation: id =>
    generateAsyncAction(
      RECOMMENDATION_DELETE,
      { id },
      graphql(deleteRecommendation, { id }),
      'addRecommendation'
    )
};
