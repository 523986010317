import { generateAsyncAction } from 'lib/action-generators';
import {
  SUBSCRIPTION_UPDATE,
  SUBSCRIPTION_CANCEL,
  SUBSCRIPTION_CREATE,
} from './constants';
import graphql from 'lib/api/graphql';
import modifySubscription from '../graphql/mutations/modify-subscription';
import cancelSubscription from '../graphql/mutations/cancel-subscription';
import createSubscription from '../graphql/mutations/create-subscription';

export const BillingActions = {
  modifySubscription: (tier, setupIntent = '') =>
    generateAsyncAction(
      SUBSCRIPTION_UPDATE,
      { tier },
      graphql(modifySubscription, { tier, setupIntent }),
      'modifySubscription'
    ),

  cancelSubscription: subscriptionId =>
    generateAsyncAction(
      SUBSCRIPTION_CANCEL,
      { subscriptionId },
      graphql(cancelSubscription, { subscriptionId }),
      'cancelSubscription'
    ),

  createSubscription: subscriptionInterval =>
    generateAsyncAction(
      SUBSCRIPTION_CREATE,
      { subscriptionInterval },
      graphql(createSubscription, { subscriptionInterval }),
      'createSubscription'
    ),
};
