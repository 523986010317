import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const FindEstie = lazy(() =>
  import(/* webpackChunkName: "FindEstie" */ '../components')
);

export const FindEstieRoutes = [
  {
    component: FindEstie,
    isExact: false,
    key: 'find-estie',
    path: '/find-an-esthetician',
    flag: FEATURE_FLAGS.FIND_ESTIES,
  },
];
