import * as actionTypes from 'constants/actionTypes';
import graphql from 'lib/api/graphql';
import surveyLoad from 'graphQL/queries/surveyLoad';
import surveySave from 'graphQL/queries/surveySave';
import surveyDeleteAnswer from 'graphQL/mutations/surveyDeleteAnswer';

export default {
  load: () => ({
    type: actionTypes.SURVEY__LOAD,
    payload: graphql(surveyLoad)
  }),

  setAnswer:
    ({ answer, choices, question }) =>
    dispatch => {
      dispatch({
        type: actionTypes.SURVEY__SET_ANSWER,
        payload: { answer, choices, question }
      });
    },

  save: id => (dispatch, getState) => {
    const capturedUser = JSON.parse(
      window.localStorage.getItem('capturedUser')
    );
    // if browser refreshes state will be empty, get id from local storage
    const capturedUserId =
      getState().getIn(['authUser', 'captureId']) || capturedUser?.captureId;
    const userId = capturedUserId || getState().getIn(['authUser', 'Id']);

    const answers = [];
    const rawAnswers = getState()
      .getIn(['survey', 'answers'])
      .filter(answer => answer.get('question') === id);

    rawAnswers.forEach((questionAnswer, questionId) => {
      if (questionAnswer.get('answer')) {
        return answers.push({
          answer: questionAnswer.get('answer'),
          question: questionId,
          user: userId
        });
      }

      if (!questionAnswer.get('choices')) return true;

      return questionAnswer.get('choices').forEach((choice, choiceId) =>
        answers.push({
          answer: choice.get('answer'),
          choice: choiceId,
          question: questionId,
          user: userId
        })
      );
    });

    return {
      type: actionTypes.SURVEY__SAVE_ANSWERS,
      payload: graphql(surveySave, { answers })
    };
  },

  setSavedAnswers: () => ({
    type: actionTypes.SURVEY__SAVE_ANSWERS_FULFILLED
  }),

  deleteAnswer: (choiceId, userId) => async dispatch => {
    const payload = await graphql(surveyDeleteAnswer, {
      choice: choiceId,
      user: userId
    });
    return dispatch({
      type: actionTypes.SURVEY__DELETE_ANSWER_FULFILLED,
      payload
    });
  },

  saveStep: step => ({
    type: actionTypes.SURVEY__SAVE_STEP,
    payload: {
      step
    }
  }),

  openSurvey: () => ({
    type: actionTypes.SURVEY__IS_OPEN
  }),

  closeSurvey: () => ({
    type: actionTypes.SURVEY__IS_CLOSE
  })
};
