import { generateAsyncAction } from '../lib/action-generators';
import { REPORT_LOADED } from '../constants/actionTypes';
import graphql from '../lib/api/graphql';
import getReport from '../graphQL/queries/reports-get.graphql';
import listReports from '../graphQL/queries/reports-list.graphql';
import runReport from '../graphQL/queries/reports-run.graphql';

export const reportActions = {
  getReport: id =>
    generateAsyncAction(REPORT_LOADED, { id }, graphql(getReport, { id })),
  listReports: query =>
    generateAsyncAction(
      REPORT_LOADED,
      { query },
      graphql(listReports, { query })
    ),
  runReport: (id, filters) =>
    generateAsyncAction(
      REPORT_LOADED,
      { id, filters },
      graphql(runReport, { id, filters })
    )
};
