import { FEATURE_FLAGS, ROLES } from '@pomp-libs/core';
import { lazy } from 'react';

const ProStore = lazy(() =>
  import(/* webpackChunkName: "ProStore" */ '../components')
);
const AboutFormWrapper = lazy(() =>
  import(
    /* webpackChunkName: "AboutFormWrapper" */ '../components/about-form-wrapper'
  )
);

export const ProStoreRoutes = [
  {
    component: ProStore,
    isExact: true,
    key: 'pomp-pro',
    path: '/pomp-pro',
    flag: FEATURE_FLAGS.ESTHE_PRO_STORE,
    role: [ROLES.ESTHETICIAN, ROLES.CUSTOMER],
    isAuthed: true,
  },
  {
    component: AboutFormWrapper,
    isExact: true,
    key: 'pomp-pro',
    path: '/pomp-pro/edit',
    flag: FEATURE_FLAGS.ESTHE_PRO_STORE,
    role: [ROLES.ESTHETICIAN],
    isAuthed: true,
  },
  {
    component: ProStore,
    isExact: true,
    key: 'pomp-pro',
    path: '/pomp-pro/:storeUserId',
    flag: FEATURE_FLAGS.PRO_STORE,
  },
];
