import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const VendorSearchPage = lazy(() =>
  import(
    /* webpackChunkName: "RoutineView" */ 'features/vendors/pages/vendor-search'
  ).then(({ VendorSearchPage }) => ({
    default: VendorSearchPage,
  }))
);

export const VendorRoutes = [
  {
    component: VendorSearchPage,
    isExact: false,
    key: 'vendors',
    path: '/vendors',
    flag: FEATURE_FLAGS.MANAGE_PRODUCTS,
  },
];
