export const InterceptedRequestInitialState = {
  variablesByType: {
    user: {
      guest: null
    },
    cart: {
      guest: null
    }
  },
  requests: [],
  interceptors: {}
};
