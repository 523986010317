import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const Checkout = lazy(() =>
  import(/* webpackChunkName: "Checkout" */ 'components/pages/StripeCheckout')
);

export const CheckoutRoutes = [
  {
    component: Checkout,
    isExact: true,
    key: 'checkout',
    path: '/checkout',
    flag: FEATURE_FLAGS.CHECKOUT,
  },
];
