import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export const twilio = ImmutablePropTypes.contains({
  ready: PropTypes.bool,
  identity: PropTypes.string,
  status: PropTypes.string,
  open: PropTypes.bool,
  currentToken: PropTypes.string,
  conversations: PropTypes.any,
  unconsumedMessages: PropTypes.number,
  unconsumedConversations: PropTypes.any,
  newMessage: PropTypes.any,
  clients: PropTypes.bool,
  estheticianId: PropTypes.string,
  err: PropTypes.any
});
