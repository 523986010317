import * as actionTypes from 'constants/actionTypes';
import createReducer from 'reducers/createReducer';
import Immutable from 'immutable';
import initialState from './initialState';
import { asyncActionStatuses } from 'lib/action-generators';
import {
  ORDERS__DELETE_SHIPMENT,
  ORDERS__LOAD_TRACKING_NUMBERS_FULFILLED,
  ORDERS__UPDATE_SHIPMENTS
} from 'features/shipments/actions/actions';

export default createReducer(initialState, {
  [actionTypes.ORDERS__LOAD_ALL_PENDING]: state => state.set('isLoading', true),

  [actionTypes.ORDERS__LOAD_ALL_FULFILLED]: (state, { payload: { data } }) => {
    if (!data) {
      state.set('isLoading', false);
      return state;
    }

    return state.merge({
      isLoading: false,
      orders: data.orders || []
    });
  },

  [actionTypes.CHECKOUT__PLACE_ORDER_FULFILLED]: (
    state,
    { payload: { data } }
  ) => {
    state.setIn(
      ['orders', data.placeOrder.orderNumber],
      Immutable.fromJS(data.placeOrder)
    );
  },

  [actionTypes.AUTH_USER__LOGOUT]: () => initialState,

  [actionTypes.ORDER__LOAD]: (state, { payload: { data } }) => {
    if (!data?.order) return state;

    return state.merge({
      ordersById: {
        [data.order.id]: data.order
      }
    });
  },

  [actionTypes.ORDERS__UPDATE_ESTHETICIAN_FULFILLED]: (
    state,
    { payload: { data } }
  ) => {
    if (!data) return state;
    const orders = state.get('ordersBasic').toJS();
    orders.find(o => o.id === data.updateOrderEsthetician.id).estheticianId =
      data.updateOrderEsthetician.estheticianId;
    return state.set('ordersBasic', Immutable.fromJS(orders));
  },

  [actionTypes.ORDERS__UPDATE_COMMISSION_PAID_FULFILLED]: (
    state,
    { payload: { data } }
  ) => {
    if (!data) return state;
    const orders = state.get('ordersBasic').toJS();
    orders.find(o => o.id === data.updateCommissionPaid.id).commissionPaid =
      data.updateCommissionPaid.commissionPaid;
    return state.set('ordersBasic', Immutable.fromJS(orders));
  },

  [actionTypes.USERS__LOAD_ESTHETICIANS_FULFILLED]: (
    state,
    { payload: { data } }
  ) => {
    if (!data) return state;
    return state.merge(data);
  },

  [ORDERS__LOAD_TRACKING_NUMBERS_FULFILLED]: (state, { payload: { data } }) => {
    if (!data) return state;
    return state.merge(data);
  },

  [actionTypes.ORDERS__SEARCH_FULFILLED]: (state, { payload: { data } }) =>
    state.merge({
      isLoading: false,
      ordersById: Immutable.fromJS(
        data?.orderSearch?.data?.reduce(
          (acc, order) => ({ ...acc, [order.id]: order }),
          {}
        ) ?? {}
      )
    }),

  [actionTypes.ORDERS__TOGGLE_TRANSACTIONS]: (state, { payload: { toggle } }) =>
    state.merge({
      failedTransactionsToggle: toggle
    }),

  [actionTypes.ORDERS__SHOW_ALL]: (state, { payload: { showAll } }) =>
    state.merge({ showAll }),

  [actionTypes.ORDERS__ADDED]: (state, { payload: { added } }) =>
    state.merge({ added }),

  [actionTypes.ORDERS__FILTER]: (state, { payload: { filter } }) =>
    state.merge({ filter }),

  [actionTypes.ORDERS__TYPE]: (state, { payload: { type } }) =>
    state.merge({ type }),

  [ORDERS__UPDATE_SHIPMENTS]: (state, { status, payload }) => {
    if (status !== 'success') return state;

    const shipments = state
      .getIn(['ordersById', payload.orderId, 'shipments'])
      .toJS();

    let existing = false;

    const updatedShipments = shipments.map(s => {
      if (s.id !== payload.id) return s;
      existing = true;
      return payload;
    });

    if (!existing) updatedShipments.push(payload);

    return state.mergeIn(
      ['ordersById', payload.orderId, 'shipments'],
      updatedShipments
    );
  },

  [ORDERS__DELETE_SHIPMENT]: (state, { status, requestPayload }) => {
    if (status !== 'success') return state;

    const shipments = state
      .getIn(['ordersById', requestPayload.orderId, 'shipments'])
      .toJS();

    const updatedShipments = shipments.reduce((acc, shipment) => {
      if (shipment.id === requestPayload.shipmentId) return acc;
      return [...acc, shipment];
    }, []);

    return state.setIn(
      ['ordersById', requestPayload.orderId, 'shipments'],
      Immutable.fromJS(updatedShipments)
    );
  },

  [actionTypes.ORDERS__LOAD_ALL_BY_USER]: (
    state,
    { status, payload, requestPayload }
  ) => {
    switch (status) {
      case asyncActionStatuses.REQUEST: {
        return state.merge({
          ordersByUserId: {
            [requestPayload.userId]: {
              [requestPayload.sortKey]: {
                loading: true
              }
            }
          }
        });
      }

      case asyncActionStatuses.SUCCESS: {
        return state.merge({
          ordersByUserId: {
            [requestPayload.userId]: {
              [requestPayload.sortKey]: {
                loading: false,
                orders: payload.data.orderSearch.data
              }
            }
          }
        });
      }
    }

    return state;
  }
});
