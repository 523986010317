import {
  LOAD__PRO_STORE,
  SUBMIT_PRO_PROFILE,
  PRO_STORE_SEARCH,
} from '../actions/action-types';
import { ProStoreReducerInitialState } from './initial-state';
import {
  PRODUCT__ADD_FAVORITE,
  PRODUCT__REMOVE_FAVORITE,
} from 'constants/actionTypes';

export const ProStoreReducer = (
  state = ProStoreReducerInitialState,
  { type, payload, status }
) => {
  switch (type) {
    case LOAD__PRO_STORE: {
      if (status === 'request') return state;

      if (status === 'success') {
        const {
          data: { proStore = {} },
        } = payload;

        return {
          ...state,
          ...proStore,
        };
      }
      return state;
    }

    case PRODUCT__ADD_FAVORITE: {
      // Not an elevated esthe so we don't need to add it to the pro store products.
      if (!state.products || status === 'request') return state;

      if (status === 'success') {
        const {
          data: { addFavorite },
        } = payload;

        const existing = state.products.find(
          product => product.id === addFavorite.id
        );

        if (existing) return state;

        // Add new product to the store
        const updatedProducts = [...state.products];
        updatedProducts.push(addFavorite);

        return {
          ...state,
          products: updatedProducts,
        };
      }
      return state;
    }

    case PRODUCT__REMOVE_FAVORITE: {
      // Not an elevated esthe so we don't need to manage the store products.
      if (!state.products || status === 'request') return state;

      if (status === 'success') {
        const {
          data: { removeFavorite },
        } = payload;

        // Remove the product from the store list
        const updatedProducts = state.products.filter(
          product => product.id !== removeFavorite
        );

        return {
          ...state,
          products: updatedProducts,
        };
      }
      return state;
    }

    case SUBMIT_PRO_PROFILE: {
      if (status !== 'success') return state;

      const { profile = {} } = payload;
      return {
        ...state,
        profile,
      };
    }

    case PRO_STORE_SEARCH: {
      if (status === 'request') return state;

      if (status === 'success') {
        return {
          ...state,
          products: [...payload.data],
          vendors: payload.vendors,
          productCategories: payload.productCategories,
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export default ProStoreReducer;
