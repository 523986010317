import { SET__MESSAGE, REMOVE__MESSAGE } from '../../../constants/actionTypes';
import { MessagesReducerInitialState } from './initial-state';

export const MessagesReducer = (
  state = MessagesReducerInitialState,
  { type, payload, status }
) => {
  switch (type) {
    case SET__MESSAGE: {
      if (status === 'request') return state;

      if (status === 'success') {
        return {
          message: payload,
        };
      }
      return state;
    }

    case REMOVE__MESSAGE: {
      if (status === 'request') return state;
      if (status === 'success') {
        return {
          ...payload,
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export default MessagesReducer;
