import * as actionTypes from 'constants/actionTypes';
import graphql from 'lib/api/graphql';
import vendorSearch from 'graphQL/queries/vendorSearch';
import vendorSave from 'graphQL/mutations/admin/vendor';

export default {
  /**
   * @param searchParams {
   * query?: string,
   * filters?: {
   *  id?: string,
   *  hasVendorId?: string,
   *  displayInStore?: boolean,
   * },
   * sort?: {
   *  field: string,
   *  direction: 'asc' | 'desc'
   * },
   * pagination?: {
   *   page: number,
   *   size: number
   * }
   * }
   **/
  loadAll: searchParams => dispatch =>
    dispatch({
      type: actionTypes.VENDORS__LOAD_ALL,
      payload: graphql(vendorSearch, searchParams)
    }),

  save: vendor => dispatch =>
    dispatch({
      type: actionTypes.VENDORS__SAVE,
      payload: graphql(vendorSave, { vendor })
    })
};
