import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const CategorySearchPage = lazy(() =>
  import('features/categories/pages/category-search').then(
    ({ CategorySearchPage }) => ({
      default: CategorySearchPage
    })
  )
);

export const CategoryRoutes = [
  {
    component: CategorySearchPage,
    isExact: true,
    key: 'categories',
    path: '/categories',
    flag: FEATURE_FLAGS.MANAGE_PRODUCTS
  }
];
