import * as productPropTypes from './products';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const cartProduct = ImmutablePropTypes.contains({
  id: PropTypes.string,
  product: productPropTypes.product,
  quantity: PropTypes.number.isRequired
});

export const cartProducts = ImmutablePropTypes.listOf(cartProduct);

const cartRaw = {
  id: PropTypes.string,
  products: cartProducts
};

export const cart = ImmutablePropTypes.contains(cartRaw);

export const cartState = ImmutablePropTypes.contains({
  isLoading: PropTypes.bool.isRequired,
  ...cartRaw
});
