import actionCreators from 'actionCreators';
import { useActions } from 'components/shared/hooks/useActions';
import { useSelector } from 'react-redux';

export const useAuthActions = () => {
  const actions = useActions(actionCreators.authUserActions);
  const authUser = useSelector(state => state.get('authUser'));

  return {
    authUser,
    ...actions,
  };
};
