import * as actionTypes from 'constants/actionTypes';
import addFavorite from 'graphQL/mutations/product/add-favorite-product';
import addFeatured from 'graphQL/mutations/product/add-featured-product';
import giftCardProducts from 'graphQL/queries/giftCardProducts';
import { generateAsyncAction } from 'lib/action-generators';
import graphql from 'lib/api/graphql';
import { htmlParser } from 'components/shared/HtmlParser';
import productDelete from 'graphQL/mutations/admin/productDelete';
import productLoadToEdit from 'graphQL/queries/productLoadToEdit';
import productSave from 'graphQL/mutations/admin/product';
import saveProductImages from 'features/products/graphql/mutations/save-product-images';
import deleteProductImage from 'features/products/graphql/mutations/delete-product-image';
import saveDefaultImage from 'features/products/graphql/mutations/save-default-image';
import productSearch from 'graphQL/queries/productSearch';
import productSearchBasic from 'graphQL/queries/product-search-basic';
import productSearchTotals from 'graphQL/queries/productSearchTotals';
import removeFavorite from 'graphQL/mutations/product/remove-favorite-product';
import removeFeatured from 'graphQL/mutations/product/remove-featured-product';
import sendAskMyEstieRequest from '../graphql/mutations/send-ask-my-estie-request';
import loadProductRules from '../graphql/queries/load-product-rules.graphql';
import addProductsToBrandRules from '../graphql/mutations/add-products-to-brand-rules.graphql';
import deleteProductRule from '../graphql/mutations/delete-product-rule.graphql';
import editProductRule from '../graphql/mutations/edit-product-rule.graphql';
import bulkAddProductImages from 'features/products/graphql/mutations/bulk-add-product-images';

import {
  PRODUCT__ASK_MY_ESTIE_REQUEST,
  LOAD__PRODUCT_RULES,
  ADD__PRODUCTS_TO_BRAND_RULES,
  DELETE__PRODUCT_RULE,
  EDIT__PRODUCT_RULE,
  DELETE__PRODUCT_IMAGE,
  SAVE__DEFAULT_IMAGE,
  SAVE__PRODUCT_IMAGES,
  BULK__ADD_PRODUCT_IMAGES,
} from './constants';

export default {
  search: args => ({
    type: actionTypes.PRODUCT__SEARCH,
    payload: graphql(productSearch, args),
  }),

  getSearchTotals: userId =>
    generateAsyncAction(
      actionTypes.PRODUCT__SET_TOTALS,
      { userId },
      graphql(productSearchTotals, { userId })
    ),

  loadToEdit: productId => ({
    type: actionTypes.PRODUCT__LOAD_TO_EDIT,
    payload: graphql(productLoadToEdit, { product: productId }),
  }),

  loadProduct: productId =>
    generateAsyncAction(
      actionTypes.PRODUCT__LOAD,
      { product: productId },
      graphql(productLoadToEdit, { product: productId }),
      'product'
    ),

  clearProductToEdit: () =>
    generateAsyncAction(
      actionTypes.PRODUCT__CLEAR_LOAD_TO_EDIT,
      {},
      (async function () {
        return { productToEdit: null };
      })()
    ),

  saveProduct: product => {
    product.retailPrice = parseFloat(product.retailPrice || 0, 10);
    product.wholesalePrice = parseFloat(product.wholesalePrice || 0, 10);
    product.description = htmlParser('sanitizedHTML', product.description);

    return {
      type: actionTypes.PRODUCT__SAVE,
      payload: graphql(productSave, { product }),
    };
  },

  deleteOrRestoreProduct: productId => ({
    type: actionTypes.PRODUCT__DELETE,
    payload: graphql(productDelete, { product: productId }),
    meta: { product: productId },
  }),

  giftCardProducts: () => ({
    type: actionTypes.PRODUCT__GIFT_CARD_PRODUCTS,
    payload: graphql(giftCardProducts),
  }),

  addFavorite: productId =>
    generateAsyncAction(
      actionTypes.PRODUCT__ADD_FAVORITE,
      { productId },
      graphql(addFavorite, { productId })
    ),

  removeFavorite: productId =>
    generateAsyncAction(
      actionTypes.PRODUCT__REMOVE_FAVORITE,
      { productId },
      graphql(removeFavorite, { productId })
    ),

  addFeatured: productId =>
    generateAsyncAction('', { productId }, graphql(addFeatured, { productId })),

  removeFeatured: productId =>
    generateAsyncAction(
      '',
      { productId },
      graphql(removeFeatured, { productId })
    ),

  productSearchBasic: vendorId =>
    generateAsyncAction(
      actionTypes.PRODUCT__SEARCH_BASIC,
      { vendorId },
      graphql(productSearchBasic, { vendorId }),
      'productSearchBasic'
    ),

  sendAskMyEstieRequest: productId =>
    generateAsyncAction(
      PRODUCT__ASK_MY_ESTIE_REQUEST,
      { productId },
      graphql(sendAskMyEstieRequest, { productId }),
      'sendAskMyEstieRequest'
    ),

  loadProductRules: vendorId =>
    generateAsyncAction(
      LOAD__PRODUCT_RULES,
      { vendorId },
      graphql(loadProductRules, { vendorId }),
      'loadProductRules'
    ),

  addProductsToBrandRules: (vendorId, productIds) =>
    generateAsyncAction(
      ADD__PRODUCTS_TO_BRAND_RULES,
      { vendorId, productIds },
      graphql(addProductsToBrandRules, { vendorId, productIds }),
      'addProductsToBrandRules'
    ),

  deleteProductRule: productId =>
    generateAsyncAction(
      DELETE__PRODUCT_RULE,
      { productId },
      graphql(deleteProductRule, { productId }),
      'deleteProductRule'
    ),

  editProductRule: (productId, input) =>
    generateAsyncAction(
      EDIT__PRODUCT_RULE,
      { productId, input },
      graphql(editProductRule, { productId, input }),
      'editProductRule'
    ),

  saveProductImages: (imageUrl, productId) =>
    generateAsyncAction(
      SAVE__PRODUCT_IMAGES,
      { imageUrl, productId },
      graphql(saveProductImages, { imageUrl, productId }),
      'saveProductImages'
    ),
  deleteProductImage: (imageId, productId) =>
    generateAsyncAction(
      DELETE__PRODUCT_IMAGE,
      { imageId, productId },
      graphql(deleteProductImage, { imageId, productId }),
      'deleteProductImage'
    ),
  saveDefaultImage: (imageId, productId) =>
    generateAsyncAction(
      SAVE__DEFAULT_IMAGE,
      { imageId, productId },
      graphql(saveDefaultImage, { imageId, productId }),
      'saveDefaultImage'
    ),

  bulkAddProductImages: csv =>
    generateAsyncAction(
      BULK__ADD_PRODUCT_IMAGES,
      { csv },
      graphql(bulkAddProductImages, { csv }),
      'bulkAddProductImages'
    ),
};
