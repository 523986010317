import Immutable from 'immutable';

const blankAuthUser = {
  addresses: [],
  email: null,
  firstName: null,
  id: null,
  jwt: null,
  twilioAccessToken: null,
  lastName: null,
  paymentMethods: [],
  role: null,
  referrerDetails: {},
  referralDetails: {},
  captureId: null,
  completedSurvey: false,
  agreedToTerms: null,
  impersonate: null,
  created: null,
  profileImage: null,
  featureFlags: [],
  subscription: null,
  previousSubscriptions: [],
  estheticianPromoCode: '',
  license: {},
  stripeId: '',
  tier: '',
};

export default Immutable.fromJS(blankAuthUser);
