import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const SurveyAnswers = lazy(() =>
  import(
    /* webpackChunkName: "SurveyAnswers" */ 'components/pages/admin/SurveyAnswers'
  )
);
const Survey = lazy(() =>
  import(/* webpackChunkName: "Survey" */ 'components/pages/Survey')
);
const SurveyRegister = lazy(() =>
  import(/* webpackChunkName: "SurveyRegister" */ '../components')
);

export const SurveyRoutes = [
  {
    component: SurveyAnswers,
    isExact: false,
    key: 'survey-answers',
    path: '/survey-answers/:userId',
    flag: FEATURE_FLAGS.VIEW_SURVEYS,
  },
  {
    component: Survey,
    isExact: true,
    key: 'survey',
    path: '/survey',
  },
  {
    component: SurveyRegister,
    isExact: true,
    key: 'survey-register',
    path: '/survey/register',
  },
  {
    component: Survey,
    isExact: true,
    key: 'survey-question',
    path: '/survey/:questionId',
  },
];
