import { applyMiddleware, createStore } from 'redux';
import combinedReducer from './reducers';
import promiseMiddleware from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

const middleware = [applyMiddleware(thunk), applyMiddleware(promiseMiddleware)];
const composeEnhancers = composeWithDevTools({
  trace: process.env.NODE_ENV === 'development',
  traceLimit: 25
});

export default createStore(combinedReducer, composeEnhancers(...middleware));
