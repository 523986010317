import * as actionTypes from 'constants/actionTypes';
import addressMutation from 'graphQL/mutations/address';
import addressDeleteMutation from 'graphQL/mutations/addressDelete';
import graphql from 'lib/api/graphql';

export default {
  create: address => dispatch => {
    return dispatch({
      type: actionTypes.ADDRESS__CREATE,
      payload: graphql(addressMutation, { address })
    });
  },

  delete: address => ({
    type: actionTypes.ADDRESS__DELETE,
    payload: graphql(addressDeleteMutation, { address })
  })
};
