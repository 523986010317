import addressActions from './address';
import appActions from './app';
import authUserActions from './authUser';
import noteActions from './notes';
import orderActions from './orders';
import paymentMethodActions from './paymentMethod';
import productActions from '../features/products/actions/actions';
import productCategoryActions from './productCategory';
import promoCodeActions from '../features/promos/action-creators/actions';
import surveyActions from './survey';
import checkoutActions from './checkout';
import userActions from '../features/users/actions';
import vendorsActions from './vendors';
import messageActions from './message';
import creditActions from './credits';
import loaderActions from './loader';
import loadProductsActions from './loadProducts';
import notificationActions from '../features/notifications/actions';
import { reportActions } from './reports';
import { dashboardActions } from './dashboards';
import { RoutineActions as routineActions } from 'features/routines/action-creators';
import { RecommendationActions as recommendationActions } from 'features/recommendations/action-creators';
import { BillingActions as billingActions } from 'features/billing/actions';
import { ShipmentActions as shipmentActions } from 'features/shipments/actions';
import { ProductVariantActions as productVariantActions } from 'features/products/actions';
import customLinkActions from '../features/customLinks/actions';
import proStoreActions from '../features/pro-stores/actions';
import tagActions from '../features/tags/actions';
import brandEducationActions from '../features/brand-educations/actions';
import tasksActions from '../features/tasks/actions';
import estheticianSearchActions from '../features/find-esties/actions';
import wishlistActions from '../features/wishlists/actions';
import businessActions from 'features/businesses/actions';
import collectionActions from 'features/collections/actions';
import storeActions from 'features/enhancements-store/actions';
import progressPhotoActions from 'features/progress-photos/actions';

export default {
  addressActions,
  appActions,
  authUserActions,
  billingActions,
  brandEducationActions,
  businessActions,
  checkoutActions,
  collectionActions,
  creditActions,
  customLinkActions,
  dashboardActions,
  estheticianSearchActions,
  loaderActions,
  loadProductsActions,
  messageActions,
  noteActions,
  notificationActions,
  orderActions,
  paymentMethodActions,
  proStoreActions,
  productActions,
  productCategoryActions,
  productVariantActions,
  progressPhotoActions,
  promoCodeActions,
  recommendationActions,
  reportActions,
  routineActions,
  shipmentActions,
  storeActions,
  surveyActions,
  tagActions,
  tasksActions,
  userActions,
  vendorsActions,
  wishlistActions,
};
