import { generateAsyncAction } from 'lib/action-generators';
import {
  PRODUCT_VARIANT_GROUP_BULK_UPDATE,
  PRODUCT_VARIANT_GROUP_DELETE,
  PRODUCT_VARIANT_GROUP_PRODUCT_DELETE,
  PRODUCT_VARIANT_GROUP_PRODUCT_UPDATE,
  PRODUCT_VARIANT_GROUP_UPDATE,
} from './variants.constants';
import graphql from 'lib/api/graphql';

import productVariantGroupSearch from '../graphql/queries/product-variant-group-search';
import getProductVariantGroup from '../graphql/queries/get-product-variant-group';
import addProductVariantGroup from '../graphql/mutations/add-product-variant-group';
import editProductVariantGroup from '../graphql/mutations/edit-product-variant-group';
import deleteProductVariantGroup from '../graphql/mutations/delete-product-variant-group';
import addProductVariantGroupProduct from '../graphql/mutations/add-product-variant-group-product';
import editProductVariantGroupProduct from '../graphql/mutations/edit-product-variant-group-product';
import deleteProductVariantGroupProduct from '../graphql/mutations/delete-product-variant-group-product';

export const ProductVariantActions = {
  // Queries
  productVariantGroupSearch: searchData =>
    generateAsyncAction(
      PRODUCT_VARIANT_GROUP_BULK_UPDATE,
      searchData,
      graphql(productVariantGroupSearch, searchData),
      'productVariantGroupSearch'
    ),
  getProductVariantGroup: id =>
    generateAsyncAction(
      PRODUCT_VARIANT_GROUP_UPDATE,
      { id },
      graphql(getProductVariantGroup, { id }),
      'getProductVariantGroup'
    ),

  // PRODUCT VARIANT GROUP ACTIONS
  addProductVariantGroup: (name, variationName) =>
    generateAsyncAction(
      PRODUCT_VARIANT_GROUP_UPDATE,
      { name, variationName },
      graphql(addProductVariantGroup, { name, variationName }),
      'addProductVariantGroup'
    ),
  editProductVariantGroup: (id, name, variationName) =>
    generateAsyncAction(
      PRODUCT_VARIANT_GROUP_UPDATE,
      { id, name, variationName },
      graphql(editProductVariantGroup, { id, name, variationName }),
      'editProductVariantGroup'
    ),
  deleteProductVariantGroup: id =>
    generateAsyncAction(
      PRODUCT_VARIANT_GROUP_DELETE,
      { id },
      graphql(deleteProductVariantGroup, { id }),
      'deleteProductVariantGroup'
    ),

  // GROUP PRODUCT ACTIONS
  addProductVariantGroupProduct: (variantGroupId, productId, variantName) =>
    generateAsyncAction(
      PRODUCT_VARIANT_GROUP_PRODUCT_UPDATE,
      { variantGroupId, productId, variantName },
      graphql(addProductVariantGroupProduct, {
        variantGroupId,
        productId,
        variantName,
      }),
      'addProductVariantGroupProduct'
    ),
  editProductVariantGroupProduct: (id, productId, variantName) =>
    generateAsyncAction(
      PRODUCT_VARIANT_GROUP_PRODUCT_UPDATE,
      { id, productId, variantName },
      graphql(editProductVariantGroupProduct, { id, productId, variantName }),
      'editProductVariantGroupProduct'
    ),
  deleteProductVariantGroupProduct: id =>
    generateAsyncAction(
      PRODUCT_VARIANT_GROUP_PRODUCT_DELETE,
      { id },
      graphql(deleteProductVariantGroupProduct, { id }),
      'deleteProductVariantGroupProduct'
    ),
};
