import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { LoadingButton } from 'components/shared';

/**
 * An easily expandable dialog component that can be used as a base for other dialogs.
 * Contains the actions, header, and subheader
 */
export const BaseDialogDisplayComponent = ({
  header,
  children,
  cancel,
  subheader = '',
  headerProps = {},
  contentProps = {},
  hideTopCloseButton = false,
  primaryAction: propPrimaryAction = null,
  secondaryAction = {
    label: 'Cancel',
    action: cancel,
  },
}) => {
  const [loading, setLoading] = useState(false);
  const primaryAction = useMemo(() => propPrimaryAction, [propPrimaryAction]);

  const handlePrimaryAction = (...values) => {
    setLoading(true);
    const res = primaryAction.action(...values);

    res?.then(() => setLoading(false)) ?? setLoading(false);
  };

  return (
    <>
      <CardHeader
        title={header}
        subheader={subheader}
        subheaderTypographyProps={{ variant: 'subtitle2' }}
        sx={{ px: 3, pt: 3, pb: 0 }}
        {...headerProps}
        action={
          !hideTopCloseButton && (
            <IconButton onClick={cancel}>
              <Close />
            </IconButton>
          )
        }
      />
      <If condition={children}>
        <CardContent sx={{ px: 3, py: 1 }} {...contentProps}>
          {children}
        </CardContent>
      </If>

      <If condition={primaryAction || secondaryAction}>
        <CardActions
          sx={{ display: 'flex', justifyContent: 'flex-end', pb: 3, px: 3 }}
        >
          {secondaryAction && (
            <Button
              onClick={secondaryAction.action}
              {...(secondaryAction?.props ?? {})}
            >
              {secondaryAction.label}
            </Button>
          )}
          {primaryAction && (
            <LoadingButton
              onClick={handlePrimaryAction}
              variant="contained"
              loading={loading}
              disabled={primaryAction?.disabled ?? false}
            >
              {primaryAction.label}
            </LoadingButton>
          )}
        </CardActions>
      </If>
    </>
  );
};

BaseDialogDisplayComponent.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  subheader: PropTypes.string,
  headerProps: PropTypes.object,
  contentProps: PropTypes.object,
  hideTopCloseButton: PropTypes.bool,
  cancel: PropTypes.func,
  primaryAction: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }),
  secondaryAction: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }),
};
