import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import { SideBarAwareThemeProvider } from 'providers';
import React from 'react';
import PropTypes from 'prop-types';
import Background from './images/svg/LogoSquare/PompLogoTaraweraSpacing.svg';

/**
 * Unfortunately it's impossible to use a functional component here.
 * https://reactjs.org/docs/error-boundaries.html
 *
 */
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      showDetails: false,
      errorId: null,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    this.setState({ error, errorId: this.props.sentry.lastEventId() });
  }

  render() {
    const { hasError, error, showDetails, errorId } = this.state;
    if (!hasError) {
      return this.props.children;
    }

    return (
      // Might as well add our theming back in.
      <SideBarAwareThemeProvider>
        <Box
          sx={{
            backgroundImage: `url(${Background})`,
            height: 'var(--app-height)',
          }}
        >
          <Backdrop open={true} sx={{ px: 1 }}>
            <Card
              sx={{
                maxWidth: 900,
                mx: 'auto',
                my: 2,
                overflowY: 'scroll',
                maxHeight: '90vh',
              }}
            >
              <CardHeader
                sx={{ m: 2 }}
                title="Well this is awkward..."
                titleTypographyProps={{
                  fontStyle: 'italic',
                  fontFamily: 'century-old-style-std',
                }}
              />
              <CardContent sx={{ px: 2, py: 0 }}>
                <Typography variant="body1" sx={{ m: 2, lineHeight: 2 }}>
                  Unfortunately something has gone wrong and caused the
                  application to crash. Don&apos;t worry we&apos;ve been
                  notified and will be looking into it. You can refresh or go
                  back to the previous page.
                </Typography>

                <Alert severity="info" icon={false} sx={{ mx: 2 }}>
                  Error Id: {errorId}
                </Alert>

                <If condition={!showDetails}>
                  <Button
                    variant="text"
                    sx={{ m: 2 }}
                    onClick={() => this.setState({ showDetails: true })}
                  >
                    Click here to see the full error
                  </Button>
                </If>
                <If condition={showDetails}>
                  <Alert
                    severity="error"
                    icon={false}
                    sx={{
                      m: 2,
                      px: 2,
                      maxHeight: 300,
                      overflow: 'scroll',
                      display: 'flex',
                    }}
                  >
                    <pre>{error?.stack}</pre>
                  </Alert>
                </If>
              </CardContent>
              <Divider />
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button
                  sx={{ m: 2 }}
                  variant="outlined"
                  color="primary"
                  onClick={() => history.back()}
                >
                  Go Back
                </Button>
                <Button
                  sx={{ m: 2 }}
                  variant="contained"
                  color="primary"
                  onClick={() => location.reload()}
                >
                  Refresh Page
                </Button>
              </CardActions>
            </Card>
          </Backdrop>
        </Box>
      </SideBarAwareThemeProvider>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  sentry: PropTypes.object.isRequired,
};
