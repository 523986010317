import * as actionTypes from 'constants/actionTypes';
import sendSMS from 'graphQL/mutations/sendSMS';
import graphql from 'lib/api/graphql';
import { generateAsyncAction } from 'lib/action-generators';

export default {
  sendSMS: ({
    conversationId,
    messageIndex,
    totalMessageCount,
    user,
    sms,
  }) => ({
    type: actionTypes.SMS__SEND,
    payload: graphql(sendSMS, {
      conversationId,
      messageIndex,
      totalMessageCount,
      user,
      sms,
    }),
  }),

  setMessage: message =>
    generateAsyncAction(
      actionTypes.SET__MESSAGE,
      { message },
      (async function () {
        return message;
      })()
    ),

  removeMessage: (message = null) =>
    generateAsyncAction(
      actionTypes.REMOVE__MESSAGE,
      { message },
      (async function () {
        return { message };
      })()
    ),
};
