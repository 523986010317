import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const NotificationSearch = lazy(() =>
  import(
    /* webpackChunkName: "NotificationSearch" */ 'components/pages/admin/NotificationSearch'
  )
);

export const NotificationRoutes = [
  {
    component: NotificationSearch,
    isExact: false,
    key: 'notifications',
    path: '/notifications',
    flag: FEATURE_FLAGS.MANAGE_NOTIFICATIONS,
  },
];
