import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const Cart = lazy(() =>
  import(/* webpackChunkName: "Cart" */ 'components/pages/Cart')
);

export const CartRoutes = [
  {
    component: Cart,
    isExact: true,
    key: 'cart',
    path: '/cart',
    flag: FEATURE_FLAGS.CART,
  },
];
