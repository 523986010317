import * as addressPropTypes from './addresses';
import * as authUserPropTypes from './authUser';
import * as cartPropTypes from './cart';
import * as orderPropTypes from './orders';
import * as paymentMethodPropTypes from './paymentMethods';
import * as productPropTypes from './products';
import * as productCategoryPropTypes from './productCategories';
import * as productSearchPropTypes from './productSearch';
import * as promoCodesPropTypes from './promoCodes';
import * as reactRouterPropTypes from './reactRouter';
import * as surveyPropTypes from './survey';
import * as twilioPropTypes from './twilio';
import * as checkoutPropTypes from './checkout';
import * as userPropTypes from './users';
import * as vendorPropTypes from './vendors';
import * as creditPropTypes from './credits';
import * as loader from './loader';
import * as loadProductsPropTypes from './loadProducts';
import PropTypes from 'prop-types';

export default {
  actions: PropTypes.objectOf(PropTypes.func),

  ...addressPropTypes,
  ...orderPropTypes,
  ...authUserPropTypes,
  ...cartPropTypes,
  ...paymentMethodPropTypes,
  ...productPropTypes,
  ...productCategoryPropTypes,
  ...productSearchPropTypes,
  ...promoCodesPropTypes,
  ...reactRouterPropTypes,
  ...surveyPropTypes,
  ...twilioPropTypes,
  ...checkoutPropTypes,
  ...userPropTypes,
  ...vendorPropTypes,
  ...creditPropTypes,
  ...loader,
  ...loadProductsPropTypes
};
