import * as actionTypes from 'constants/actionTypes';
import authUserLogin from 'graphQL/mutations/authUserLogin';
import adminImpersonate from 'graphQL/mutations/adminImpersonate.graphql';
import authUserRegister from 'graphQL/mutations/authUserRegister';
import authUserCapture from 'graphQL/mutations/authUserCapture';
import graphql from 'lib/api/graphql';
import surveyActions from './survey';
import referrerDetails from 'graphQL/queries/referrerDetails';
import refreshTwilioToken from 'graphQL/queries/refreshTwilioToken';
import deleteUncapturedUser from 'graphQL/mutations/deleteUncapturedUser';
import surveySetCompleted from 'graphQL/mutations/surveySetCompleted';

function postAuthCallback(dispatch, getState, response) {
  if (getState().getIn(['survey', 'answers']).keySeq().size > 0) {
    dispatch(surveyActions.save());

    // Override the server-side redirect for this request
    if (response.value.data.register && response.value.data.register.status) {
      response.value.data.register.redirect = '/welcome';
    }
  }

  return response;
}

export default {
  login:
    ({ email, password }) =>
    (dispatch, getState) =>
      dispatch({
        type: actionTypes.AUTH_USER__LOGIN,
        payload: graphql(authUserLogin, {
          user: {
            email,
            password,
          },
        }),
      }).then(postAuthCallback.bind(null, dispatch, getState)),

  impersonate: userId => (dispatch, getState) =>
    dispatch({
      type: actionTypes.AUTH_USER__LOGIN,
      payload: graphql(adminImpersonate, {
        userId,
      }),
    }).then(postAuthCallback.bind(null, dispatch, getState)),

  register:
    ({
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
      referrerId,
      businessId,
      isEsthetician,
      id,
      fromSurvey,
      userMetadata,
      signedEsthieAgreementId,
      findAnEstieId,
    }) =>
    (dispatch, getState) =>
      dispatch({
        type: actionTypes.AUTH_USER__REGISTER,
        payload: graphql(authUserRegister, {
          user: {
            email,
            firstName,
            lastName,
            password,
            phoneNumber,
            referrerId,
            businessId,
            isEsthetician,
            id,
            fromSurvey,
            userMetadata,
            signedEsthieAgreementId,
            findAnEstieId,
          },
        }),
      }).then(postAuthCallback.bind(null, dispatch, getState)),

  logout: () => dispatch => dispatch({ type: actionTypes.AUTH_USER__LOGOUT }),

  refreshTwilioToken:
    (id = null) =>
    dispatch =>
      dispatch({
        type: actionTypes.AUTH_USER__REFRESH_TWILIO_TOKEN,
        payload: graphql(refreshTwilioToken, { user: id }),
      }),

  getReferrals: () => dispatch =>
    dispatch({
      type: actionTypes.AUTH_USER__GET_REFERRALS,
      payload: graphql(referrerDetails),
    }),

  userCapture:
    ({ email, firstName, lastName, id }) =>
    async dispatch => {
      const payload = await graphql(authUserCapture, {
        user: {
          email,
          firstName,
          lastName,
          id,
        },
      });

      return dispatch({
        type: actionTypes.AUTH_USER__CAPTURE_FULLFILLED,
        payload,
      });
    },

  deleteUncaptureUser: id => async dispatch => {
    const payload = await graphql(deleteUncapturedUser, { user: id });

    return dispatch({
      type: actionTypes.AUTH_USER__DELETE_UNCAPTURED_USER,
      payload,
    });
  },

  /**
   * @param {string} userId - The id of the user to update
   */
  setSurveyCompleted: userId => async dispatch => {
    const payload = await graphql(surveySetCompleted, { user: userId });
    return dispatch({
      type: actionTypes.AUTH_USER__SET_SURVEY_COMPLETED,
      payload,
    });
  },
};
