export const INTEGRATIONS__BULK_UPDATE = 'INTEGRATIONS__BULK_UPDATE';

export const INTEGRATION_ACCOUNTS__BULK_UPDATE =
  'INTEGRATION_ACCOUNTS__BULK_UPDATE';
export const INTEGRATION_ACCOUNTS__SINGLE_UPDATE =
  'INTEGRATION_ACCOUNTS__SINGLE_UPDATE';
export const INTEGRATION_ACCOUNTS__DELETE = 'INTEGRATION_ACCOUNTS__DELETE';

export const INTENTION_ACCOUNT_SECRET_KEY__UPDATE =
  'INTENTION_ACCOUNT_SECRET_KEY__UPDATE';
