import * as actionTypes from 'constants/actionTypes';
import graphql from 'lib/api/graphql';
import paymentMethodCreate from 'graphQL/mutations/paymentMethodCreate';
import paymentMethodDelete from 'graphQL/mutations/paymentMethodDelete';

export default {
  create: token => ({
    type: actionTypes.PAYMENT_METHOD__CREATE,
    payload: graphql(paymentMethodCreate, { token })
  }),

  delete: id => ({
    type: actionTypes.PAYMENT_METHOD__DELETE,
    payload: graphql(paymentMethodDelete, { paymentMethod: id })
  })
};
