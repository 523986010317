import {
  NOTIFICATIONS__LOAD_ALL,
  NOTIFICATION__SEARCH,
  NOTIFICATION__SAVE,
  NOTIFICATION__DELETE,
} from './action-types';
import graphql from 'lib/api/graphql';
import notificationLoadAll from '../graphql/queries/notification-load-all.graphql';
import notificationSave from '../graphql/mutations/notification-save.graphql';
import notificationSearch from '../graphql/queries/notification-search.graphql';
import notificationDelete from '../graphql/mutations/notification-delete.graphql';

export default {
  loadAll: () => dispatch =>
    dispatch({
      type: NOTIFICATIONS__LOAD_ALL,
      payload: graphql(notificationLoadAll),
    }),

  save: notification => dispatch =>
    dispatch({
      type: NOTIFICATION__SAVE,
      payload: graphql(notificationSave, { notification }),
    }),

  search: searchQuery => dispatch =>
    dispatch({
      type: NOTIFICATION__SEARCH,
      payload: graphql(notificationSearch, searchQuery),
    }),

  delete: notificationId => ({
    type: NOTIFICATION__DELETE,
    payload: graphql(notificationDelete, { notificationId }),
  }),
};
