import { lazy } from 'react';
import { FEATURE_FLAGS } from '@pomp-libs/core';

const OnboardingPage = lazy(() =>
  import(/* webpackChunkName: "RoutineView" */ '../components').then(
    ({ OnboardingPage }) => ({
      default: OnboardingPage,
    })
  )
);

export const OnboardingRoutes = [
  {
    component: OnboardingPage,
    key: 'onboarding',
    path: '/onboarding',
    flag: FEATURE_FLAGS.ONBOARDING,
    isAuthed: true,
  },
];
