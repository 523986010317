import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const DashboardPage = lazy(
  /* webpackChunkName: "DashboardPage" */ () =>
    import('features/dashboards/dashboard').then(({ DashboardPage }) => ({
      default: DashboardPage,
    }))
);

export const DashboardRoutes = [
  {
    component: DashboardPage,
    isExact: false,
    key: 'dashboard',
    path: '/dashboard',
    flag: FEATURE_FLAGS.DASHBOARDS,
  },
];
