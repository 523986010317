export const PROMO_CODES__LOAD_ALL = 'PROMO_CODES__LOAD_ALL';
export const PROMO_CODES__LOAD_ALL_FULFILLED = `${PROMO_CODES__LOAD_ALL}_FULFILLED`;
export const PROMO_CODES__LOAD_ALL_PENDING = `${PROMO_CODES__LOAD_ALL}_PENDING`;

export const PROMO_CODES__SAVE = 'PROMO_CODES__SAVE';
export const PROMO_CODES__SAVE_FULFILLED = `${PROMO_CODES__SAVE}_FULFILLED`;
export const PROMO_CODES__SAVE_PENDING = `${PROMO_CODES__SAVE}_PENDING`;

export const PROMO_CODES__DELETE = 'PROMO_CODES__DELETE';

export const PROMO__SEARCH = 'PROMO__SEARCH';
