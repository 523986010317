import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const Order = lazy(() =>
  import(/* webpackChunkName: "Order" */ 'components/pages/admin/Order')
);
const Orders = lazy(() =>
  import(/* webpackChunkName: "Orders" */ 'components/pages/admin/Orders')
);
const OrderConfirmation = lazy(() =>
  import(
    /* webpackChunkName: "OrderConfirmation" */ 'components/pages/Confirmation'
  )
);

export const OrdersRoutes = [
  {
    component: Order,
    isExact: true,
    key: 'orders-detail',
    path: '/order/:orderNumber',
    flag: FEATURE_FLAGS.ORDER_SEARCH,
  },
  {
    component: Orders,
    isExact: true,
    key: 'orders',
    path: '/orders',
    flag: FEATURE_FLAGS.ORDER_SEARCH,
  },
  {
    component: OrderConfirmation,
    isExact: true,
    key: 'order-confirmation',
    path: '/orders/:orderNumber/confirmation',
  },
];
