import * as actionTypes from 'constants/actionTypes';

import createReducer from 'reducers/createReducer';
import Immutable from 'immutable';
import initialState from './initialState';
import {
  VENDORS__DELETE_SINGLE_USER_VENDOR_RULE,
  VENDORS__UPDATE_MULTIPLE_USER_VENDOR_RULES,
  VENDORS__UPDATE_SINGLE_USER_VENDOR_RULE,
} from '../actions/rules.constants';

// TODO: Convert from Immutable
export default createReducer(initialState, {
  [actionTypes.VENDORS__LOAD_ALL_PENDING]: state =>
    state.set('areLoading', true),

  [actionTypes.VENDORS__LOAD_ALL_FULFILLED]: (state, { payload: { data } }) =>
    state.merge({
      all: Immutable.fromJS(data.vendorSearch.data),
      areLoading: false,
    }),

  [actionTypes.VENDORS__SAVE_FULFILLED]: (state, { payload: { data } }) => {
    const vendorIndex = state
      .get('all')
      .findIndex(v => v.get('id') === data.vendor.id);

    if (vendorIndex === -1) {
      return state.updateIn(['all'], list =>
        list.push(Immutable.fromJS(data.vendor))
      );
    }

    return state.setIn(['all', vendorIndex], Immutable.fromJS(data.vendor));
  },

  [VENDORS__UPDATE_SINGLE_USER_VENDOR_RULE]: (state, { payload, status }) => {
    if (status !== 'success') return state;

    const userId = payload?.userId;
    const userVendorRulesByUserId = state.toJS().userVendorRulesByUserId;

    userVendorRulesByUserId[userId].push(payload);

    return state.set(
      'userVendorRulesByUserId',
      Immutable.fromJS(userVendorRulesByUserId)
    );
  },

  [VENDORS__UPDATE_MULTIPLE_USER_VENDOR_RULES]: (
    state,
    { payload, requestPayload, status }
  ) => {
    if (status !== 'success') return state;

    const userId = requestPayload?.userId;

    return state.setIn(
      ['userVendorRulesByUserId', userId],
      Immutable.fromJS(payload)
    );
  },

  [VENDORS__DELETE_SINGLE_USER_VENDOR_RULE]: (
    state,
    { requestPayload, status }
  ) => {
    if (status !== 'success') return state;

    const { id } = requestPayload;
    const userVendorRulesByUserId = state.toJS().userVendorRulesByUserId;

    const userId = Object.keys(userVendorRulesByUserId).find(userId =>
      userVendorRulesByUserId[userId].some(rule => rule.id === id)
    );

    if (!userId) return state;

    const updated = userVendorRulesByUserId[userId].filter(
      rule => rule.id !== id
    );

    return state.setIn(
      ['userVendorRulesByUserId', userId],
      Immutable.fromJS(updated)
    );
  },

  [actionTypes.APP__START_FULFILLED]: (state, { payload }) => {
    if (!payload?.data?.restrictedVendors) return state;

    return state.set(
      'restrictedVendors',
      Immutable.fromJS(payload.data.restrictedVendors)
    );
  },
});
