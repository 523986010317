import * as actionTypes from 'constants/actionTypes';
import createReducer from 'reducers/createReducer';
import Immutable from 'immutable';
import initialState from './initialState';

export default createReducer(initialState, {
  [actionTypes.PRODUCT_CATEGORIES__LOAD_ALL_PENDING]: state =>
    state.set('areLoading', true),

  [actionTypes.PRODUCT_CATEGORIES__LOAD_ALL_FULFILLED]: (
    state,
    { payload: { data } }
  ) => {
    if (!data) return state;
    const stateJs = [...state.toJS().all];

    // Only add the category in if it doesn't exist yet in the state
    const updated = data?.productCategorySearch?.data?.reduce((acc, obj) => {
      const exists = acc.some(accObj => accObj.id === obj.id);

      if (!exists) {
        acc.push(obj);
      }

      return acc;
    }, stateJs);

    return state.merge({
      all: Immutable.fromJS(updated),
      areLoading: false,
    });
  },
  [actionTypes.PRODUCT_CATEGORY__SAVE_FULFILLED]: (
    state,
    { payload: { data } }
  ) => {
    const productCategoryIndex = state
      .get('all')
      .findIndex(v => v?.get('id') === data?.productCategory?.id);

    if (productCategoryIndex === -1) {
      return state.updateIn(['all'], list =>
        list.push(Immutable.fromJS(data.productCategory))
      );
    }

    return state.setIn(
      ['all', productCategoryIndex],
      Immutable.fromJS(data.productCategory)
    );
  },
  [actionTypes.PRODUCT_CATEGORY__DELETE]: (state, { payload, status }) => {
    if (status === 'request') return state;

    const productCategoryIndex = state
      .get('all')
      .findIndex(v => v?.get('id') === payload);

    if (productCategoryIndex !== -1) {
      return state.updateIn(['all'], list => list.delete(productCategoryIndex));
    }

    return state;
  },
});
