import { debounce } from 'lodash';
import { asyncActionStatuses } from './constants';

/**
 * Generate an async action
 * @param type The action type
 * @param requestPayload The request payload
 * @param apiCall The api call to make for the async action
 * @param functionName The function / object name to extract from the response
 */
export const generateAsyncAction = (
  type,
  requestPayload,
  apiCall,
  functionName = null
) => {
  const asyncActionCreator = async dispatch => {
    dispatch({
      type,
      status: asyncActionStatuses.REQUEST,
      payload: requestPayload,
      requestPayload,
    });
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      try {
        const res = await apiCall;
        if (res.errors) {
          // eslint-disable-next-line no-console
          console.error(res.errors);
          throw new Error(res?.errors?.[0]?.message);
        }

        dispatch({
          type,
          status: asyncActionStatuses.SUCCESS,
          payload: functionName ? res?.data?.[functionName] : res,
          requestPayload,
        });

        // Automatically extract the data from the response
        resolve(functionName ? res?.data?.[functionName] : res);
      } catch (error) {
        dispatch({
          type,
          status: asyncActionStatuses.FAIL,
          payload: error?.message ?? error,
          requestPayload,
        });
        // We resolve here instead of reject, as otherwise the rejection gets
        // lost inside the middleware and we can't handle it properly
        resolve({ error, isError: true });
      }
    });
  };

  // Prevent the event from firing consecutive times
  return debounce(asyncActionCreator, 500, { leading: true });
};
