import { generateAsyncAction } from 'lib/action-generators';
import listRoutinesForUser from '../graphql/queries/list';
import getRoutine from '../graphql/queries/get';
import {
  ROUTINE_DELETE,
  ROUTINE_STEP_DELETE,
  ROUTINE_STEP_UPDATE,
  ROUTINE_UPDATE,
  ROUTINE_UPDATE_BULK,
} from './action-types';
import graphql from 'lib/api/graphql';
import editRoutine from '../graphql/mutations/edit';
import updateRoutineStatus from '../graphql/mutations/update-status';
import addRoutine from '../graphql/mutations/add';
import deleteRoutine from '../graphql/mutations/delete';
import duplicateRoutine from '../graphql/mutations/duplicate';
import editRoutineStep from '../graphql/mutations/steps/edit';
import deleteRoutineStep from '../graphql/mutations/steps/delete';
import addRoutineStep from '../graphql/mutations/steps/add';
import reorderRoutineSteps from '../graphql/mutations/steps/reorder';

export const RoutineActions = {
  // Routine Actions
  listRoutinesForUser: (userId, statuses, isTemplate = false) =>
    generateAsyncAction(
      ROUTINE_UPDATE_BULK,
      { userId, statuses, isTemplate },
      graphql(listRoutinesForUser, { userId, statuses, isTemplate }),
      'listRoutinesForUser'
    ),
  getRoutine: id =>
    generateAsyncAction(
      ROUTINE_UPDATE,
      { id },
      graphql(getRoutine, { id }),
      'getRoutine'
    ),
  editRoutine: (id, routine) =>
    generateAsyncAction(
      ROUTINE_UPDATE,
      { id, routine },
      graphql(editRoutine, { id, routine }),
      'editRoutine'
    ),
  addRoutine: routine =>
    generateAsyncAction(
      ROUTINE_UPDATE,
      { routine },
      graphql(addRoutine, { routine }),
      'addRoutine'
    ),
  updateStatus: (id, status) =>
    generateAsyncAction(
      ROUTINE_UPDATE,
      { id, status },
      graphql(updateRoutineStatus, { id, status }),
      'updateRoutineStatus'
    ),
  deleteRoutine: id =>
    generateAsyncAction(
      ROUTINE_DELETE,
      { id },
      graphql(deleteRoutine, { id }),
      'deleteRoutine'
    ),
  duplicateRoutine: (id, clientId, isTemplate = false) =>
    generateAsyncAction(
      ROUTINE_UPDATE,
      { id, clientId, isTemplate },
      graphql(duplicateRoutine, { id, clientId, isTemplate }),
      'duplicateRoutine'
    ),

  // Routine Step Actions
  addRoutineStep: step =>
    generateAsyncAction(
      ROUTINE_STEP_UPDATE,
      { step },
      graphql(addRoutineStep, { step }),
      'addRoutineStep'
    ),
  editRoutineStep: (id, step) =>
    generateAsyncAction(
      ROUTINE_STEP_UPDATE,
      { id, step },
      graphql(editRoutineStep, { id, step }),
      'editRoutineStep'
    ),
  deleteRoutineStep: id =>
    generateAsyncAction(
      ROUTINE_STEP_DELETE,
      { id },
      graphql(deleteRoutineStep, { id }),
      'deleteRoutineStep'
    ),
  reorderRoutineSteps: (id, steps) =>
    generateAsyncAction(
      ROUTINE_UPDATE,
      { id, steps },
      graphql(reorderRoutineSteps, { id, steps }),
      'reorderRoutineSteps'
    ),
};
