export const USERS__CHANGE_ROLE = 'USERS__CHANGE_ROLE';
export const USERS__CHANGE_ROLE_FULFILLED = `${USERS__CHANGE_ROLE}_FULFILLED`;
export const USERS__CHANGE_ROLE_PENDING = `${USERS__CHANGE_ROLE}_PENDING`;

export const USERS__SET_NEEDS_CONTACT = 'USERS__SET_NEEDS_CONTACT';
export const USERS__SET_NEEDS_CONTACT_FULFILLED = `${USERS__SET_NEEDS_CONTACT}_FULFILLED`;
export const USERS__SET_NEEDS_CONTACT_PENDING = `${USERS__SET_NEEDS_CONTACT}_PENDING`;

export const USERS__LOAD_NEEDS_CONTACT = 'USERS__LOAD_NEEDS_CONTACT';
export const USERS__LOAD_NEEDS_CONTACT_FULFILLED = `${USERS__LOAD_NEEDS_CONTACT}_FULFILLED`;
export const USERS__LOAD_NEEDS_CONTACT_PENDING = `${USERS__LOAD_NEEDS_CONTACT}_PENDING`;

export const USERS__LOAD_ONE = 'USERS__LOAD_ONE';
export const USERS__LOAD_ONE_FULFILLED = `${USERS__LOAD_ONE}_FULFILLED`;
export const USERS__LOAD_ONE_PENDING = `${USERS__LOAD_ONE}_PENDING`;

export const USERS_BASIC__LOAD_ONE = 'USERS_BASIC__LOAD_ONE';

export const USERS__LOAD_ALL = 'USERS__LOAD_ALL';
export const USERS__LOAD_ALL_FULFILLED = `${USERS__LOAD_ALL}_FULFILLED`;
export const USERS__LOAD_ALL_PENDING = `${USERS__LOAD_ALL}_PENDING`;

export const USERS__LOAD_SURVEY_USER = `USERS__LOAD_SURVEY_USER`;

export const USERS__PASSWORD_RESET = 'USERS__PASSWORD_RESET';
export const USERS__PASSWORD_RESET_FULFILLED = `${USERS__PASSWORD_RESET}_FULFILLED`;
export const USERS__PASSWORD_RESET_PENDING = `${USERS__PASSWORD_RESET}_PENDING`;

export const USERS__SEARCH = 'USERS__SEARCH';
export const USERS__SEARCH_FULFILLED = `${USERS__SEARCH}_FULFILLED`;
export const USERS__SEARCH_PENDING = `${USERS__SEARCH}_PENDING`;

export const USERS__SEND_RESET_PASSWORD = 'USERS__SEND_RESET_PASSWORD';
export const USERS__SEND_RESET_PASSWORD_FULFILLED = `${USERS__SEND_RESET_PASSWORD}_FULFILLED`;
export const USERS__SEND_RESET_PASSWORD_PENDING = `${USERS__SEND_RESET_PASSWORD}_PENDING`;

export const USERS__VALIDATE_EMAIL = 'USERS__VALIDATE_EMAIL';
export const USERS__VALIDATE_EMAIL_FULFILLED = `${USERS__VALIDATE_EMAIL}_FULFILLED`;
export const USERS__VALIDATE_EMAIL_PENDING = `${USERS__VALIDATE_EMAIL}_PENDING`;

export const USERS__ADMIN_EDIT_PROFILE = 'USERS__ADMIN_EDIT_PROFILE';
export const USERS__ADMIN_UPDATE_PASSWORD = 'USERS__ADMIN_UPDATE_PASSWORD';
export const USERS__UPDATE_PROFILE = 'USERS__UPDATE_PROFILE';
export const USERS__UPDATE_GIFTCARD_BALANCE = 'USERS__UPDATE_GIFTCARD_BALANCE';

export const USERS__SELECT = 'USERS__SELECT';

export const USERS__LOAD_ESTHETICIANS = 'USERS__LOAD_ESTHETICIANS';
export const USERS__LOAD_ESTHETICIANS_FULFILLED = `${USERS__LOAD_ESTHETICIANS}_FULFILLED`;
export const USERS__LOAD_ESTHETICIANS_PENDING = `${USERS__LOAD_ESTHETICIANS}_PENDING`;

export const USERS__UPDATE_ESTHETICIAN = 'USERS__UPDATE_ESTHETICIAN';
export const USERS__UPDATE_ESTHETICIAN_FULFILLED = `${USERS__UPDATE_ESTHETICIAN}_FULFILLED`;
export const USERS__UPDATE_ESTHETICIAN_PENDING = `${USERS__UPDATE_ESTHETICIAN}_PENDING`;

export const USERS__ESTHETICIAN_UPSERT_CLIENT_SHIPPING_ADDRESS =
  'USERS__ESTHETICIAN_UPSERT_CLIENT_SHIPPING_ADDRESS';
export const USERS__ESTHETICIAN_UPSERT_CLIENT_SHIPPING_ADDRESS_FULFILLED = `${USERS__ESTHETICIAN_UPSERT_CLIENT_SHIPPING_ADDRESS}_FULFILLED`;
export const USERS__ESTHETICIAN_UPSERT_CLIENT_SHIPPING_ADDRESS_PENDING = `${USERS__ESTHETICIAN_UPSERT_CLIENT_SHIPPING_ADDRESS}_PENDING`;

export const USERS__CHANGE_TIER = 'USERS__CHANGE_TIER';

export const USERS__SUBMIT_LICENSE = 'USERS__SUBMIT_LICENSE';
export const USERS__GET_SETUP_CLIENT_SECRET = 'USERS__GET_SETUP_CLIENT_SECRET';
export const USERS__UPDATE_LICENSE_STATUS = 'USERS__UPDATE_LICENSE_STATUS';
//Subscription Credits
export const USERS__ADJUST_CREDIT = 'USERS__ADJUST_CREDIT';
export const USERS__SUBSCRIPTION_CREDIT = 'USERS__SUBSCRIPTION_CREDIT';

export const USERS__SUBMIT_ONBOARDING_DETAILS =
  'USERS__SUBMIT_ONBOARDING_DETAILS';
export const USERS__SEARCH_SELECTOR = 'USERS__SEARCH_SELECTOR';
export const USERS__ADD_METADATA = 'USERS__ADD_METADATA';
