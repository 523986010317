import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useStyles } from './useStyles';
import clsx from 'clsx';
import { getIcon } from './stylesLibrary';
import CircularProgress from '@mui/material/CircularProgress';

const InitialButton = ({
  name,
  nameAfter = '',
  sx,
  disabled = false,
  disableAfter = false,
  capitalize = true,
  onClick = null,
  clicked = false,
  path,
  variant = 'contained',
  color = 'primary',
  size = 'large',
  iconLeft = false,
  iconRight = false,
  dataTestId,
  type = 'button',
  target = '_self',
  fullWidth = false,
  loading = false,
  href,
  rel = href && target === '_blank' ? 'noopener noreferrer' : '',
  state,
  // TODO: Remove All Buttons being width 100% on smaller screens without refactoring every single instance of this class
  fixedWidth = false,
  icon = null,
  children,
}) => {
  const [currentDisabled, setCurrentDisabled] = useState(disabled);
  const [currentClicked, setCurrentClicked] = useState(clicked);

  const classes = useStyles({
    color,
    size,
    variant,
    capitalize,
    fullWidth,
    disabled,
    loading,
    fixedWidth,
  })();

  // TODO: Update this function to instead of using the button name, use the icon so we can have prenamed icons. OR supply a custom icon.
  const getNameIcon = () => icon ?? getIcon(name, currentClicked);

  useEffect(() => {
    setCurrentClicked(clicked);
  }, [clicked]);

  useEffect(() => {
    setCurrentDisabled(disabled);
  }, [disabled]);

  const computedRel = useMemo(() => {
    if (target !== '_blank') return rel;
    // Ensure the rel attribute includes `noopener` and `noreferrer`
    const relAttributeValues = rel.split(' ');
    let crossDomainSafeRel = rel;
    if (!relAttributeValues.some(value => value === 'noopener'))
      crossDomainSafeRel += ' noopener';
    if (!relAttributeValues.some(value => value === 'noreferrer'))
      crossDomainSafeRel += ' noreferrer';

    return crossDomainSafeRel;
  }, [rel, target]);

  const handleClick = e => {
    if (disableAfter) setCurrentDisabled(true);
    if (typeof onClick === 'function') onClick(e);
    setCurrentClicked(!currentClicked);
  };

  const getName = () => {
    if (currentClicked || currentDisabled) return nameAfter || name;
    return name;
  };

  const currentName = getName();

  return (
    <Button
      className={clsx(classes.Button)}
      data-testid={dataTestId}
      type={type}
      component={path ? Link : Button}
      sx={sx}
      color={color}
      variant={variant}
      disabled={currentDisabled || loading}
      startIcon={iconLeft ? getNameIcon() : null}
      endIcon={iconRight ? getNameIcon() : null}
      onClick={handleClick}
      to={path}
      href={href}
      fullWidth={fullWidth}
      rel={computedRel}
      target={target}
      state={state}
      size={size}
    >
      <Choose>
        <When condition={loading}>
          <CircularProgress color="secondary" size={24} />
        </When>

        <Otherwise>
          <Choose>
            <When condition={!!children}>{children}</When>
            <Otherwise>{currentName}</Otherwise>
          </Choose>
        </Otherwise>
      </Choose>
    </Button>
  );
};

InitialButton.propTypes = {
  name: PropTypes.string,
  sx: PropTypes.object,
  disabled: PropTypes.bool,
  disableAfter: PropTypes.bool,
  capitalize: PropTypes.bool,
  onClick: PropTypes.func,
  clicked: PropTypes.bool,
  path: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  iconLeft: PropTypes.bool,
  iconRight: PropTypes.bool,
  nameAfter: PropTypes.string,
  dataTestId: PropTypes.string,
  type: PropTypes.string,
  target: PropTypes.string,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  href: PropTypes.string,
  rel: PropTypes.string,
  state: PropTypes.object,
  fixedWidth: PropTypes.bool,
  icon: PropTypes.any, // Element or Functional component
  children: PropTypes.node,
};
export default InitialButton;
