import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import NotificationCard from 'components/shared/Notifications/NotificationCard';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const NotificationCarousel = ({ notifications, cancel }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = notifications?.size;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {notifications.map((notification, index) => (
          <Box key={notification.get('id')}>
            <Choose>
              <When condition={Math.abs(activeStep - index) <= 2}>
                <NotificationCard
                  cancel={cancel}
                  key={notification.get('description')}
                  notification={notification}
                />
              </When>
              <Otherwise>{null}</Otherwise>
            </Choose>
          </Box>
        ))}
      </SwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            <Choose>
              <When condition={theme.direction === 'rtl'}>
                <KeyboardArrowLeft />
              </When>
              <Otherwise>
                <KeyboardArrowRight />
              </Otherwise>
            </Choose>
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <Choose>
              <When condition={theme.direction === 'rtl'}>
                <KeyboardArrowRight />
              </When>
              <Otherwise>
                <KeyboardArrowLeft />
              </Otherwise>
            </Choose>
            Back
          </Button>
        }
      />
    </Box>
  );
};

NotificationCarousel.propTypes = {
  notifications: PropTypes.object,
  cancel: PropTypes.func,
};

export default NotificationCarousel;
