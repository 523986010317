import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const address = ImmutablePropTypes.contains({
  city: PropTypes.string,
  id: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  name: PropTypes.string,
  phone: PropTypes.string,
  state: PropTypes.string,
  street1: PropTypes.string,
  street2: PropTypes.string,
  zip: PropTypes.string
});

export const addresses = ImmutablePropTypes.listOf(address);
