import React from 'react';
import proptypes from 'prop-types';
import { Box } from '@mui/material';

const LogoSquare = ({ color = 'primary.light' }) => {
  return (
    <Box sx={{ color }}>
      <svg
        width="31"
        height="21"
        viewBox="23 25 35 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M46.3732 25.6943H31.6588C31.5456 25.6943 31.4371 25.7393 31.357 25.8193C31.277 25.8993 31.2321 26.0079 31.2321 26.121V34.0587C31.232 34.1723 31.2769 34.2813 31.3568 34.362C31.4366 34.4428 31.5452 34.4888 31.6588 34.49H35.5358C35.6502 34.49 35.7599 34.4445 35.8408 34.3637C35.9217 34.2828 35.9671 34.1731 35.9671 34.0587V30.2C35.9665 30.1438 35.9771 30.088 35.9983 30.036C36.0196 29.9839 36.051 29.9366 36.0907 29.8969C36.1305 29.8571 36.1778 29.8257 36.2298 29.8045C36.2819 29.7833 36.3376 29.7727 36.3938 29.7733H45.7079C49.2638 29.7733 50.9844 31.1773 50.9844 34.1137C50.9844 37.0502 49.2638 38.4909 45.7079 38.4909H40.7022C40.589 38.4909 40.4805 38.5359 40.4004 38.6159C40.3204 38.6959 40.2755 38.8045 40.2755 38.9176V42.1294C40.2749 42.1858 40.2854 42.2418 40.3066 42.2941C40.3278 42.3464 40.3591 42.394 40.3988 42.4341C40.4385 42.4742 40.4857 42.506 40.5378 42.5278C40.5899 42.5495 40.6457 42.5607 40.7022 42.5607H46.3732C52.471 42.5607 55.8342 39.5875 55.8342 34.1137C55.8342 28.64 52.471 25.6943 46.3732 25.6943Z"
          fill={'currentColor'}
        />
        <path
          d="M35.5405 38.4907H22.5925C22.4793 38.4907 22.3708 38.5357 22.2907 38.6157C22.2107 38.6957 22.1658 38.8043 22.1658 38.9174V42.7945C22.1658 42.9077 22.2107 43.0162 22.2907 43.0962C22.3708 43.1762 22.4793 43.2212 22.5925 43.2212H30.81C30.866 43.2212 30.9215 43.2322 30.9733 43.2537C31.0251 43.2751 31.0721 43.3065 31.1117 43.3462C31.1514 43.3858 31.1828 43.4328 31.2042 43.4846C31.2257 43.5364 31.2367 43.5919 31.2367 43.6479V51.87C31.2355 51.9264 31.2455 51.9825 31.2661 52.0351C31.2867 52.0876 31.3175 52.1355 31.3568 52.1761C31.3961 52.2166 31.443 52.249 31.4948 52.2712C31.5467 52.2935 31.6024 52.3053 31.6588 52.3059H35.5359C35.6495 52.3059 35.7585 52.2611 35.8393 52.1812C35.92 52.1013 35.966 51.9928 35.9672 51.8792V38.9174C35.966 38.8046 35.9207 38.6968 35.8409 38.617C35.7611 38.5373 35.6533 38.4919 35.5405 38.4907Z"
          fill={'currentColor'}
        />
      </svg>
    </Box>
  );
};

LogoSquare.propTypes = {
  color: proptypes.string
};

export default LogoSquare;
