import * as actionTypes from 'constants/actionTypes';
import createReducer from 'reducers/createReducer';
import Immutable from 'immutable';
import initialState from './initialState';
import {
  SAVE__PRODUCT_IMAGES,
  DELETE__PRODUCT_IMAGE,
  SAVE__DEFAULT_IMAGE,
} from 'features/products/actions/constants';

// TODO: Look to see if we can remove this OR at least remove a bunch of it!
export default createReducer(initialState, {
  [actionTypes.PRODUCT__SEARCH_PENDING]: state =>
    state.set('isSearching', true),

  [actionTypes.PRODUCT__SEARCH_FULFILLED]: (state, { payload: { data } }) =>
    state.merge({
      isSearching: false,
      products: Immutable.fromJS(data?.productSearch),
    }),

  [actionTypes.PRODUCT__DELETE_FULFILLED]: (state, { meta }) =>
    state.updateIn(['products'], list =>
      list.filter(product => product.id !== meta.product)
    ),

  [actionTypes.USERS__LOAD_NEEDS_CONTACT_FULFILLED]: (
    state,
    { payload: { data } }
  ) =>
    state.merge({
      needsContact: Immutable.fromJS(data.needsContact),
    }),

  [actionTypes.USERS__SET_NEEDS_CONTACT_PENDING]: (state, { meta }) => {
    const userIndex = state
      .get('needsContact')
      .findIndex(user => user.get('id') === meta.userId);
    if (userIndex < 0) return state;

    return state.updateIn(['needsContact'], list => list.delete(userIndex));
  },

  [actionTypes.USERS__LOAD_ONE_FULFILLED]: (state, { payload: { data } }) => {
    if (typeof data == 'undefined' || typeof data.user == 'undefined')
      return state;
    return state.updateIn(['users'], list =>
      list.push(Immutable.fromJS(data.user))
    );
  },

  [actionTypes.USERS__LOAD_ALL_FULFILLED]: (state, { payload: { data } }) =>
    state.setIn(['users'], Immutable.fromJS(data.users || [])),

  [actionTypes.USERS__SEARCH_PENDING]: state => state.set('isSearching', true),

  [actionTypes.USERS__SEARCH_FULFILLED]: (state, { payload: { data } }) =>
    state.merge({
      isSearching: false,
      users: Immutable.fromJS(data.userSearch),
    }),

  [actionTypes.USERS__SELECT]: (state, { payload: { user } }) =>
    state.set(
      'user',
      state.get('users').find(u => u.get('id') === user) || null
    ),

  [actionTypes.PRODUCT__ADD_FOR_USER_FULFILLED]: (
    state,
    { payload: { data } }
  ) => {
    const userId = data.cartProduct.cart.user.id;
    /**
     * A function that updates a user's cart with a new product.
     * @param {} cartProducts
     * @returns
     */
    const updateCartFunc = cartProducts => {
      if (!data || !data.cartProduct) return cartProducts;
      const existingIndex = cartProducts.find(
        p => p.get('id') === data.cartProduct.id
      );

      if (existingIndex !== undefined) {
        return cartProducts.set(
          existingIndex,
          Immutable.fromJS(data.cartProduct)
        );
      }

      return cartProducts.push(Immutable.fromJS(data.cartProduct));
    };

    // If this is added for the signed in user.
    if (userId === state.get('user').get('id')) {
      // Fun fact, this previously wasn't actually doing anything! Because we weren't saving it back to the state.
      state = state.updateIn(['user', 'cart', 'products'], updateCartFunc);
    }

    // Always update the users by id function.
    // P.S I would like to mention exactly how much immutablejs can go fuck itself.
    // I tried to use their helpers to update the list, but they didn't work.
    // Their documentation is fucking crap. Seriously look at this...
    // https://immutable-js.com/docs/latest@main/updateIn()/
    // 2 Stars
    const updatedUsers = state.get('users').map(user => {
      if (user.get('id') === userId) {
        user = user.updateIn(['cart', 'products'], updateCartFunc);
      }

      return user;
    });

    state = state.set('users', updatedUsers);

    return state;
  },

  [actionTypes.PRODUCT__REMOVE_FOR_USER_FULFILLED]: (
    state,
    { payload: { data } }
  ) =>
    state.updateIn(['user', 'cart', 'products'], list =>
      list.filter(product => product.get('id') !== data.removeCartProduct)
    ),

  [actionTypes.PRODUCT__LOAD_TO_EDIT_FULFILLED]: (
    state,
    { payload: { data } }
  ) => state.set('productToEdit', Immutable.fromJS(data.product)),

  [actionTypes.PRODUCT__CLEAR_LOAD_TO_EDIT]: (state, { payload, status }) => {
    if (status !== 'success') return state;
    return state.set('productToEdit', Immutable.fromJS(payload.productToEdit));
  },

  [actionTypes.USERS__CHANGE_ROLE_FULFILLED]: (state, { payload: { data } }) =>
    state.updateIn(['users'], users =>
      users.map(user => {
        if (user.get('id') !== data.userChangeRole.id) return user;
        return user.set('role', data.userChangeRole.role);
      })
    ),

  [actionTypes.PRODUCT__ADD_FAVORITE]: (
    state,
    { status, requestPayload: { productId } }
  ) => {
    if (status !== 'success') return state;

    const totals = state.get('totals').toJS();
    if (totals) {
      totals.favorite = totals.favorite + 1;
    }

    const products = state.get('products').toJS();

    return state
      .set(
        'products',
        Immutable.fromJS({
          ...products,
          data: products.data.map(product => {
            if (product.id === productId) {
              product.favorite = true;
            }
            return product;
          }),
        })
      )
      .set('totals', Immutable.fromJS(totals));
  },

  [actionTypes.PRODUCT__REMOVE_FAVORITE]: (
    state,
    { status, requestPayload: { productId } }
  ) => {
    if (status !== 'success') return state;

    const totals = state.get('totals').toJS();
    if (totals) {
      totals.favorite = totals.favorite - 1;
    }

    const products = state.get('products').toJS();

    return state
      .set(
        'products',
        Immutable.fromJS({
          ...products,
          data: products.data.map(product => {
            if (product.id === productId) {
              product.favorite = false;
            }
            return product;
          }),
        })
      )
      .set('totals', Immutable.fromJS(totals));
  },

  [actionTypes.PRODUCT__SET_TOTALS]: (state, { payload: { data }, status }) => {
    if (status !== 'success') return state;

    return state.set('totals', Immutable.fromJS(data.productSearchTotals));
  },

  [actionTypes.PRODUCT__SEARCH_BASIC]: (state, { payload, status }) => {
    if (status !== 'success') return state;

    return state.set('productsBasic', Immutable.fromJS(payload));
  },

  [SAVE__PRODUCT_IMAGES]: (state, { payload, status }) => {
    if (status !== 'success') return state;
    return state.set('productToEdit', Immutable.fromJS(payload));
  },

  [SAVE__DEFAULT_IMAGE]: (state, { payload, status }) => {
    if (status !== 'success') return state;
    const updatedProduct = state.get('productToEdit').toJS();
    updatedProduct.imageUrl = payload.imageUrl;
    updatedProduct.images = payload.images;

    return state.set('productToEdit', Immutable.fromJS(updatedProduct));
  },

  [DELETE__PRODUCT_IMAGE]: (state, { payload, status }) => {
    if (status !== 'success') return state;
    return state.set('productToEdit', Immutable.fromJS(payload));
  },
});
