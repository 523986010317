import { generateAsyncAction } from 'lib/action-generators';
import { SEARCH_FOR_ESTHETICIANS, GET_ESTIE_PROFILE } from './action-types';
import searchForEstheticians from '../graphql/queries/search-for-estheticians.graphql';
import graphql from 'lib/api/graphql';
import getEstieProfile from '../graphql/queries/get-estie-profile.graphql';

export default {
  searchForEstheticians: search =>
    generateAsyncAction(
      SEARCH_FOR_ESTHETICIANS,
      { search },
      graphql(searchForEstheticians, search),
      'searchForEstheticians'
    ),

  getEstieProfile: userId =>
    generateAsyncAction(
      GET_ESTIE_PROFILE,
      { userId },
      graphql(getEstieProfile, { userId }),
      'getEstieProfile'
    ),
};
