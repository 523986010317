import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const user = ImmutablePropTypes.contains({
  email: PropTypes.string,
  firstName: PropTypes.string,
  id: PropTypes.string,
  lastName: PropTypes.string
});

export const users = ImmutablePropTypes.listOf(user);
