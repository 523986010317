export const PRODUCT_VARIANT_GROUP_UPDATE = 'PRODUCT_VARIANT_GROUP_UPDATE';
export const PRODUCT_VARIANT_GROUP_BULK_UPDATE =
  'PRODUCT_VARIANT_GROUP_BULK_UPDATE';
export const PRODUCT_VARIANT_GROUP_DELETE = 'PRODUCT_VARIANT_GROUP_DELETE';

export const PRODUCT_VARIANT_GROUP_PRODUCT_UPDATE =
  'PRODUCT_VARIANT_GROUP_PRODUCT_UPDATE';
export const PRODUCT_VARIANT_GROUP_PRODUCT_BULK_UPDATE =
  'PRODUCT_VARIANT_GROUP_PRODUCT_BULK_UPDATE';
export const PRODUCT_VARIANT_GROUP_PRODUCT_DELETE =
  'PRODUCT_VARIANT_GROUP_PRODUCT_DELETE';
