import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const ListReportPage = lazy(() =>
  import(
    /* webpackChunkName: "ListReportPage" */ 'components/pages/admin/reports'
  ).then(({ ListReportPage }) => ({
    default: ListReportPage,
  }))
);

const ViewReportPage = lazy(() =>
  import(
    /* webpackChunkName: "ViewReportPage" */ 'components/pages/admin/reports'
  ).then(({ ViewReportPage }) => ({
    default: ViewReportPage,
  }))
);

export const ReportRoutes = [
  {
    component: ListReportPage,
    isExact: true,
    key: 'list-reports',
    path: '/reports',
    flag: FEATURE_FLAGS.VIEW_REPORTS,
  },
  {
    component: ViewReportPage,
    isExact: true,
    key: 'view-report',
    path: '/reports/:id',
    flag: FEATURE_FLAGS.VIEW_REPORTS,
  },
];
