import { LOAD_TASKS } from '../actions/action-types';

import { TasksReducerInitialState } from './initial-state';

export const TasksReducer = (
  state = TasksReducerInitialState,
  { status, type, payload }
) => {
  switch (type) {
    case LOAD_TASKS: {
      if (status !== 'success') return state;

      return {
        ...state,
        tasks: payload,
      };
    }

    default:
      return state;
  }
};

export default TasksReducer;
