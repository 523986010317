import {
  SEARCH_BRAND_EDUCATIONS,
  CREATE_BRAND_EDUCATION,
  LOAD_BRAND_EDUCATION,
  EDIT_BRAND_EDUCATION,
  DELETE_BRAND_EDUCATION,
  CREATE_BRAND_RESOURCE,
  DELETE_BRAND_RESOURCE,
  EDIT_BRAND_RESOURCE,
} from '../actions/action-types';

import { BrandEducationReducerInitialState } from './initial-state';

export const BrandEducationReducer = (
  state = BrandEducationReducerInitialState,
  { status, type, payload }
) => {
  switch (type) {
    case SEARCH_BRAND_EDUCATIONS: {
      if (status !== 'success') return { ...state, loading: true };
      if (!payload?.data?.length) return state;

      const brandEducationById = payload?.data?.reduce((acc, tag) => {
        acc[tag.id] = tag;
        return acc;
      }, {});

      return {
        ...state,
        loading: false,
        brandEducationById: {
          ...brandEducationById,
        },
      };
    }

    case CREATE_BRAND_EDUCATION: {
      if (status !== 'success') return state;

      return {
        ...state,
        loading: false,
        brandEducationById: {
          [payload.id]: {
            ...payload,
          },
          ...state.brandEducationById,
        },
      };
    }

    case DELETE_BRAND_EDUCATION: {
      if (status !== 'success') return { ...state, loading: true };

      const updatedBrandsById = Object.keys(state.brandEducationById)
        .filter(key => key !== payload)
        .reduce((obj, key) => {
          obj[key] = state.brandEducationById[key];
          return obj;
        }, {});

      return {
        ...state,
        loading: false,
        brandEducationById: {
          ...updatedBrandsById,
        },
      };
    }

    case EDIT_BRAND_EDUCATION:
    case LOAD_BRAND_EDUCATION: {
      if (status !== 'success') return state;

      return {
        ...state,
        brandEducation: payload,
      };
    }

    case CREATE_BRAND_RESOURCE: {
      if (status !== 'success') return state;

      return {
        ...state,
        brandEducation: {
          ...state.brandEducation,
          resources: [...state.brandEducation.resources, payload],
        },
      };
    }

    case EDIT_BRAND_RESOURCE: {
      if (status !== 'success') return state;
      const updatedResource = state.brandEducation.resources.map(resource => {
        return payload.id === resource.id
          ? { ...resource, ...payload }
          : resource;
      });

      return {
        ...state,
        brandEducation: {
          ...state.brandEducation,
          resources: updatedResource,
        },
      };
    }

    case DELETE_BRAND_RESOURCE: {
      if (status !== 'success') return { ...state, loading: true };

      const updatedResource = state.brandEducation.resources.filter(
        resource => resource.id !== payload
      );

      return {
        ...state,
        loading: false,
        brandEducation: {
          ...state.brandEducation,
          resources: updatedResource,
        },
      };
    }

    default:
      return state;
  }
};

export default BrandEducationReducer;
