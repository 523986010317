import React from 'react';
import {
  Add as AddIcon,
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpIcon,
  Edit as EditDetailsIcon,
  Message as MessagingIcon,
  ArrowBack as ArrowLeftIcon,
} from '@mui/icons-material';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ArrowDownUp from 'images/svg/components/arrowDownUp';
import { ARROW_LEFT, MESSAGING, EDIT, READ, ADD } from './typesIcons';

const reqSvgs = require.context('images/svg', false, /\.svg$/);
const svgs = reqSvgs.keys().map(path => ({ path, file: reqSvgs(path) }));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getIconByName = name => {
  const iconInFolder = svgs.find(o => o.path.includes(name));
  return iconInFolder?.file?.default;
};

const isMatchIcon = (types, type) => {
  return types.some(el => type.toLowerCase().includes(el));
};

/**
 * TODO: This seems really convoluted because to use this, I not
 * only have to preconfigure the icon I want to use, but I also require the
 * overhead of knowing which icon string to match: shouldn't we be able to
 * just pass an icon to this component? I also can't configure the icon
 * styles from the consumer - they're preconfigured here.
 *
 * Comment: The getIconButton function was removed because it was not used in the code.
 * Instead, MUI icons are directly used in the getIcon function, making the code cleaner.
 */
const getIcon = (type, clicked) => {
  switch (true) {
    case isMatchIcon(ADD, type):
      return <AddIcon />;
    case isMatchIcon(READ, type):
      return clicked ? (
        <ArrowUpIcon color="primary" />
      ) : (
        <ArrowDownIcon color="primary" />
      );
    case isMatchIcon(EDIT, type):
      return <EditDetailsIcon />;
    case isMatchIcon(MESSAGING, type):
      return <MessagingIcon />;
    case isMatchIcon(ARROW_LEFT, type):
      return <ArrowLeftIcon />;
    default:
      return null;
  }
};

export { getIcon };
