import { grey } from '@mui/material/colors';

export const POMP_LIGHT_THEME = {
  mode: 'light',
  primary: {
    main: '#18454b',
    dark: '#0d2d31',
    light: '#a0bec3',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#809dbd',
    dark: '#496788',
    light: '#bfcede',
    contrastText: '#0f2742',
  },
  tertiary: {
    main: '#e0cdbd',
    dark: '#775342',
    light: '#f0e6de',
  },
  error: {
    main: '#e2242a',
    dark: '#9e191d',
    light: '#e74f54',
    contrastText: '#ffffff',
  },
  warning: {
    main: '#f6c823',
    dark: '#ac8c18',
    light: '#f7d34f',
    contrastText: '#4c4c4c',
  },
  info: {
    main: '#809dbd',
    dark: '#496788',
    light: '#bfcede',
    contrastText: '#0F2743',
  },
  success: {
    main: '#008464',
    dark: '#005c46',
    light: '#339c83',
    alert: '#003528',
    contrastText: '#ffffff',
  },
  text: {
    primary: '#3C4142',
    secondary: 'rgba(60,65,66,0.75)',
    disabled: 'rgba(60,65,66,0.38)',
  },
  background: {
    default: '#F1EEEB',
    paper: '#FFFFFF',
    lightYellow: '#FFFAF0',

    // Not a generic MUI value, but using it as the background for inputs
    input: '#FFFFFF',
    tableOddRow: '#bfcede11',

    lightGray: grey[50],
    grey: grey[100],
    mediumGrey: grey[300],
  },
  backdrop: {
    overlay: '#00000080',
  },
};
