import React, { If } from 'react';
import { Button as MuiButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

/**
 * A wrapper around the Button, so we can set a loading state
 */
export const LoadingButton = ({ loading, children, disabled, ...props }) => {
  return (
    <MuiButton
      disabled={loading || disabled}
      startIcon={
        <If condition={loading}>
          <CircularProgress size={20} sx={{ color: 'inherit' }} />
        </If>
      }
      {...props}
    >
      {children}
    </MuiButton>
  );
};

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
};
