import { generateAsyncAction } from 'lib/action-generators';
import { LOAD_TASKS } from './action-types';
import graphql from 'lib/api/graphql';
import loadTasks from '../graphql/queries/load-tasks';

const TasksActions = {
  loadTasks: () =>
    generateAsyncAction(
      LOAD_TASKS,
      {},
      graphql(loadTasks),
      'listTaskSummariesForUser'
    ),
};

export default TasksActions;
