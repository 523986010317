import {
  LOAD_STORE_LANDING_PAGE,
  EDIT_STORE_LANDING_PAGE,
} from '../actions/action-types';

import { StoreLandingPageInitialState } from './initial-state';

export const StoreLandingPageReducer = (
  state = StoreLandingPageInitialState,
  { status, type, payload }
) => {
  switch (type) {
    case EDIT_STORE_LANDING_PAGE:
    case LOAD_STORE_LANDING_PAGE: {
      if (status !== 'success') return state;
      return {
        page: payload,
      };
    }

    default:
      return state;
  }
};

export default StoreLandingPageReducer;
