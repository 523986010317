import Immutable from 'immutable';

export default Immutable.fromJS({
  answers: {},
  areLoading: false,
  questions: [],
  saveAnswersAfterLogin: false,
  savedAnswers: false,
  surveyCompleted: false,
  open: false,
  step: 0
});
