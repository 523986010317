import {
  LOAD_BUSINESS,
  ADD_EMPLOYEE,
  REMOVE_EMPLOYEE,
  EDIT_EMPLOYEE,
} from '../actions/action-types';

import { BusinessReducerInitialState } from './initial-state';

export const BusinessReducer = (
  state = BusinessReducerInitialState,
  { status, type, payload }
) => {
  switch (type) {
    case LOAD_BUSINESS: {
      if (status !== 'success') return state;

      return {
        ...state,
        business: payload,
      };
    }

    case ADD_EMPLOYEE: {
      if (status !== 'success') return state;
      const updated = [payload, ...state.business.businessEmployees];

      return {
        ...state,
        business: {
          ...state.business,
          businessEmployees: updated,
        },
      };
    }

    case REMOVE_EMPLOYEE: {
      if (status !== 'success') return state;
      const updated = state.business.businessEmployees.filter(
        employee => employee.id !== payload.id
      );

      return {
        ...state,
        business: {
          ...state.business,
          businessEmployees: updated,
        },
      };
    }

    case EDIT_EMPLOYEE: {
      if (status !== 'success') return state;

      const employee = state.business.businessEmployees.find(
        employee => employee.id === payload.id
      );
      employee.type = payload.type;
      const updated = state.business.businessEmployees.filter(
        employee => employee.id !== payload.id
      );

      return {
        ...state,
        business: {
          ...state.business,
          businessEmployees: [employee, ...updated],
        },
      };
    }

    default:
      return state;
  }
};
