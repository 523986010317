import actionCreators from 'actionCreators';
import { useActions } from 'components/shared/hooks/useActions';

export const useAppActions = () => {
  const actions = useActions(actionCreators.appActions);

  return {
    ...actions,
  };
};
