import * as actionTypes from 'constants/actionTypes';
import creditsAvailableQuery from 'graphQL/queries/creditsAvailable';
import graphql from 'lib/api/graphql';
import { generateAsyncAction } from '../lib/action-generators';

export default {
  getAvailableCredits: userId =>
    generateAsyncAction(
      actionTypes.CREDITS_AVAILABLE,
      { userId },
      graphql(creditsAvailableQuery, { userId })
    ),
};
