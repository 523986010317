import { useActions } from 'components/shared';
import graphql from 'lib/api/graphql';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo
} from 'react';
import { useSelector } from 'react-redux';
import { InterceptedRequestActions } from '../action-creators';
import store from 'store';
import { useAuth } from 'features/auth';
import * as Interceptors from '../interceptors';

const getValueFromDotNotation = (obj, path) => {
  const pathParts = path.split('.');
  const first = pathParts.shift();

  if (!pathParts.length || !obj?.[first]) return obj?.[first];
  return getValueFromDotNotation(obj?.[first], pathParts.join('.'));
};

const setValueFromDotNotation = (obj, path, value) => {
  const pathParts = path.split('.');
  const first = pathParts.shift();

  if (!pathParts.length) {
    obj[first] = value;
    return obj;
  }

  if (!obj[first]) obj[first] = {};

  return {
    ...obj,
    [first]: setValueFromDotNotation(obj[first], pathParts.join('.'), value)
  };
};

const buildRequestArgs = (request, variablesByType) => {
  const { args, requestVariables } = request;

  return requestVariables.reduce(
    (acc, { type, key, arg }) =>
      setValueFromDotNotation(acc, arg, variablesByType[type][key]),
    args
  );
};

function CreateInterceptedRequestProvider() {
  const Context = createContext({});

  const { Provider } = Context;

  // eslint-disable-next-line react/prop-types
  const InterceptedRequestProvider = ({ children }) => {
    const state = useSelector(state => state.toJS().interceptedRequests);
    const [processingBatch, setProcessingBatch] = useState(false);
    const {
      setInterceptedRequests,
      setInterceptors,
      updateProcessedRequests,
      addInterceptedVariables
    } = useActions(InterceptedRequestActions);
    const { isAuthed } = useAuth();

    const requests = useMemo(() => [...state.requests], [state]);
    const variablesByType = useMemo(
      () => ({ ...state.variablesByType }),
      [state]
    );

    // LOAD FROM LOCAL STORAGE
    useEffect(() => {
      const storedRequests = localStorage.getItem('interceptedRequests');
      if (!storedRequests) return;
      const parsedRequests = JSON.parse(storedRequests);

      for (const { response, type } of parsedRequests) {
        store.dispatch(dispatch =>
          dispatch({
            type,
            status: 'intercepted',
            payload: response
          })
        );
      }

      setInterceptedRequests(parsedRequests);
    }, []);

    // SAVE TO LOCAL STORAGE
    useEffect(() => {
      localStorage.setItem('interceptedRequests', JSON.stringify(requests));
    }, [requests]);

    // Set interceptors
    useEffect(() => {
      setInterceptors(
        !isAuthed
          ? {
              CART_ITEM_UPDATE: 'GUEST_ADD_TO_CART_INTERCEPTOR',
              CART_ITEM_DELETE: 'GUEST_REMOVE_FROM_CART_INTERCEPTOR',
              PRODUCT__EDIT_GIFTCARD: 'GUEST_EDIT_GIFTCARD_INTERCEPTOR'
            }
          : {}
      );
    }, [isAuthed]);

    useEffect(async () => {
      // If there are no requests, or we are already processing a batch, return.
      if (!requests?.length || processingBatch) return;

      // Find a list of requests that have all of their variables set.
      const readyToProcess = requests.filter(({ requestVariables }) =>
        requestVariables.every(({ type, key }) =>
          Boolean(variablesByType?.[type]?.[key])
        )
      );

      // No records ready to process
      if (!readyToProcess.length) return;
      setProcessingBatch(true);

      for (const request of readyToProcess) {
        try {
          const args = buildRequestArgs(request, variablesByType);
          const { data: response = {} } = await graphql(request.query, args);

          const variables = request.responseVariables.map(variable => ({
            ...variable,
            value: getValueFromDotNotation(response, variable.arg)
          }));

          addInterceptedVariables(variables);
          Interceptors?.[request.interceptorName]?.replaceFunc({
            request,
            store,
            response
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
      updateProcessedRequests(readyToProcess.map(({ id }) => id));
    }, [variablesByType, requests, processingBatch]);

    return <Provider value={{}}>{children}</Provider>;
  };

  const useInterceptedRequest = () => {
    const context = useContext(Context);

    if (!context) {
      throw new Error(
        'useInterceptedRequest must be used within a InterceptedRequestProvider'
      );
    }

    return context;
  };

  return {
    InterceptedRequestProvider,
    useInterceptedRequest
  };
}

const { InterceptedRequestProvider, useInterceptedRequest } =
  CreateInterceptedRequestProvider();

export { InterceptedRequestProvider, useInterceptedRequest };
