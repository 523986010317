import * as actionTypes from 'constants/actionTypes';
import createReducer from 'reducers/createReducer';
import Immutable from 'immutable';
import initialState from './initialState';

function formatAnswerMap(rawAnswers) {
  const answers = {};

  rawAnswers.forEach(rawAnswer => {
    if (answers[rawAnswer.question] === undefined) {
      answers[rawAnswer.question] = {
        answer: rawAnswer.answer,
        choices: {},
        question: rawAnswer.question
      };
    }

    if (rawAnswer.choice === null) return;

    answers[rawAnswer.question].choices[rawAnswer.choice] = {
      answer: rawAnswer.answer,
      id: rawAnswer.choice
    };
  });

  return answers;
}

export default createReducer(initialState, {
  [actionTypes.SURVEY__LOAD_PENDING]: state => state.set('areLoading', true),

  [actionTypes.SURVEY__LOAD_FULFILLED]: (state, { payload: { data } }) => {
    const surveyQuestions = data.surveyQuestions.filter(
      question => question.version === 'v3'
    );

    return state
      .set('areLoading', false)
      .set('questions', Immutable.fromJS(surveyQuestions))
      .set('answers', Immutable.fromJS(formatAnswerMap(data.surveyAnswers)))
      .set('savedAnswers', data.surveyAnswers.length > 0);
  },

  [actionTypes.SURVEY__SET_ANSWER]: (state, { payload }) =>
    state.setIn(['answers', `${payload.question}`], Immutable.fromJS(payload)),

  [actionTypes.SURVEY__SAVE_ANSWERS_FULFILLED]: state =>
    state.set('savedAnswers', true),

  [actionTypes.SURVEY__SAVE_STEP]: (state, { payload }) =>
    state.set('step', payload.step),

  [actionTypes.AUTH_USER__LOGOUT]: () => initialState,

  [actionTypes.SURVEY__IS_OPEN]: state => state.set('open', true),

  [actionTypes.SURVEY__IS_CLOSE]: state => state.set('open', false)
});
