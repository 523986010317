import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const vendor = ImmutablePropTypes.contains({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
});

export const product = ImmutablePropTypes.contains({
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  retailPrice: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  vendor,
  wholesalePrice: PropTypes.number.isRequired
});

export const products = PropTypes.any;
