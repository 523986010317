//@ts-nocheck - silence cannot find module or type definitions error for graphql files
import { generateAsyncAction } from 'lib/action-generators';
import {
  ADD__PROGRESS_PHOTO,
  DELETE__PROGRESS_PHOTO,
  LOAD__PROGRESS_PHOTOS
} from './action-types';
import graphql from 'lib/api/graphql';
import loadProgressPhotos from '../graphql/queries/load-progress-photos.graphql';
import addProgressPhoto from '../graphql/mutations/add-progress-photo';
import deleteProgressPhoto from '../graphql/mutations/delete-progress-photo';

export const ProgressPhotoActions = {
  addProgressPhoto: (photo: object, userId: string | null, photoId: string | null) =>
    generateAsyncAction(
      ADD__PROGRESS_PHOTO,
      { photo, userId, photoId },
      graphql(addProgressPhoto, { photo, userId, photoId }),
      'addProgressPhoto'
    ),

  loadProgressPhotos: (userId: string) =>
    generateAsyncAction(
      LOAD__PROGRESS_PHOTOS,
      { userId },
      graphql(loadProgressPhotos, { userId }),
      'loadProgressPhotos'
    ),

  deleteProgressPhoto: (photoId: string) =>
    generateAsyncAction(
      DELETE__PROGRESS_PHOTO,
      { photoId },
      graphql(deleteProgressPhoto, { photoId }),
      'deleteProgressPhoto'
    ),
};

export default ProgressPhotoActions;
