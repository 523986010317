import { generateAsyncAction } from 'lib/action-generators';
import {
  SEARCH_BUSINESS,
  LOAD_BUSINESS,
  ADD_EMPLOYEE,
  REMOVE_EMPLOYEE,
  EDIT_EMPLOYEE,
} from './action-types';
import graphql from 'lib/api/graphql';
import searchBusiness from '../graphql/queries/search-business';
import loadBusiness from '../graphql/queries/load-business';
import addEmployee from '../graphql/mutations/add-employee';
import removeEmployee from '../graphql/mutations/remove-employee';
import editEmployee from '../graphql/mutations/edit-employee';

const BusinessActions = {
  searchBusiness: searchData =>
    generateAsyncAction(
      SEARCH_BUSINESS,
      searchData,
      graphql(searchBusiness, searchData),
      'searchBusiness'
    ),

  loadBusiness: businessId =>
    generateAsyncAction(
      LOAD_BUSINESS,
      { businessId },
      graphql(loadBusiness, { businessId }),
      'loadBusiness'
    ),

  addEmployee: (businessId, userId) =>
    generateAsyncAction(
      ADD_EMPLOYEE,
      { businessId, userId },
      graphql(addEmployee, { businessId, userId }),
      'addEmployee'
    ),

  removeEmployee: employeeId =>
    generateAsyncAction(
      REMOVE_EMPLOYEE,
      { employeeId },
      graphql(removeEmployee, { employeeId }),
      'removeEmployee'
    ),

  editEmployee: (employeeId, role) =>
    generateAsyncAction(
      EDIT_EMPLOYEE,
      { employeeId, role },
      graphql(editEmployee, { employeeId, role }),
      'editEmployee'
    ),
};

export default BusinessActions;
