import { RetailLocation, RetailTerminal } from '@pomp-libs/core';
import { RETAIL__BULK_UPDATE_LOCATION } from '../actions';

type RetailReducerState = {
  locationsById: {
    [locationId: string]: RetailLocation;
  };
  terminalsById: {
    [terminalId: string]: RetailTerminal;
  };
};

type RetailActionType = typeof RETAIL__BULK_UPDATE_LOCATION;

type RetailReducerAction = {
  type: RetailActionType;
  status: 'request' | 'success' | 'error';
  payload: any;
};

const initialState: RetailReducerState = {
  locationsById: {},
  terminalsById: {},
};

export const RetailReducer = (
  state: RetailReducerState = initialState,
  { type, payload, status }: RetailReducerAction
) => {
  switch (type) {
    case RETAIL__BULK_UPDATE_LOCATION: {
      if (status !== 'success') return state;

      const locationsById = { ...state.locationsById };
      const terminalsById = { ...state.terminalsById };

      for (const location of payload as RetailLocation[]) {
        locationsById[location.id] = location;

        for (const terminal of location.terminals) {
          terminalsById[terminal.id] = terminal;
        }
      }

      return {
        ...state,
        locationsById,
        terminalsById,
      };
    }

    default:
      return state;
  }
};
