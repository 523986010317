import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const ProgressPhotos = lazy(() =>
  import(
    /* webpackChunkName: "ProgressPhotos" */ '../components/index'
  ).then(({ ProgressPhotos }) => ({
    default: ProgressPhotos,
  }))
);

export const ProgressPhotoRoutes = [
  {
    component: ProgressPhotos,
    isExact: true,
    key: 'progress-photos',
    path: '/progress-photos',
    flag: FEATURE_FLAGS.PROGRESS_PHOTOS,
  },
];
