import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// This should only run in localhost, and should only run once on page load to pull in UTM params from the URL.
// For the rest of the environments this function will live inside gtm
export const UtmLocalhostManager = () => {
  const writeCookie = (key, value, days = 7) => {
    var dt, expires;
    dt = new Date();
    dt.setTime(dt.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + dt.toGMTString();
    document.cookie = key + '=' + value + expires + '; domain=localhost';
  };

  const { search = '' } = useLocation();

  useEffect(() => {
    if (window.location.hostname !== 'localhost') return;

    const params = new URLSearchParams(search);
    const source = params.get('utm_source');
    const medium = params.get('utm_medium');
    const campaign = params.get('utm_campaign');
    const term = params.get('utm_term');
    const content = params.get('utm_content');

    if (source) writeCookie('utm_source', source);
    if (medium) writeCookie('utm_medium', medium);
    if (campaign) writeCookie('utm_campaign', campaign);
    if (term) writeCookie('utm_term', term);
    if (content) writeCookie('utm_content', content);
  }, []);

  return null;
};
