import { createTheme } from '@mui/material';
import {
  POMP_CLASSIC_THEME,
  POMP_DARK_THEME,
  POMP_LIGHT_THEME,
} from './themes';

const getPaleteFromTheme = theme => {
  switch (theme) {
    case 'dark':
      return POMP_DARK_THEME;
    case 'classic':
      return POMP_CLASSIC_THEME;
    default:
      return POMP_LIGHT_THEME;
  }
};

export const generateTheme = (sidebarWidth = 0, theme = 'light') => {
  const palette = getPaleteFromTheme(theme);

  return createTheme({
    // Custom breakpoints that include side bar
    breakpoints: {
      values: {
        xs: 0,
        sm: 600 + sidebarWidth,
        md: 900 + sidebarWidth,
        lg: 1200 + sidebarWidth,
        xl: 1546 + sidebarWidth,
      },
    },
    palette,
    typography: {
      fontFamily: `'proxima-nova', 'Helvetica Neue', Arial, sans-serif`,
      button: {
        letterSpacing: '0.46px',
      },
      allVariants: {
        color: palette.text.primary,
      },
      title: {
        fontFamily: "'century-old-style-std', serif",
        fontSize: '34px',
        lineHeight: '41.99px',
        letterSpacing: '0.25px',
      },
      subtitle: {
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '21.98px',
        letterSpacing: '0.1px',
        color: palette.text.secondary,
      },
      secondaryTitle: {
        fontFamily: "'proxima-nova', sans-serif",
        fontSize: '19.2px',
        letterSpacing: '0.25px',
        fontWeight: 700,
      },
      h1: {
        fontFamily: "'century-old-style-std', serif",
        fontSize: '4rem',
      },
      h2: {
        fontFamily: "'century-old-style-std', serif",
        fontSize: '3rem',
      },
      h3: {
        fontFamily: "'century-old-style-std', serif",
        fontSize: '2rem',
      },
      h4: {
        fontFamily: "'century-old-style-std', serif",
        fontSize: '1.5rem',
      },
      h5: {
        fontFamily: "'century-old-style-std', serif",
        fontSize: '1.3rem',
      },
      h6: {
        fontSize: '1.2rem',
        fontWeight: 700,
      },
      overline: {
        fontWeight: 600,
        letterSpacing: '0.1em',
      },
      // A type that should inherit everything from it's container.
      inherit: {},
    },
    components: {
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // This will need to be kept an eye on, but without this the default borderColor is the almost black primary Typography color
            color: palette.text.disabled,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ theme }) => {
            return {
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
              textTransform: 'none',
              boxShadow: 'none',
              fontWeight: 500,
            };
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontWeight: 400,
          },
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiCard: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            borderRadius: '10px',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          boxShadow: 'none',
          border: 'none',
          '& :-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 1000px ${palette.primary.light} inset`,
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.primary.light,
            },
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.primary.main,
            },
          },
        },
        notchedOutline: {
          borderColor: palette.text.disabled,
        },
      },
      MuiMenu: {
        defaultProps: {
          BackdropProps: {
            sx: { background: 'none' },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: palette.backdrop.overlay,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            '&:before': {
              display: 'none',
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            width: '40px',
            height: '40px',
            backgroundColor: palette.primary.light,
            color: palette.primary.contrastText,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: palette.background.input,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            background: 'none !important',
          },
        },
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: palette.primary.main,
          },
        },
      },
      MuiDialogTitle: {
        defaultProps: {
          variant: 'h5',
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: 'underline',
            fontWeight: 'bold',
            color: palette.primary.main,
          },
        },
      },
      MuiSkeleton: {
        defaultProps: {
          animation: 'wave',
        },
      },
      MuiAlert: {
        defaultProps: {
          icon: false,
        },
        styleOverrides: {
          root: {
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4))`,
          },
          standardSuccess: {
            borderColor: palette.success.dark,
            backgroundColor: palette.success.light,
            color: palette.text.default,
          },
          standardError: {
            borderColor: palette.error.dark,
            backgroundColor: palette.error.light,
            color: palette.text.default,
          },
          standardWarning: {
            borderColor: palette.warning.dark,
            backgroundColor: palette.warning.light,
            color: palette.text.default,
          },
          standardInfo: {
            borderColor: palette.info.dark,
            backgroundColor: palette.info.light,
            color: palette.text.default,
          },
        },
      },
    },
  });
};

export const Theme = generateTheme();
