import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const checkout = ImmutablePropTypes.contains({
  step: PropTypes.number,
  total: PropTypes.number,
  paypal: PropTypes.any,
  billingAsShipping: PropTypes.bool,
  disabled: PropTypes.bool
});
