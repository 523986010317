import React, { lazy, memo, useEffect, useMemo } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppRoutes } from './routes';
import appPropTypes from 'constants/propTypes';
import Backdrop from '@mui/material/Backdrop';
import { useAuth } from 'features/auth';
import { useDialog, useSideBarAwareTheme } from 'providers';
import Layout from '../Layout';
import { UtmLocalhostManager } from './utm-localhost-manager';
import { Box } from '@mui/material';
import LogoSquare from 'images/svg/LogoSquare';
import { makeStyles } from '@mui/styles';

const GuestPage = lazy(
  () =>
    import(/* webpackChunkName: "GuestPage" */ 'components/shared/GuestPage')
);
const NotFoundPage = lazy(
  () => import(/* webpackChunkName: "NotFoundPage" */ '../pages/NotFound')
);

const DocumentRequiredSigningAction = lazy(() =>
  import(
    /* webpackChunkName: "DocumentRequiredSigningAction" */
    'features/documents/dialogs'
  ).then(({ DocumentRequiredSigningAction }) => ({
    default: DocumentRequiredSigningAction,
  }))
);

const RegisterDialog = lazy(() =>
  import(
    /* webpackChunkName: "RegisterDialog" */
    'features/auth/dialogs/register-dialog'
  ).then(({ RegisterDialog }) => ({
    default: RegisterDialog,
  }))
);

const SwitchWithRouter = withRouter(Switch);

// TODO: Migrate this to a shared animation component.
const useAnimation = makeStyles(theme => ({
  pulse: {
    animation: `$pulse 5000ms ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite',
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 0.7,
      transform: 'scale(1)',
    },
    '50%': {
      opacity: 1,
      transform: 'scale(1.2)',
    },
    '100%': {
      opacity: 0.7,
      transform: 'scale(1)',
    },
  },
}));

export const AppLoader = () => {
  const classes = useAnimation();
  const { margins } = useSideBarAwareTheme();

  return (
    <Backdrop
      open
      sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
        display: 'flex',
        ...margins,
      }}
    >
      <Box className={classes.pulse}>
        <Box sx={{ transform: 'scale(2)' }}>
          <LogoSquare color="primary.main" />
        </Box>
      </Box>
    </Backdrop>
  );
};

/**
 * Prevent re-rendering an Authenticated component,
 * unless the user, feature flags OR location (URL) Changes.
 * @param previousProps
 * @param nextProps
 * @returns {boolean}
 */
const shouldNotRerender = (previousProps, nextProps) =>
  JSON.stringify(previousProps.location) ===
    JSON.stringify(nextProps.location) &&
  previousProps.authUser.get('id') === nextProps.authUser.get('id') &&
  JSON.stringify(previousProps.authUser.get('featureFlags').toJS()) ===
    JSON.stringify(nextProps.authUser.get('featureFlags').toJS());

// eslint-disable-next-line react/display-name
const AuthRoute = memo(
  ({ component: Component, authUser, isAuthed, guestPageProps, ...rest }) => (
    <Route
      {...rest}
      render={props => (
        <Choose>
          <When condition={authUser.get('id') === null && guestPageProps}>
            <GuestPage {...props} {...guestPageProps} />
          </When>
          <When condition={authUser.get('id') === null && isAuthed}>
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          </When>
          <Otherwise>
            <Component {...props} />
          </Otherwise>
        </Choose>
      )}
    />
  ),
  shouldNotRerender
);

AuthRoute.propTypes = {
  component: PropTypes.elementType,
  authUser: appPropTypes.authUser,
  isAuthed: PropTypes.bool,
  location: appPropTypes.location,
  guestPageProps: PropTypes.object,
};

const PompApplication = () => {
  const { user: authUser, enabledFeatures, loading } = useAuth();
  const { search, pathname } = useLocation();
  const { replace } = useHistory();
  const { open: openDialog } = useDialog();

  /**
   * Extract the referrerId and/or businessId from the query string, so we can use any page as a referral link.
   */
  useEffect(() => {
    if (!search) return;

    const queryParms = new URLSearchParams(search);
    const referrerId = queryParms.get('referrerId');
    const businessId = queryParms.get('businessId');

    if (!referrerId && !queryParms.has('register')) return;
    if (referrerId) {
      localStorage.setItem('referrerId', referrerId);
      queryParms.delete('referrerId');

      if (businessId) {
        localStorage.setItem('businessId', businessId);
        queryParms.delete('businessId');
      }
    }

    if (queryParms.has('register')) {
      openDialog({
        component: RegisterDialog,
        containerProps: {
          PaperProps: {
            style: {
              borderRadius: '10px',
            },
          },
        },
      });
      queryParms.delete('register');
    }
    replace({ search: queryParms.toString() });
  }, [search]);

  const routes = useMemo(
    () => AppRoutes.filter(route => !route.flag || enabledFeatures[route.flag]),
    [enabledFeatures, AppRoutes, authUser]
  );

  // For all authentication routes, we want to add redirects to the home page.
  const redirectRoutes = useMemo(
    () =>
      AppRoutes.filter(
        route => route.flag && !enabledFeatures[route.flag] && !route.isAuthed
      ),
    [enabledFeatures, AppRoutes]
  );

  // If the user is still loading, or we haven't got any features yet, show the loader.
  if (!authUser || loading || Object.keys(enabledFeatures).length === 0)
    return <AppLoader />;

  return (
    <Layout>
      <UtmLocalhostManager />
      <DocumentRequiredSigningAction authUser={authUser} />
      <SwitchWithRouter>
        <For each="routeConfig" of={routes}>
          <AuthRoute
            authUser={authUser}
            isAuthed={routeConfig.isAuthed}
            component={routeConfig.component}
            exact={routeConfig.isExact}
            key={routeConfig.key}
            path={routeConfig.path}
            guestPageProps={routeConfig.guestPageProps}
          />
        </For>

        {/* Redirecting from auth routes */}
        <For each="routeConfig" of={redirectRoutes}>
          <Redirect
            from={routeConfig.path}
            to={
              !authUser?.get('id')
                ? {
                    pathname: '/login',
                    state: { from: `${pathname}${search}` },
                  }
                : '/'
            }
            key={routeConfig.key}
          />
        </For>

        <Redirect from="/register" to="/" />
        <Redirect from="/login" to="/" />
        <Redirect from="/messaging" to="/cart?messaging=true" />
        <Redirect from="/logout" to="/login" />

        {/* TODO: Remove this redirect once we can confirm all live links no longer go to the old route. */}
        <Redirect from="/the-enhancements" to="/shop" />

        <Choose>
          <When condition={!authUser?.get('id')}>
            <Redirect
              to={{
                pathname: '/login',
                state: { from: `${pathname}${search}` },
              }}
            />
          </When>
          <Otherwise>
            <Route component={NotFoundPage} />
          </Otherwise>
        </Choose>
      </SwitchWithRouter>
    </Layout>
  );
};

export default withRouter(PompApplication);
