import React from 'react';
import { AppContainer } from '../../../components/shared/base/app-container';
import PropTypes from 'prop-types';
import InitialButton from '../../../components/shared/Buttons/InitialButton';

export const ExternalPage = ({
  name,
  url,
  displayUrl = null,
  subheader = null,
}) => (
  <AppContainer
    title={name}
    subheader={subheader}
    containerSx={{
      display: 'flex',
      flexDirection: 'column',
      pb: 2,
    }}
    actions={
      <InitialButton
        variant="outlined"
        href={url}
        color="primary"
        target="_blank"
        size="small"
        name="Open in New Tab"
        capitalize={false}
      />
    }
  >
    <iframe
      src={displayUrl || url}
      style={{
        borderRadius: '10px',
        background: '#fff',
        flex: 1,
        padding: 8,
        border: 'none',
      }}
    />
  </AppContainer>
);

ExternalPage.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  displayUrl: PropTypes.string,
  subheader: PropTypes.string,
};
