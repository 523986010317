import React from 'react';
import { CardActions, Dialog, IconButton, Slide } from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

const SideCarTransition = React.forwardRef((props, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

SideCarTransition.displayName = 'SideCarTransition';

export const BaseSidecar = ({
  children,
  hideCloseButton = true,
  cancel,
  paperStyles,
  ...props
}) => (
  <Dialog
    fullWidth
    maxWidth="xs"
    transitionDuration={300}
    TransitionComponent={SideCarTransition}
    PaperProps={{
      sx: {
        height: 1,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        maxHeight: 'none',
        width: 1,
        ...paperStyles,
      },
    }}
    {...props}
  >
    {!hideCloseButton && (
      <CardActions sx={{ justifyContent: 'flex-end', m: 1 }}>
        <IconButton aria-label="close" onClick={cancel}>
          <CloseIcon size="large" />
        </IconButton>
      </CardActions>
    )}
    {children}
  </Dialog>
);

BaseSidecar.propTypes = {
  children: PropTypes.element,
  hideCloseButton: PropTypes.bool,
  cancel: PropTypes.func,
  paperStyles: PropTypes.object,
};
