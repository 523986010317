import {
  NOTES__CREATE,
  NOTES__LOAD_NOTES_ABOUT_USER,
  NOTES__UPDATE,
  NOTES__DELETE
} from 'constants/actionTypes';
import { asyncActionStatuses } from 'lib/action-generators';
import NotesReducerInitialState from './initialState';

export default (
  state = NotesReducerInitialState,
  { type, payload, status, requestPayload }
) => {
  switch (type) {
    case NOTES__LOAD_NOTES_ABOUT_USER: {
      switch (status) {
        case asyncActionStatuses.REQUEST: {
          return {
            ...state,
            aboutUserId: {
              ...state.aboutUserId,
              [payload.filters.userId]: {
                loading: true,
                count: undefined,
                notes: state.aboutUserId[payload.filters.userId]?.notes ?? []
              }
            }
          };
        }

        case asyncActionStatuses.SUCCESS: {
          return {
            ...state,
            aboutUserId: {
              ...state.aboutUserId,
              [requestPayload.filters.userId]: {
                loading: false,
                count: payload.data.noteSearch.count,
                // Append to existing with the next page of results
                notes: [
                  ...(state.aboutUserId[requestPayload.filters.userId]?.notes ??
                    []),
                  ...payload.data.noteSearch.data
                ]
              }
            }
          };
        }
      }

      return state;
    }

    case NOTES__CREATE: {
      switch (status) {
        case asyncActionStatuses.REQUEST: {
          /**
           * Optimistically update the state with the new note, supplying
           * a temporary uuid until the server responds with the actual id
           */
          return {
            ...state,
            aboutUserId: {
              ...state.aboutUserId,
              [payload.userId]: {
                ...state.aboutUserId[payload.userId],
                count: state.aboutUserId[payload.userId]?.count + 1,
                notes: [
                  payload,
                  ...(state.aboutUserId[payload.userId]?.notes
                    ? state.aboutUserId[payload.userId].notes
                    : [])
                ]
              }
            }
          };
        }

        case asyncActionStatuses.SUCCESS: {
          const { upsertNote: note } = payload.data;

          const newNotes = [
            note,
            ...state.aboutUserId[requestPayload.userid].notes.filter(
              note => note.id !== requestPayload.id
            )
          ];

          return {
            ...state,
            aboutUserId: {
              ...state.aboutUserId,
              [requestPayload.userId]: {
                ...state.aboutUserId[requestPayload.userId],
                notes: newNotes
              }
            }
          };
        }
      }

      return state;
    }

    case NOTES__UPDATE: {
      switch (status) {
        case asyncActionStatuses.REQUEST: {
          return {
            ...state,
            aboutUserId: {
              [requestPayload.userId]: {
                ...state.aboutUserId[requestPayload.userId],
                notes: state.aboutUserId[requestPayload.userId].notes.map(
                  note => {
                    if (note.id === requestPayload.id) {
                      return {
                        ...note,
                        /**
                         * Optimistically update the state with the new data
                         */
                        updated: requestPayload.updated,
                        title: requestPayload.title,
                        note: requestPayload.note
                      };
                    }

                    return note;
                  }
                )
              }
            }
          };
        }

        case asyncActionStatuses.SUCCESS: {
          return {
            ...state,
            aboutUserId: {
              [payload.userId]: state.aboutUserId[payload.userId].map(note => {
                if (note.id === payload.id) {
                  return payload;
                }

                return note;
              })
            }
          };
        }
      }

      return state;
    }

    case NOTES__DELETE: {
      switch (status) {
        case asyncActionStatuses.REQUEST: {
          return {
            ...state,
            aboutUserId: {
              [requestPayload.userId]: {
                ...state.aboutUserId[requestPayload.userId],

                /**
                 * Optimistically update the state by filtering out
                 * the deleted note
                 */
                notes: state.aboutUserId[requestPayload.userId].notes.filter(
                  note => note.id !== requestPayload.id
                )
              }
            }
          };
        }
      }

      return state;
    }
  }

  return state;
};
