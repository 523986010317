import {
  CUSTOM_LINKS__LOAD_ALL,
  CUSTOM_LINKS__SEARCH,
  CUSTOM_LINKS__SAVE,
  CUSTOM_LINKS__DELETE
} from './action-types';
import graphql from 'lib/api/graphql';
import customLinksLoadAll from '../../../graphQL/queries/customLinksLoadAll.graphql';
import customLinksSave from '../../../graphQL/mutations/customLinksSave.graphql';
import customLinksSearch from '../../../graphQL/queries/customLinksSearch.graphql';
import customLinkDelete from '../../../graphQL/mutations/customLinksDelete.graphql';

export default {
  loadAll: () => dispatch =>
    dispatch({
      type: CUSTOM_LINKS__LOAD_ALL,
      payload: graphql(customLinksLoadAll)
    }),

  save: customLink => dispatch =>
    dispatch({
      type: CUSTOM_LINKS__SAVE,
      payload: graphql(customLinksSave, { customLink })
    }),

  search: searchQuery => dispatch =>
    dispatch({
      type: CUSTOM_LINKS__SEARCH,
      payload: graphql(customLinksSearch, searchQuery)
    }),

  delete: customLink => ({
    type: CUSTOM_LINKS__DELETE,
    payload: graphql(customLinkDelete, { customLink })
  })
};
