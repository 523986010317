import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const UserDetailsPage = lazy(() =>
  import(
    /* webpackChunkName: "UserDetailsPage" */ 'components/pages/admin/UserDetail'
  ).then(({ EstheticianUserDetailsPage }) => ({
    default: EstheticianUserDetailsPage,
  }))
);

const ManageUsers = lazy(
  () =>
    import(
      /* webpackChunkName: "ManageUsers" */ 'features/users/pages/user-search-page'
    )
);

export const UserRoutes = [
  {
    component: ManageUsers,
    isExact: true,
    key: 'users',
    path: '/users',
    isAuthed: true,
    flag: FEATURE_FLAGS.USER_SEARCH,
  },
  // TODO: Remove this after a little bit when no one is using it.
  {
    component: UserDetailsPage,
    isExact: false,
    key: 'user-detail-deprecated',
    path: '/user/:userId',
    isAuthed: true,
    flag: FEATURE_FLAGS.USER_SEARCH,
  },
  {
    component: UserDetailsPage,
    isExact: false,
    key: 'user-detail',
    path: '/users/:userId',
    isAuthed: true,
    flag: FEATURE_FLAGS.USER_SEARCH,
  },
];
