import {
  INTEGRATION_ACCOUNTS__BULK_UPDATE,
  INTEGRATION_ACCOUNTS__DELETE,
  INTEGRATION_ACCOUNTS__SINGLE_UPDATE,
  INTEGRATIONS__BULK_UPDATE,
} from '../actions';
import { IntegrationsReducerInitialState } from './initialState';

export const IntegrationsReducer = (
  state = IntegrationsReducerInitialState,
  { type, payload, requestPayload, status }
) => {
  switch (type) {
    case INTEGRATIONS__BULK_UPDATE: {
      if (status === 'request') return { ...state, loadingIntegrations: true };
      if (status !== 'success') return state;

      return {
        ...state,
        loadingIntegrations: false,
        integrationsByType: {
          ...state.integrationsByType,
          ...payload.reduce(
            (acc, integration) => ({ ...acc, [integration.key]: integration }),
            {}
          ),
        },
      };
    }

    case INTEGRATION_ACCOUNTS__BULK_UPDATE: {
      if (status === 'request') return { ...state, loadingAccounts: true };
      if (status !== 'success') return state;

      return {
        ...state,
        loadingAccounts: false,
        integrationAccountsById: {
          ...state.integrationAccountsById,
          ...payload.reduce(
            (acc, integrationAccount) => ({
              ...acc,
              [integrationAccount.id]: integrationAccount,
            }),
            {}
          ),
        },
        // For now assumes each user can only have a single integration account per integration type
        integrationAccountsByType: {
          ...payload.reduce(
            (acc, integrationAccount) => ({
              ...acc,
              [integrationAccount.integration.key]: integrationAccount,
            }),
            {}
          ),
        },
      };
    }

    case INTEGRATION_ACCOUNTS__SINGLE_UPDATE: {
      if (status !== 'success') return state;

      return {
        ...state,
        integrationAccountsById: {
          ...state.integrationAccountsById,
          [payload.id]: payload,
        },
        integrationAccountsByType: {
          ...state.integrationAccountsByType,
          [payload.integration.key]: payload,
        },
      };
    }

    case INTEGRATION_ACCOUNTS__DELETE: {
      if (status !== 'success') return state;

      const accountId = requestPayload.integrationAccountId;
      const account = state.integrationAccountsById[accountId];

      const integrationAccountsById = { ...state.integrationAccountsById };
      const integrationAccountsByType = { ...state.integrationAccountsByType };

      delete integrationAccountsById[account.id];
      delete integrationAccountsByType[account.integration.key];

      return {
        ...state,
        integrationAccountsById: {
          ...integrationAccountsById,
        },
        integrationAccountsByType: {
          ...integrationAccountsByType,
        },
      };
    }

    default:
      return state;
  }
};
