import graphql from './graphql';

/**
 * A helper class that provided a named request will cancel any previous requests with the
 * same name to prevent duplicate/superseded requests
 */
class GraphqlSingletonWrapper {
  requests = {};

  register(name) {
    return async (query, variables) => {
      if (this.requests[name]) {
        await this.requests[name].abort();
      }

      this.requests[name] = new AbortController();
      const res = await graphql(query, variables, this.requests[name]);

      delete this.requests[name];
      return res;
    };
  }
}

const graphqlSingletonWrapper = new GraphqlSingletonWrapper();

export { graphqlSingletonWrapper, GraphqlSingletonWrapper };
