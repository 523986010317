import PropTypes from 'prop-types';

export const history = PropTypes.shape({
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired
});

export const match = PropTypes.shape({
  isExact: PropTypes.bool,
  params: PropTypes.object,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
});

export const location = PropTypes.shape({
  hash: PropTypes.string.isRequired,
  key: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  state: PropTypes.object
});
