import { LOAD_COLLECTIONS } from '../actions/action-types';
import { CollectionReducerInitialState } from './initial-state';
import { PRODUCT__SEARCH_FULFILLED } from 'constants/actionTypes';

export const CollectionsReducer = (
  state = CollectionReducerInitialState,
  { status, type, payload }
) => {
  switch (type) {
    case LOAD_COLLECTIONS: {
      if (status !== 'success') return state;

      const collectionsById = payload?.reduce((acc, collection) => {
        acc[collection.id] = collection;
        return acc;
      }, {});

      return {
        collectionsById: {
          ...collectionsById,
        },
      };
    }

    case PRODUCT__SEARCH_FULFILLED: {
      const {
        data: {
          productSearch: { count },
        },
      } = payload;

      return {
        ...state,
        productsCount: count,
      };
    }

    default:
      return state;
  }
};
