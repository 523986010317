const { DOCUMENT_TYPES } = require('@pomp-libs/core');
import { APP__START_FULFILLED } from 'constants/actionTypes';

import {
  GET_ALL_DOCUMENTS,
  GET_LATEST_ESTHIE_AGREEMENT_DOCUMENTS,
  SIGN_DOCUMENT_BY_ID,
} from '../actions';

import { DocumentsReducerInitialState } from './initialState';

export const DocumentsReducer = (
  state = DocumentsReducerInitialState,
  { type, payload, status }
) => {
  switch (type) {
    case APP__START_FULFILLED: {
      if (!payload?.data) return state;
      const { data } = payload;

      if (!data.latestDocuments) return state;
      return {
        ...state,
        allDocuments: data.latestDocuments,
      };
    }
    case GET_ALL_DOCUMENTS: {
      if (status !== 'success') return state;
      if (!payload.length) return state;

      const latestEsthieAgreementDocuments = payload.map(document => {
        if (document.type === DOCUMENT_TYPES.ESTHETICIAN_AGREEMENT) {
          return document;
        }
      });

      return {
        ...state,
        // Add documents to the reducer
        allDocuments: payload,
        // loading all latest esthie agreement documents
        latestEsthieAgreementDocuments: latestEsthieAgreementDocuments,
      };
    }

    case GET_LATEST_ESTHIE_AGREEMENT_DOCUMENTS: {
      if (status !== 'success') return state;
      if (!payload.length) return state;

      return {
        ...state,
        // Add documents to the reducer
        latestEsthieAgreementDocuments: payload,
      };
    }

    case SIGN_DOCUMENT_BY_ID: {
      if (status !== 'success') return state;
      const { data } = payload;

      if (!data.length) return state;

      return {
        ...state,
        // Add documents to the reducer
        signedDocumentsById: data.reduce(
          (acc, document) => ({ ...acc, [document.id]: document }),
          state.signedDocumentsById
        ),
      };
    }

    default:
      return state;
  }
};
