import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const vendor = ImmutablePropTypes.contains({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
});

export const vendors = ImmutablePropTypes.listOf(vendor);

export const vendorsState = ImmutablePropTypes.contains({
  all: vendors,
  areLoading: PropTypes.bool.isRequired
});
