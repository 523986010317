import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const CustomLinks = lazy(() =>
  import(
    /* webpackChunkName: "RoutineView" */ 'components/pages/profile/sections/custom-links/index.js'
  )
);

export const CustomLinksRoutes = [
  {
    component: CustomLinks,
    isExact: false,
    key: 'custom-links',
    path: '/custom-links',
    flag: FEATURE_FLAGS.MANAGE_CUSTOM_LINKS,
  },
];
