import {
  NOTES__CREATE,
  NOTES__LOAD_NOTES_ABOUT_USER,
  NOTES__UPDATE,
  NOTES__DELETE
} from 'constants/actionTypes';
import { generateAsyncAction } from 'lib/action-generators';
import graphql from 'lib/api/graphql';
import upsertNote from 'graphQL/mutations/admin/upsertNote';
import deleteNote from 'graphQL/mutations/admin/deleteNote';
import notes from 'graphQL/queries/noteSearch';

/**
 * @typedef {Object} UpsertNoteInput
 * @property {string} id - A uuid.v4 for the new note so that it can be immediately added to the list optimistically
 * @property {string} title - The title of the new note
 * @property {string} note - The new note's content
 * @property {string} userId - The user the esthetician / admin is writing a note about
 * @property {string} created - A date for the new note so that it can be added to the list optimistically
 * @property {string} updated - A date for the new note so that it can be added to the list optimistically
 */

/**
 * @typedef {Object} Note
 * @property {string} id - A uuid.v4 for the new note so that it can be immediately added to the list optimistically
 * @property {string} title - The title of the new note
 * @property {string} note - The new note's content
 * @property {string} userId - The user the esthetician / admin is writing a note about
 * @property {string} authorId - The esthetician / admin that wrote the note
 * @property {string} created - A date for the new note so that it can be added to the list optimistically
 * @property {string} updated - A date for the new note so that it can be added to the list optimistically
 */

/**
 * @typedef {Object} Sort
 * @property {string} field - The field to sort by
 * @property {'asc' | 'desc'} direction - The direction to sort by
 */

/**
 * @typedef {Object} Pagination
 * @property {number} size- The size of the page to load
 * @property {number} page - The page number to load
 */

/**
 * @typedef {Object} NotesQueryInput
 * @property {string} query - A search string to filter notes by title
 * @property {Note} filters - A where clause for the notes query
 * @property {Pagination} pagination - The pagination details to apply to the notes query
 * @property {Sort} sort - An orderBy clause for the notes query

 */

export default {
  /** @params {UpsertNoteInput}} */
  create: ({ id, title, note, userId, created, updated }) =>
    generateAsyncAction(
      NOTES__CREATE,
      {
        id,
        title,
        note,
        userId,
        created,
        updated
      },
      graphql(upsertNote, { input: { title, note, userId } })
    ),

  /** @params {NotesQueryInput} */
  loadNotesAboutUser: ({ query, filters, pagination, sort }) =>
    generateAsyncAction(
      NOTES__LOAD_NOTES_ABOUT_USER,
      {
        query,
        filters,
        pagination,
        sort
      },
      graphql(notes, { query, filters, pagination, sort })
    ),

  /** @params {UpsertNoteInput}} */
  updateNote: ({ id, title, note, userId, created, updated }) =>
    generateAsyncAction(
      NOTES__UPDATE,
      { id, title, note, userId, created, updated },
      graphql(upsertNote, {
        input: { id, title, note, userId }
      })
    ),

  /**
   * @params {string} The id of the note to delete
   * @params {string} The id of the user the note is about
   */
  deleteNote: (noteId, userId) =>
    generateAsyncAction(
      NOTES__DELETE,
      { id: noteId, userId },
      graphql(deleteNote, { id: noteId })
    )
};
