import {
  LOAD__WISHLIST_PRODUCTS,
  ADD__WISHLIST_PRODUCT,
  DELETE__WISHLIST_PRODUCT,
} from '../actions/action-types';

import { WishlistsReducerInitialState } from './initial-state';

export const WishlistsReducer = (
  state = WishlistsReducerInitialState,
  { status, type, payload, requestPayload }
) => {
  switch (type) {
    case LOAD__WISHLIST_PRODUCTS: {
      if (status !== 'success') return state;

      return {
        ...state,
        wishlistProducts: payload,
      };
    }

    case ADD__WISHLIST_PRODUCT: {
      if (status !== 'success') return state;
      const updatedProducts = [...state.wishlistProducts, payload];

      return {
        wishlistProducts: updatedProducts,
      };
    }

    case DELETE__WISHLIST_PRODUCT: {
      if (status !== 'success') return state;
      const updatedProducts = state.wishlistProducts.filter(
        product => product.id !== requestPayload.wishlistProductId
      );

      return {
        wishlistProducts: updatedProducts,
      };
    }

    default:
      return state;
  }
};

export default WishlistsReducer;
