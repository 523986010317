import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

export const credit = ImmutablePropTypes.contains({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  consumedAmount: PropTypes.number.isRequired
});

export const credits = ImmutablePropTypes.listOf(credit);

export const creditsState = ImmutablePropTypes.contains({
  creditsAvailable: credits
});
