import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CardActions, Dialog, Slide } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';

const useCloseButtonStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
});

const DialogTransition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

DialogTransition.displayName = 'DialogTransition';

export const BaseDialog = ({
  children,
  cancel,
  showFloatingCloseButton = false,
  maxWidth = 'sm',
  ...props
}) => {
  const closeStyles = useCloseButtonStyles();

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      transitionDuration={300}
      TransitionComponent={DialogTransition}
      {...props}
    >
      {showFloatingCloseButton && (
        <CardActions classes={closeStyles}>
          <IconButton sx={{ zIndex: 1 }} onClick={cancel} aria-label="cancel">
            <CloseIcon />
          </IconButton>
        </CardActions>
      )}
      {children}
    </Dialog>
  );
};

BaseDialog.propTypes = {
  children: PropTypes.any.isRequired,
  cancel: PropTypes.func.isRequired,
  showFloatingCloseButton: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};
