import {
  SEARCH_TAGS,
  CREATE_TAG,
  DELETE_TAG,
  LOAD_TAG,
  EDIT_TAG,
  ADD_PRODUCTS_TO_TAG,
  DELETE_PRODUCT_TAG,
} from '../actions/action-types';

import { TagReducerInitialState } from './initial-state';

export const TagsReducer = (
  state = TagReducerInitialState,
  { status, type, payload }
) => {
  switch (type) {
    case SEARCH_TAGS: {
      if (status !== 'success') return { ...state, loading: true };
      if (!payload?.data?.length) return state;

      const tagsById = payload?.data?.reduce((acc, tag) => {
        acc[tag.id] = tag;
        return acc;
      }, {});

      return {
        ...state,
        loading: false,
        tagsById: {
          ...tagsById,
        },
      };
    }

    case CREATE_TAG: {
      if (status !== 'success') return state;

      return {
        ...state,
        loading: false,
        tagsById: {
          [payload.id]: {
            ...payload,
          },
          ...state.tagsById,
        },
      };
    }

    case DELETE_TAG: {
      if (status !== 'success') return { ...state, loading: true };

      const updatedTagsById = Object.keys(state.tagsById)
        .filter(key => key !== payload)
        .reduce((obj, key) => {
          obj[key] = state.tagsById[key];
          return obj;
        }, {});

      return {
        ...state,
        loading: false,
        tagsById: {
          ...updatedTagsById,
        },
      };
    }

    case EDIT_TAG:
    case LOAD_TAG: {
      if (status !== 'success') return state;

      return {
        ...state,
        tag: payload,
      };
    }

    case ADD_PRODUCTS_TO_TAG: {
      if (status !== 'success') return state;

      return {
        ...state,
        tag: {
          ...state.tag,
          products: [...state.tag.products, ...payload],
        },
      };
    }

    case DELETE_PRODUCT_TAG: {
      if (status !== 'success') return state;

      const updatedProducts = state?.tag?.products?.filter(
        product => product.id !== payload
      );

      return {
        ...state,
        tag: {
          ...state.tag,
          products: updatedProducts,
        },
      };
    }

    default:
      return state;
  }
};

export default TagsReducer;
