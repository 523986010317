import {
  APP__START_FULFILLED,
  PRODUCT__ADD_FOR_USER_FULFILLED,
  USERS__ADMIN_EDIT_PROFILE,
  USERS__LOAD_ONE,
  USERS__LOAD_ESTHETICIANS,
  USERS__CHANGE_ROLE,
  USERS__UPDATE_ESTHETICIAN,
  USERS__LOAD_ALL_PENDING,
  USERS__LOAD_ALL_FULFILLED,
  USERS__SEARCH,
  USERS__UPDATE_PROFILE,
  USERS__CHANGE_TIER,
  ORDERS__SEARCH_FULFILLED,
  USERS__SUBMIT_LICENSE,
  USERS__UPDATE_LICENSE_STATUS,
  USERS_BASIC__LOAD_ONE,
  USERS__SUBMIT_ONBOARDING_DETAILS,
  USERS__SEARCH_SELECTOR,
  USERS__ADD_METADATA,
  USERS__LOAD_SURVEY_USER,
} from 'constants/actionTypes';
import { UserReducerInitialState } from './initialState';
import {
  SUBSCRIPTION_CANCEL,
  SUBSCRIPTION_CREATE,
} from '../../billing/actions/constants';
import {
  SEARCH_FOR_ESTHETICIANS,
  GET_ESTIE_PROFILE,
} from 'features/find-esties/actions/action-types';

import { COMPLETE_BRAND_EDUCATION } from '../../brand-educations/actions/action-types';
import { USER_LOADED_STATUS } from '../constants';

export const UserReducer = (
  state = UserReducerInitialState,
  { type, payload, status, requestPayload }
) => {
  switch (type) {
    case USERS__ADMIN_EDIT_PROFILE: {
      // If the action is pending or errored, no need to update the reducer
      if (status !== 'success') return state;
      const user = payload.data.adminUpdateUserDetails;

      // If User isn't found, don't save anything
      if (!user) return state;

      // Add Users into list
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [user.id]: { ...user, loadingStatus: USER_LOADED_STATUS.BASIC },
        },
      };
    }

    case USERS__LOAD_SURVEY_USER:
    case USERS__LOAD_ONE: {
      if (status === 'fail') {
        return {
          ...state,
          usersById: {
            ...state.usersById,
            [requestPayload.user]: {
              accessDenied: true,
            },
          },
        };
      }

      if (status === 'request') {
        return {
          ...state,
          usersById: {
            ...state.usersById,
            [payload.user ?? payload.id]: {
              // If the data is already there, don't overwrite it
              ...(state.usersById[payload.user ?? payload.id] ?? {}),
              loading: true,
            },
          },
        };
      }
      let user = payload;
      // If User isn't found, don't save anything
      if (!user || user === 'Error: User not found') return state;

      // Add Users into list
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [user.id]: { ...user, loadingStatus: USER_LOADED_STATUS.FULL },
        },
      };
    }

    case USERS__UPDATE_PROFILE: {
      if (status === 'request') return state;
      let user = payload;
      // If User isn't found, don't save anything
      if (!user) return state;

      // If a user updated their profile, merge their properties with updated information.
      if (user) {
        user = {
          ...state.usersById[user.id],
          ...user,
        };
      }

      // Add Users into list
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [user.id]: user,
        },
      };
    }

    case APP__START_FULFILLED:
    case USERS__LOAD_ESTHETICIANS: {
      // Stop Concurrent Loading Requests
      if (status === 'request')
        return {
          ...state,
          estheticiansLoaded: true,
        };
      // Stop esthetician console error if unauthed on App Start
      const estheticians = payload?.data?.estheticians || [];
      const estheticiansById = {};

      for (const esthetician of estheticians) {
        estheticiansById[esthetician.id] = {
          ...esthetician,
          loadingStatus: USER_LOADED_STATUS.BASIC,
        };
      }

      return {
        ...state,
        estheticiansLoaded: true,
        usersById: {
          ...state.usersById,
          ...estheticiansById,
        },
        estheticians,
      };
    }

    case USERS__UPDATE_ESTHETICIAN: {
      // If the action is pending or errored, no need to update the reducer
      if (status !== 'success') return state;
      const { id, estheticianId } = payload.data.updateEsthetician;
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [id]: {
            ...state.usersById[id],
            estheticianId,
          },
        },
      };
    }

    case USERS__CHANGE_ROLE: {
      // If the action is pending or errored, no need to update the reducer
      if (status !== 'success') return state;
      const { id, role } = payload.data.userChangeRole;
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [id]: {
            ...state.usersById[id],
            role,
          },
        },
      };
    }

    case USERS__CHANGE_TIER: {
      // If the action is pending or errored, no need to update the reducer
      if (status !== 'success') return state;
      const { id, tier } = payload.data;
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [id]: {
            ...state.usersById[id],
            tier,
          },
        },
      };
    }

    case PRODUCT__ADD_FOR_USER_FULFILLED: {
      const {
        cart: {
          user: { id: userId },
        },
        ...cartProduct
      } = payload.data.cartProduct;
      const user = state.usersById[userId];

      if (!user) return state;

      return {
        ...state,
        usersById: {
          ...state.usersById,
          [userId]: {
            ...user,
            cart: {
              ...user.cart,
              products: [...user.cart.products, cartProduct],
            },
          },
        },
      };
    }

    case USERS__LOAD_ALL_PENDING: {
      return {
        ...state,
        loadingAll: true,
      };
    }

    case USERS__LOAD_ALL_FULFILLED: {
      const {
        data: { users },
      } = payload;

      // Dedupe already fetched users
      const newUsers = users.reduce((accumulator, user) => {
        if (!state.usersById[user.id]) accumulator[user.id] = user;
        return accumulator;
      }, {});

      return {
        ...state,
        loadingAll: false,
        usersById: {
          ...state.usersById,
          ...newUsers,
        },
      };
    }

    case USERS__SEARCH: {
      if (status !== 'success') return state;

      const { data: users } = payload;
      const usersById = {};

      for (const user of users) {
        usersById[user.id] = {
          // TODO: Build a user comparison function to prevent overwriting data and determine loadStatus
          ...(state?.usersById?.[user.id] ?? {}),
          // If we've got the full user loaded, don't overwrite the loading status
          loadingStatus:
            state?.usersById[user.id]?.loadingStatus > USER_LOADED_STATUS.SEARCH
              ? state.usersById[user.id].loadingStatus
              : USER_LOADED_STATUS.SEARCH,
          ...user,
        };
      }

      return {
        ...state,
        usersById: {
          ...state.usersById,
          ...usersById,
        },
      };
    }

    case [ORDERS__SEARCH_FULFILLED]: {
      return {
        ...state,
        loadingAll: true,
      };
    }

    case ORDERS__SEARCH_FULFILLED: {
      if (!payload?.data?.orderSearch?.data) return state;
      const users = payload.data.orderSearch.data.map(order => order.user);
      // Dedupe already fetched users
      const newUsers = users.reduce((accumulator, user) => {
        if (!state.usersById[user.id])
          accumulator[user.id] = {
            ...user,
            loadingStatus: USER_LOADED_STATUS.SEARCH,
          };
        return accumulator;
      }, {});

      return {
        ...state,
        loadingAll: false,
        usersById: {
          ...state.usersById,
          ...newUsers,
        },
      };
    }

    case USERS__SUBMIT_LICENSE: {
      if (status === 'request') return state;

      const {
        data: { submitLicense = null },
      } = payload;

      let user;

      if (!submitLicense) return state;

      // If a license is updated, merge the user properties with updated information.
      if (submitLicense) {
        user = {
          ...state.usersById[submitLicense?.license?.userId],
          license: submitLicense?.license,
        };
      }

      // Add Users into list
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [submitLicense?.license.userId]: user,
        },
      };
    }

    case USERS__UPDATE_LICENSE_STATUS: {
      // If the action is pending or errored, no need to update the reducer
      if (status !== 'success') return state;

      const { userId } = payload.data.updateLicenseStatus;

      return {
        ...state,
        usersById: {
          ...state.usersById,
          [userId]: {
            ...state.usersById[userId],
            license: {
              ...payload.data.updateLicenseStatus,
            },
          },
        },
      };
    }

    case USERS_BASIC__LOAD_ONE: {
      if (status !== 'success') return state;

      return {
        ...state,
        usersById: {
          ...state.usersById,
          [payload.id]: {
            ...payload,
            loadingStatus: USER_LOADED_STATUS.BASIC,
          },
        },
      };
    }

    case SUBSCRIPTION_CANCEL:
    case SUBSCRIPTION_CREATE: {
      const { userId, productSubscriptions } = payload;

      const user = state.usersById[userId];
      if (status === 'request') {
        return {
          ...state,
          usersById: {
            ...state.usersById,
            [userId]: {
              ...(user ?? {}),
              loading: true,
            },
          },
        };
      }

      return {
        ...state,
        usersById: {
          ...state.usersById,
          [userId]: {
            ...user,
            productSubscriptions,
          },
        },
      };
    }

    case USERS__SEARCH_SELECTOR: {
      if (status !== 'success') return state;

      // It's empty, no need to alter the state.
      if (!payload?.length) return state;

      const usersById = {};
      for (const user of payload) {
        usersById[user.id] = {
          ...user,
          loadingStatus: USER_LOADED_STATUS.SEARCH,
        };
      }

      return {
        ...state,
        usersById: {
          ...state.usersById,
          ...usersById,
        },
      };
    }

    case USERS__SUBMIT_ONBOARDING_DETAILS: {
      if (status === 'request') return state;

      const {
        onboarding: { userId },
      } = payload;
      const user = state.usersById[userId];

      return {
        ...state,
        usersById: {
          ...state.usersById,
          [userId]: {
            ...user,
            onboarding: payload.onboarding,
          },
        },
      };
    }

    case USERS__ADD_METADATA: {
      if (status !== 'success') return state;

      const { id } = payload;

      return {
        ...state,
        usersById: {
          ...state.usersById,
          [id]: {
            ...state.usersById[id],
            ...payload,
          },
        },
      };
    }

    case COMPLETE_BRAND_EDUCATION: {
      if (status === 'request') return state;

      const { pricePoint, completion } = payload;
      let user = state.usersById[completion.userId];

      const brandEducation = { ...user?.brandEducation };
      const brandEducationId = completion.brandEducationId;

      // Update the catalog count
      const brandCatalogCount = brandEducation.brandCatalogCount;

      brandCatalogCount[pricePoint] = String(
        +brandCatalogCount[pricePoint] + 1
      );

      const updatedEducation = {
        ...brandEducation,
        completionCount: (brandEducation.completionCount ?? 0) + 1,
        completedCourses: {
          ...brandEducation.completedCourses,
          [brandEducationId]: completion.created,
        },
        brandCatalogCount: {
          ...brandCatalogCount,
        },
      };

      // If a user updated their profile, merge their properties with updated information.
      if (user) {
        user = {
          ...state.usersById[user.id],
          brandEducation: updatedEducation,
        };
      }

      // Add Users into list
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [user.id]: user,
        },
      };
    }

    case SEARCH_FOR_ESTHETICIANS: {
      if (status !== 'success') return state;
      const { inRange, outOfRange } = payload.data;
      const estheticianProfilesById = {};
      const results = [...inRange, ...outOfRange];
      for (const profile of results) {
        estheticianProfilesById[profile.userId] = {
          ...profile,
        };
      }

      return {
        ...state,
        estheticianProfilesById: {
          ...state.estheticianProfilesById,
          ...estheticianProfilesById,
        },
      };
    }

    case GET_ESTIE_PROFILE: {
      if (status !== 'success') return state;

      return {
        ...state,
        estheticianProfilesById: {
          ...state.estheticianProfilesById,
          [payload.userId]: payload,
        },
      };
    }

    default:
      return state;
  }
};
