import store from 'store';

export default () => {
  const headers = {
    Accepts: 'application/json',
    'Content-Type': 'application/json',
  };

  const jwt = store.getState().getIn(['authUser', 'jwt']);
  const impersonate = store.getState().getIn(['authUser', 'impersonate']);

  if (jwt !== null) headers.Authorization = `Bearer ${jwt}`;
  if (impersonate !== null) headers.impersonate = JSON.stringify(impersonate);

  return headers;
};
