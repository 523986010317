import {
  CREDITS_AVAILABLE,
  USERS__UPDATE_GIFTCARD_BALANCE,
  USERS__ADJUST_CREDIT,
} from 'constants/actionTypes';
import CreditsReducerInitialState from './initialState';
import { CREDIT_TYPES } from '@pomp-libs/core';

export default (
  state = CreditsReducerInitialState,
  { type, payload, status }
) => {
  if (status !== 'success') return state;

  switch (type) {
    case CREDITS_AVAILABLE: {
      if (!payload.data.creditsAvailable) return state;
      const { credits, creditTransactions } = payload.data.creditsAvailable;
      const creditBalance = credits.find(
        credit => credit.type === CREDIT_TYPES.credit
      );
      const balance = !creditBalance
        ? 0
        : creditBalance.amount - creditBalance.consumedAmount;

      return state.merge({
        creditsAvailable: credits,
        creditBalance: balance,
        creditTransactions,
      });
    }

    case USERS__UPDATE_GIFTCARD_BALANCE: {
      if (!payload.data.updateGiftCardBalance) return state;

      const { amount, creditTransactions = [] } =
        payload.data.updateGiftCardBalance;
      return state.merge({
        creditBalance: amount,
        creditTransactions,
      });
    }

    case USERS__ADJUST_CREDIT: {
      if (!payload) return state;

      const { balance, creditTransactions } = payload;
      return state.merge({
        creditBalance: balance,
        creditTransactions,
      });
    }

    default:
      return state;
  }
};
