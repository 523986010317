import { CART_ITEM_DELETE } from 'features/carts/action-creators';
import { v4 as uuid } from 'uuid';

export const GUEST_REMOVE_FROM_CART_INTERCEPTOR = {
  // The function that intercepts the request
  interceptFunc: ({ query, variables }) => {
    const { cartProduct } = variables;

    const request = {
      id: uuid(),
      query,
      type: CART_ITEM_DELETE,
      interceptorName: 'GUEST_REMOVE_FROM_CART_INTERCEPTOR',
      args: {
        cartProduct
      },
      response: true,
      requestVariables: [],
      responseVariables: []
    };

    return {
      response: true,
      request,
      variables: []
    };
  },
  // The function that is called when the response is received
  replaceFunc: () => null,
  // A function that is run to cleanup redundant requests (returns null if the request should be removed)
  normalizeFunc: () => null // Always remove the request, as it's only modifying a previous request
};
