import * as actionTypes from 'constants/actionTypes';
import cartPlaceOrder from 'graphQL/mutations/cartPlaceOrder';
import cartSetProductQuantity from 'graphQL/mutations/cartSetProductQuantity';
import graphql from 'lib/api/graphql';
import calcSalesTax from 'graphQL/queries/calcSalesTax';
import applyPromoCode from 'graphQL/mutations/applyPromoCode';
import removePromoCode from 'graphQL/mutations/removePromoCode';
import validatePromoCode from 'graphQL/mutations/validatePromoCode';
import loadAdjustments from 'graphQL/queries/load-checkout-adjustments';
import { generateAsyncAction } from 'lib/action-generators';

export default {
  placeOrder: paymentIntentId => dispatch => {
    return new Promise(resolve => {
      graphql(cartPlaceOrder, {
        paymentIntentId,
      }).then(result => {
        const d = dispatch({
          type: actionTypes.CHECKOUT__PLACE_ORDER,
          payload: result,
        });
        resolve(d);
      });
    });
  },

  setAddress: addressId => ({
    type: actionTypes.CHECKOUT__SET_ADDRESS,
    payload: { address: addressId },
  }),

  setProductQuantity: (cartProduct, quantity) => ({
    type: actionTypes.CHECKOUT__SET_PRODUCT_QUANTITY,
    payload: graphql(cartSetProductQuantity, { cartProduct, quantity }),
  }),

  calcSalesTax: (addressId, amount) => ({
    type: actionTypes.CHECKOUT__CALC_SALES_TAX,
    payload: graphql(calcSalesTax, { address: addressId, amount }),
  }),

  applyPromoCode: (promoCode, cartId, dateTime) => ({
    type: actionTypes.CHECKOUT__APPLY_PROMO_CODE,
    payload: graphql(applyPromoCode, { promoCode, cartId, dateTime }),
  }),

  removePromoCode: (promoCodeId, cartId) => ({
    type: actionTypes.CHECKOUT__REMOVE_PROMO_CODE,
    payload: graphql(removePromoCode, { promoCodeId, cartId }),
  }),

  validatePromoCode: (promoCodeId, cartId, dateTime, adjustments) => ({
    type: actionTypes.CART_VALIDATE_PROMO_CODE,
    payload: graphql(validatePromoCode, {
      promoCodeId,
      cartId,
      dateTime,
      adjustments,
    }),
  }),

  loadAdjustments: () =>
    generateAsyncAction(
      actionTypes.CHECKOUT__LOAD_ADJUSTMENTS,
      {},
      graphql(loadAdjustments)
    ),

  setSubscriptionIntervals: subscriptionIntervals => ({
    type: actionTypes.CHECKOUT_SET_SUBSCRIPTION_INTERVALS,
    payload: {
      subscriptionIntervals,
    },
  }),
};
