import { generateAsyncAction } from '../lib/action-generators';
import { DASHBOARD_LOADED } from '../constants/actionTypes';
import graphql from '../lib/api/graphql';
import loadDashboard from '../graphQL/queries/dashboard-load.graphql';
import loadDashboardClientList from '../graphQL/queries/dashboard-list.graphql';

export const dashboardActions = {
  loadDashboard: (from, to) =>
    generateAsyncAction(
      DASHBOARD_LOADED,
      { from, to },
      graphql(loadDashboard, { from, to })
    ),
  loadDashboardClientList: (key, page) =>
    generateAsyncAction(
      DASHBOARD_LOADED,
      { key, page },
      graphql(loadDashboardClientList, { key, page })
    )
};
