import Immutable from 'immutable';

export default Immutable.fromJS({
  step: 1,
  paypal: null,
  billingAsShipping: true,
  address: null,
  id: null,
  isLoading: false,
  paymentMethod: null,
  tax: { isLoading: false },
  adjustments: [],
  prevProducts: [],
  subscriptionIntervals: [],
  shipping: {},
});
