import { lazy } from 'react';
import { FEATURE_FLAGS } from '@pomp-libs/core';

const ViewCommissionPlanPage = lazy(() =>
  import(
    /* webpackChunkName: "ViewCommissionPlanPage" */ '../pages/view-commission-plan'
  ).then(({ ViewCommissionPlanPage }) => ({
    default: ViewCommissionPlanPage,
  }))
);

const CommissionPaymentsSearchPage = lazy(() =>
  import(
    /* webpackChunkName: "CommissionPaymentsSearchPage" */ '../pages/commission-payments-search-page'
  ).then(({ CommissionPaymentsSearchPage }) => ({
    default: CommissionPaymentsSearchPage,
  }))
);

const CommissionPlansSearchPage = lazy(() =>
  import(
    /* webpackChunkName: "CommissionPlansSearchPage" */ '../pages/commission-plans-search-page'
  ).then(({ CommissionPlansSearchPage }) => ({
    default: CommissionPlansSearchPage,
  }))
);

const CommissionSearchPage = lazy(() =>
  import(
    /* webpackChunkName: "CommissionSearchPage" */ '../pages/commission-search-page'
  ).then(({ CommissionSearchPage }) => ({
    default: CommissionSearchPage,
  }))
);

const CommissionUsersSearchPage = lazy(() =>
  import(
    /* webpackChunkName: "CommissionUsersSearchPage" */ '../pages/commission-users-search-page'
  ).then(({ CommissionUsersSearchPage }) => ({
    default: CommissionUsersSearchPage,
  }))
);

export const CommissionRoutes = [
  {
    component: CommissionSearchPage,
    isExact: true,
    key: 'commission',
    path: '/commission',
    flag: FEATURE_FLAGS.MANAGE_COMMISSION,
  },
  {
    component: CommissionPaymentsSearchPage,
    isExact: true,
    key: 'commission-payments',
    path: '/commission/payments',
    flag: FEATURE_FLAGS.MANAGE_COMMISSION,
  },
  {
    component: CommissionPlansSearchPage,
    isExact: true,
    key: 'commission-plans',
    path: '/commission/plans',
    flag: FEATURE_FLAGS.MANAGE_COMMISSION,
  },
  {
    component: CommissionUsersSearchPage,
    isExact: true,
    key: 'commission-users',
    path: '/commission/users',
    flag: FEATURE_FLAGS.MANAGE_COMMISSION,
  },

  {
    component: ViewCommissionPlanPage,
    isExact: true,
    key: 'view-commission-plan',
    path: '/commission/plans/:planId',
    flag: FEATURE_FLAGS.MANAGE_COMMISSION,
  },
];
