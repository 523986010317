import { CART_ITEM_DELETE, CART_ITEM_UPDATE } from '../action-creators';
import {
  APP__START_FULFILLED,
  AUTH_USER__LOGOUT_FULFILLED,
  USERS__SEARCH,
  USERS__LOAD_ONE,
  PRODUCT__EDIT_GIFTCARD,
  CHECKOUT__APPLY_PROMO_CODE_FULFILLED,
  CHECKOUT__REMOVE_PROMO_CODE_FULFILLED,
} from 'constants/actionTypes';
import { CartReducerInitialState } from './initial-state';

export const CartReducer = (
  state = CartReducerInitialState,
  { type, payload, requestPayload, status }
) => {
  switch (type) {
    case CART_ITEM_UPDATE: {
      if (!['success', 'intercepted'].includes(status)) return state;

      const { id, cart: payloadCart, ...cartProduct } = payload;
      const {
        user: { id: userId },
      } = payloadCart;

      const cartId = state.cartsByUserId[userId];
      const cart = state.cartsById[cartId];

      if (!cart) return state;

      const existingCartProduct = cart.products.find(
        ({ id: pid }) => pid === id
      );

      return {
        ...state,
        cartsById: {
          ...state.cartsById,
          [cart.id]: {
            ...state.cartsById[cartId],
            products: [
              ...cart.products.map(product => {
                if (product.id !== id) return product;
                return {
                  ...product,
                  ...cartProduct,
                };
              }),
              ...(existingCartProduct ? [] : [{ id, ...cartProduct }]),
            ],
          },
        },
      };
    }

    case CART_ITEM_DELETE: {
      if (!['success', 'intercepted'].includes(status)) return state;

      const { cartProduct: cartProductId } = requestPayload;

      const cart = Object.values(state.cartsById).find(({ products }) =>
        products.find(({ id }) => id === cartProductId)
      );

      if (!cart) return state;

      return {
        ...state,
        cartsById: {
          ...state.cartsById,
          [cart.id]: {
            ...state.cartsById[cart.id],
            products: [
              ...cart.products.filter(({ id }) => id !== cartProductId),
            ],
          },
        },
      };
    }

    case PRODUCT__EDIT_GIFTCARD: {
      if (!['success', 'intercepted'].includes(status)) return state;

      const { id: cartProductId, giftcardMetadata, product = {} } = payload;

      const cart = Object.values(state.cartsById).find(({ products }) =>
        products.find(({ id }) => id === cartProductId)
      );

      if (!cart) return state;

      return {
        ...state,
        cartsById: {
          ...state.cartsById,
          [cart.id]: {
            ...state.cartsById[cart.id],
            products: [
              ...cart.products.map(cp => {
                if (cp.id !== cartProductId) return cp;

                return {
                  ...cp,
                  giftcardMetadata,
                  product: {
                    ...cp.product,
                    ...product,
                  },
                };
              }),
            ],
          },
        },
      };
    }

    // When the app start event is complete, update the reducer with the data
    case APP__START_FULFILLED: {
      if (!payload?.data?.cart) return state;
      const {
        data: { cart },
      } = payload;

      return {
        ...state,
        cartsById: {
          ...state.cartsById,
          [cart.id]: cart,
        },
        cartsByUserId: {
          ...state.cartsByUserId,
          [cart?.userId ?? cart?.user?.id ?? 'guest']: cart.id,
        },
      };
    }

    case USERS__LOAD_ONE: {
      const { cart = null, id } = payload;
      if (!cart) return state;

      return {
        ...state,
        cartsById: {
          ...state.cartsById,
          [cart.id]: cart,
        },
        cartsByUserId: {
          ...state.cartsByUserId,
          [id]: cart.id,
        },
      };
    }

    case USERS__SEARCH: {
      if (status !== 'success') return state;

      const { data: users } = payload;

      const { cartsById, cartsByUserId } = users.reduce(
        ({ cartsById, cartsByUserId }, { cart, id }) => {
          if (!cart) return { cartsById, cartsByUserId };

          return {
            cartsById: {
              ...cartsById,
              [cart.id]: cart,
            },
            cartsByUserId: {
              ...cartsByUserId,
              [id]: cart.id,
            },
          };
        },
        { cartsById: {}, cartsByUserId: {} }
      );

      return {
        ...state,
        cartsById: {
          ...state.cartsById,
          ...cartsById,
        },
        cartsByUserId: {
          ...state.cartsByUserId,
          ...cartsByUserId,
        },
      };
    }

    case CHECKOUT__APPLY_PROMO_CODE_FULFILLED: {
      if (!payload.data.applyPromoCode) return state;
      const {
        applyPromoCode: { promoCode, cart },
      } = payload.data;

      return {
        ...state,
        cartsById: {
          ...state.cartsById,
          [cart.id]: {
            ...state.cartsById[cart.id],
            promoCode,
          },
        },
      };
    }

    case CHECKOUT__REMOVE_PROMO_CODE_FULFILLED: {
      if (!payload.data.removePromoCode) return state;
      const {
        removePromoCode: { cartId },
      } = payload.data;

      return {
        ...state,
        cartsById: {
          ...state.cartsById,
          [cartId]: {
            ...state.cartsById[cartId],
            promoCode: null,
          },
        },
      };
    }

    case AUTH_USER__LOGOUT_FULFILLED: {
      return CartReducerInitialState;
    }

    default:
      return state;
  }
};

export default CartReducer;
