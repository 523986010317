import * as actionTypes from './action-types';
import graphql from 'lib/api/graphql';
import promoCodesLoadAll from '../graphql/queries/promo-codes-load-all.graphql';
import promoCodeSave from '../graphql/mutations/promo-code.graphql';
import promoCodeDelete from '../graphql/mutations/promo-code-delete';
import promoSearch from '../graphql/queries/promo-search';
import { generateAsyncAction } from 'lib/action-generators';

export default {
  loadAll: () => ({
    type: actionTypes.PROMO_CODES__LOAD_ALL,
    payload: graphql(promoCodesLoadAll),
  }),

  save: promoCode => dispatch =>
    dispatch({
      type: actionTypes.PROMO_CODES__SAVE,
      payload: graphql(promoCodeSave, { promoCode }),
    }),

  search: searchQuery => ({
    type: actionTypes.PROMO__SEARCH,
    payload: graphql(promoSearch, searchQuery),
  }),

  delete: promoCodeId =>
    generateAsyncAction(
      actionTypes.PROMO_CODES__DELETE,
      { promoCodeId },
      graphql(promoCodeDelete, { promoCodeId }),
      'promoCodeDelete'
    ),
};
