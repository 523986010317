import { FEATURE_FLAGS } from '@pomp-libs/core';
import React from 'react';
import { ExternalPage } from '../components';

const ResourcesPage = () => (
  <ExternalPage
    name={'Resource Center'}
    displayUrl={
      'https://drive.google.com/embeddedfolderview?id=1xoE0Ptgtw6qROwp1q5dHrpY0NEgX8UxT#list'
    }
    url={
      'https://drive.google.com/drive/u/0/folders/1xoE0Ptgtw6qROwp1q5dHrpY0NEgX8UxT?ths=true'
    }
  />
);

const ElevatedResourcesPage = () => (
  <ExternalPage
    name={'Resource Center'}
    displayUrl={
      'https://drive.google.com/embeddedfolderview?id=1_bon3e1PeyeoP3PCYVj0dhrZrTMOkIaj#list'
    }
    url={
      'https://drive.google.com/drive/u/0/folders/1_bon3e1PeyeoP3PCYVj0dhrZrTMOkIaj?ths=true'
    }
  />
);

const TrainingPage = () => (
  <ExternalPage
    name={'Education'}
    url={'https://calendly.com/pompestheticians'}
  />
);

const TourPage = () => (
  <ExternalPage
    /** When updating the link, be sure to use /embed/:id rather than /share/:id, or the iframe cross domain permissions will prevent the Loom video from loading */
    url={'https://app.storylane.io/demo/ikkr8godwpk6'}
    name={'Tour'}
  />
);

export const ExternalLinkRoutes = [
  {
    component: ResourcesPage,
    isExact: true,
    key: 'resources',
    path: '/resources',
    flag: FEATURE_FLAGS.RESOURCES,
  },
  {
    component: ElevatedResourcesPage,
    isExact: true,
    key: 'resources',
    path: '/resources',
    flag: FEATURE_FLAGS.ELEVATED_RESOURCES,
  },
  {
    component: TrainingPage,
    isExact: true,
    key: 'training',
    path: '/training',
    flag: FEATURE_FLAGS.TRAINING,
  },
  {
    component: TourPage,
    isExact: true,
    key: 'tour',
    path: '/tour',
    flag: FEATURE_FLAGS.TOUR,
  },
];
