import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const EnhancementsStoreLandingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "Enhancments Store" */ 'features/enhancements-store/pages/enhancements-store'
    )
);

export const EnhancementStoreRoutes = [
  {
    component: EnhancementsStoreLandingPage,
    isExact: false,
    key: 'enhancements-store',
    path: '/shop',
    flag: FEATURE_FLAGS.ENHANCEMENTS_STORE,
  },
];
