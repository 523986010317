import * as actionTypes from 'constants/actionTypes';

export default {
  startLoader: () => ({
    type: actionTypes.LOADER__START
  }),

  stopLoader: () => ({
    type: actionTypes.LOADER__STOP
  })
};
