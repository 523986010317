import {
  ROUTINE_UPDATE,
  ROUTINE_DELETE,
  ROUTINE_UPDATE_BULK,
  ROUTINE_STEP_UPDATE,
  ROUTINE_STEP_DELETE,
} from '../action-creators';
import {
  RECOMMENDATION_UPDATE,
  RECOMMENDATION_DELETE,
} from 'features/recommendations/action-creators';
import { RoutineReducerInitialState } from './initial-state';

/**
 * Find a routine by the ID of it's step.
 *
 * @param {*} routinesById
 * @param {*} stepId
 * @returns
 */
const findRoutineByStepId = (routinesById, stepId) => {
  const routineId = Object.keys(routinesById).find(routineId =>
    routinesById[routineId]?.steps?.find(step => step.id === stepId)
  );
  return routinesById[routineId];
};

/**
 * Find a routine by the ID of it's step.
 *
 * @param {*} routinesById
 * @param {*} stepId
 * @returns
 */
const findRoutineByRecommendationId = (routinesById, recommendationId) => {
  const routineId = Object.keys(routinesById).find(routineId =>
    routinesById[routineId].steps.find(({ recommendations }) =>
      recommendations.find(({ id }) => id === recommendationId)
    )
  );
  return routinesById[routineId];
};

export const RoutineReducer = (
  state = RoutineReducerInitialState,
  { type, payload, requestPayload, status }
) => {
  switch (type) {
    case ROUTINE_UPDATE: {
      if (status === 'request') {
        const { id } = requestPayload;

        // Prevent on creation us adding a loading state for an undefined Id.
        if (!id) return state;

        return {
          ...state,
          routinesById: {
            ...state.routinesById,
            [id]: { ...(state?.routinesById?.[id] ?? {}), loading: true },
          },
        };
      }

      // If the action is pending or errored, no need to update the reducer
      if (status !== 'success') return state;

      const routine = payload;

      if (!routine) return state;

      return {
        ...state,
        routinesById: {
          ...state.routinesById,
          [routine.id]: { ...routine, loading: false },
        },
      };
    }

    case ROUTINE_UPDATE_BULK: {
      // If the action is pending or errored, no need to update the reducer
      if (status !== 'success') return state;

      const routines = payload;

      if (!routines) return state;

      const updated = {};
      for (const routine of routines) {
        updated[routine.id] = routine;
      }

      return {
        ...state,
        routinesById: {
          ...state.routinesById,
          ...updated,
        },
      };
    }

    case ROUTINE_DELETE: {
      // If the action is pending or errored, no need to update the reducer
      if (status !== 'success') return state;

      const { id } = requestPayload;

      if (!id) return state;

      delete state.routinesById[id];
      return state;
    }

    case ROUTINE_STEP_UPDATE: {
      // If the action is pending or errored, no need to update the reducer
      if (status !== 'success') return state;

      const step = payload;
      if (!step) return state;

      const { routineId } = step;
      const routine = state.routinesById[routineId];
      if (!routine) return state;

      const steps = routine.steps.map(s => (s.id === step.id ? step : s));
      if (steps.findIndex(s => s.id === step.id) === -1) {
        steps.push(step);
      }

      return {
        ...state,
        routinesById: {
          ...state.routinesById,
          [routineId]: {
            ...routine,
            steps,
          },
        },
      };
    }

    case ROUTINE_STEP_DELETE: {
      // If the action is pending or errored, no need to update the reducer
      if (status !== 'success') return state;

      const { id: stepId } = requestPayload;

      const routine = findRoutineByStepId(state.routinesById, stepId);

      if (!routine) return state;

      const steps = [];
      let removed = false;
      for (const s of routine.steps) {
        if (s.id === stepId) {
          removed = true;
          continue;
        }

        // If we've removed one step, then adjust the sort orders down for the rest.
        steps.push({
          ...s,
          sortOrder: removed ? s.sortOrder - 1 : s.sortOrder,
        });
      }

      return {
        ...state,
        routinesById: {
          ...state.routinesById,
          [routine.id]: {
            ...routine,
            steps,
          },
        },
      };
    }

    case RECOMMENDATION_UPDATE: {
      // If the action is pending or errored, no need to update the reducer
      if (status !== 'success') return state;

      const recommendation = payload;

      // if it's not a routine recommendation, then we don't care
      if (!recommendation || !recommendation?.routineStepId) return state;
      const { routineStepId } = recommendation;

      const routine = findRoutineByStepId(state.routinesById, routineStepId);
      if (!routine) return state;

      const steps = routine.steps.map(s => {
        // It's not the step that has been updated.
        if (s.id !== routineStepId) return s;

        const recommendations = s.recommendations.map(r =>
          r.id === recommendation.id ? recommendation : r
        );

        if (
          recommendations.findIndex(({ id }) => id === recommendation.id) === -1
        ) {
          recommendations.push(recommendation);
        }

        return {
          ...s,
          recommendations,
        };
      });

      return {
        ...state,
        routinesById: {
          ...state.routinesById,
          [routine.id]: {
            ...routine,
            steps,
          },
        },
      };
    }

    case RECOMMENDATION_DELETE: {
      // If the action is pending or errored, no need to update the reducer
      if (status !== 'success') return state;

      const { id } = requestPayload;

      // if it's not a routine recommendation, then we don't care
      if (!id) return state;

      const routine = findRoutineByRecommendationId(state.routinesById, id);
      if (!routine) return state;

      // Find the step the recommendation is associated with
      const step = routine.steps.find(({ recommendations }) =>
        recommendations.find(r => r.id === id)
      );

      const steps = routine.steps.map(s => {
        // It's not the step that has been updated.
        if (s.id !== step.id) return s;
        const recommendations = [];
        for (const r of s.recommendations) {
          if (r.id !== id) {
            recommendations.push(r);
          }
        }

        return {
          ...s,
          recommendations,
        };
      });

      return {
        ...state,
        routinesById: {
          ...state.routinesById,
          [routine.id]: {
            ...routine,
            steps,
          },
        },
      };
    }

    default:
      return state;
  }
};

export default RoutineReducer;
