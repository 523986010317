import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const TasksPage = lazy(() =>
  import(/* webpackChunkName: "RoutineView" */ '../../tasks/components').then(
    ({ TasksPage }) => ({
      default: TasksPage,
    })
  )
);

export const TasksRoutes = [
  {
    component: TasksPage,
    isExact: true,
    key: 'tasks',
    path: '/tasks',
    flag: FEATURE_FLAGS.TASKS,
  },
];
