import * as actionTypes from 'constants/actionTypes';
import createReducer from 'reducers/createReducer';
import initialState from './initialState';

export default createReducer(initialState, {
  [actionTypes.LOAD__SHOW_ALL]: (state, { payload: { showAll } }) =>
    state.merge({ showAll }),

  [actionTypes.LOAD__PRODUCT_UPDATED]: (
    state,
    { payload: { updatedProductId } }
  ) => state.merge({ updatedProductId }),

  [actionTypes.LOAD__FILTER]: (state, { payload: { filter } }) =>
    state.merge({ filter }),

  [actionTypes.LOAD__TYPE]: (state, { payload: { type } }) =>
    state.merge({ type }),

  [actionTypes.AUTH_USER__LOGOUT]: () => initialState
});
