import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const RoutineView = lazy(() =>
  import(
    /* webpackChunkName: "RoutineView" */ '../pages/treatment-room/components'
  ).then(({ RoutineView }) => ({
    default: RoutineView,
  }))
);

export const ClientRoutes = [
  {
    component: RoutineView,
    isExact: false,
    key: 'treatment-room',
    path: '/treatment-room/routines',
    flag: FEATURE_FLAGS.TREATMENT_ROOM,
  },
];
