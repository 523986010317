import Immutable from 'immutable';

export default Immutable.fromJS({
  filters: {
    query: null,
    vendorId: null,
    categoryId: null,
  },
  isSearching: false,
  needsContact: [],
  products: {
    count: 0,
    data: [],
  },
  productToEdit: null,
  user: {
    cart: {
      products: [],
    },
  },
  users: [],
  estheticians: [],
  totals: {},
  productsBasic: [],
});
