import { generateAsyncAction } from 'lib/action-generators';
import {
  LOAD_STORE_LANDING_PAGE,
  EDIT_STORE_LANDING_PAGE,
} from './action-types';
import loadStoreLandingPage from '../graphql/queries/load-store-landing-page.graphql';
import editStoreLandingPage from '../graphql/mutations/edit-store-landing-page.graphql';
import graphql from 'lib/api/graphql';

export default {
  loadStoreLandingPage: () =>
    generateAsyncAction(
      LOAD_STORE_LANDING_PAGE,
      {},
      graphql(loadStoreLandingPage),
      'loadStoreLandingPage'
    ),
  editStoreLandingPage: input =>
    generateAsyncAction(
      EDIT_STORE_LANDING_PAGE,
      { input },
      graphql(editStoreLandingPage, { input }),
      'editStoreLandingPage'
    ),
};
