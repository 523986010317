import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

const orderBasicPropTypes = {
  id: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  estheticianId: PropTypes.string,
  commissionPaid: PropTypes.bool,
  commissionType: PropTypes.string,
  user: ImmutablePropTypes.contains({
    id: PropTypes.string,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    estheticianId: PropTypes.string
  })
};

export const orderBasic = ImmutablePropTypes.contains(orderBasicPropTypes);

export const order = ImmutablePropTypes.contains({
  ...orderBasicPropTypes,
  address: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      name: PropTypes.string,
      state: PropTypes.string,
      city: PropTypes.string,
      street1: PropTypes.string,
      street2: PropTypes.string,
      zip: PropTypes.string,
      isBilling: PropTypes.bool
    })
  ),
  products: ImmutablePropTypes.listOf(
    ImmutablePropTypes.contains({
      quantity: PropTypes.number,

      product: ImmutablePropTypes.contains({
        id: PropTypes.string.isRequired,
        imageUrl: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,

        vendor: ImmutablePropTypes.contains({
          title: PropTypes.string.isRequired
        }).isRequired
      })
    })
  )
});

export const orders = ImmutablePropTypes.listOf(order);

export const orderState = ImmutablePropTypes.contains({
  isLoading: PropTypes.bool.isRequired,
  added: PropTypes.bool,
  amount: PropTypes.number,
  filter: PropTypes.object,
  type: PropTypes.string,
  orders
});
