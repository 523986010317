import * as actionTypes from 'constants/actionTypes';
import createReducer from 'reducers/createReducer';
import initialState from './initialState';
import Immutable from 'immutable';

export default createReducer(initialState, {
  [actionTypes.AUTH_USER__LOGOUT]: () => {
    return initialState;
  },

  [actionTypes.APP__START_PENDING]: state => {
    state.set('isLoading', true);
    return state;
  },

  [actionTypes.APP__START_FULFILLED]: (state, { payload }) => {
    if (!payload?.data) return state;
    const { data } = payload;

    let address = null;
    let paymentMethod = null;
    const addresses = data.addresses.filter(address => !!address.active);
    if (Array.isArray(addresses) && addresses.length > 0) {
      address = addresses.find(({ isPrimary }) => isPrimary) || null;
    }
    const paymentMethods = data.paymentMethods.filter(paymentMethod =>
      paymentMethod ? !!paymentMethod.active : paymentMethod
    );
    if (Array.isArray(paymentMethods) && paymentMethods.length > 0) {
      paymentMethod = paymentMethods[0];
    }

    // ADD previous Ordered Products state //
    const prevOrders = data?.orders ?? [];
    const prevProducts = [];

    prevOrders.forEach(order => {
      if (order?.products.length) {
        order.products.forEach(prod => {
          if (prod.quantity > 0 && !prod.product.isGift)
            prevProducts.push(prod);
        });
      }
    });

    const uniqProducts = Array.from(
      new Set(prevProducts.map(prod => prod.product.id))
    ).map(id => prevProducts.find(prod => prod.product.id === id));

    return state.merge({
      address: address !== null ? address.id : null,
      id: data?.cart?.id ?? 'guest',
      isLoading: false,
      paymentMethod: paymentMethod !== null ? paymentMethod.id : null,
      products: Immutable.fromJS(data?.cart?.products ?? []),
      adjustments: data?.cart?.adjustments
        ? Immutable.fromJS(data?.cart?.adjustments)
        : [],
      prevProducts: Immutable.fromJS(uniqProducts.slice(0, 8)),
      shipping: {
        shippingRate: address?.shipping?.shippingRate,
        orderThreshold: address?.shipping?.orderThreshold,
        freeShipping: address?.shipping?.freeShipping,
      },
    });
  },

  [actionTypes.CHECKOUT__SET_PRODUCT_QUANTITY_FULFILLED]: (
    state,
    { payload: { data } }
  ) =>
    state.updateIn(['products'], cartProducts =>
      cartProducts.map(cartProduct => {
        if (cartProduct.get('id') !== data.cartSetProductQuantity.id)
          return cartProduct;
        return cartProduct.set(
          'quantity',
          data.cartSetProductQuantity.quantity
        );
      })
    ),

  [actionTypes.ADDRESS__CREATE_FULFILLED]: (
    state,
    {
      payload: {
        data: { address },
      },
    }
  ) => {
    if (address === null || !address) return state;

    if (address.isBilling) {
      return state.set('billingAddress', address.id);
    }

    // return state.set('address', address.id);
    return state.merge({
      address: address.id,
      shipping: address.shipping,
    });
  },

  [actionTypes.ADDRESS__DELETE_FULFILLED]: (
    state,
    {
      payload: {
        data: { deleteAddress: addressId },
      },
    }
  ) => {
    if (!addressId) {
      return state;
    }
    if (state.get('address') === addressId) {
      return state.set('address', null);
    }
    if (state.get('billingAddress') === addressId) {
      return state.set('billingAddress', null);
    }
    return state;
  },

  [actionTypes.CHECKOUT__SET_ADDRESS]: (state, { payload: { address } }) =>
    state.set('address', address),

  [actionTypes.PAYMENT_METHOD__CREATE_FULFILLED]: (
    state,
    { payload: { data } }
  ) => {
    if (!data.paymentMethod) return state;
    return state.set('paymentMethod', data.paymentMethod.id);
  },

  [actionTypes.PAYMENT_METHOD__DELETE_FULFILLED]: (
    state,
    {
      payload: {
        data: { deletePaymentMethod: paymentMethodId },
      },
    }
  ) => {
    if (!paymentMethodId) return state;
    return state.get('paymentMethod') === paymentMethodId
      ? state.set('paymentMethod', null)
      : state;
  },

  [actionTypes.CHECKOUT__CALC_SALES_TAX_PENDING]: state => {
    return state.set(
      'tax',
      Immutable.fromJS({ amount_to_collect: 0, isLoading: true, errors: [] })
    );
  },

  [actionTypes.CHECKOUT__CALC_SALES_TAX_FULFILLED]: (
    state,
    { payload: res }
  ) => {
    if (!res.data || !res.data.calcSalesTax)
      return state.set(
        'tax',
        Immutable.fromJS({ isLoading: false, errors: res.errors })
      );
    return state.set(
      'tax',
      Immutable.fromJS({
        ...res.data.calcSalesTax,
        isLoading: false,
        errors: [],
      })
    );
  },

  [actionTypes.AUTH_USER__LOGOUT]: () => initialState,

  [actionTypes.CHECKOUT__PLACE_ORDER]: (state, { payload }) => {
    const { data, errors } = payload;
    if (
      data.placeOrder ||
      (errors && errors[0]?.extensions?.orderProcessingDone)
    ) {
      const prevProducts = state.get('prevProducts').toJS();
      const orderProducts = (data.placeOrder?.products || []).filter(
        product => !product.product.isGift && product.quantity > 0
      );

      const uniqProducts = [...(orderProducts ?? [])].reduce(
        (acc, product) => {
          const existing = acc.find(
            prod => prod.product.id === product.product.id
          );
          if (existing) return acc;

          return [...acc, product];
        },
        [...prevProducts]
      );

      return state.merge({
        step: 1,
        tax: { isLoading: false },
        products: [],
        adjustments: [],
        prevProducts: uniqProducts,
      });
    }
    return state;
  },

  [actionTypes.CHECKOUT__APPLY_PROMO_CODE_FULFILLED]: (
    state,
    { payload: { data } }
  ) => {
    if (data.applyPromoCode?.adjustments) {
      return state.set(
        'adjustments',
        Immutable.fromJS(data.applyPromoCode.adjustments)
      );
    }
    return state;
  },

  [actionTypes.CART_VALIDATE_PROMO_CODE_FULFILLED]: (
    state,
    { payload: { data, errors = null } }
  ) => {
    if (errors) return state;

    return state.set(
      'adjustments',
      Immutable.fromJS(data?.validatePromoCode?.adjustments ?? [])
    );
  },

  [actionTypes.CHECKOUT__REMOVE_PROMO_CODE_FULFILLED]: (
    state,
    { payload: { data } }
  ) => {
    if (!data.removePromoCode.error) {
      const adjustments = state
        .get('adjustments')
        .filter(adj => adj.get('source') !== 'promoCode');

      return state.set('adjustments', adjustments);
    }
    return state;
  },

  [actionTypes.CHECKOUT__LOAD_ADJUSTMENTS]: (
    state,
    { payload: { data }, status }
  ) => {
    if (status !== 'success') return state;

    return state.set('adjustments', Immutable.fromJS(data?.cart?.adjustments));
  },

  [actionTypes.CHECKOUT_SET_SUBSCRIPTION_INTERVALS]: (state, { payload }) => {
    if (!payload) return state;
    return state.merge({
      ...state,
      subscriptionIntervals: payload.subscriptionIntervals,
    });
  },
});
