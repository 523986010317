import { AccountRoutes } from 'features/accounts';
import { AuthRoutes } from 'features/auth';
import { BrandEducationRoutes } from 'features/brand-educations/routes';
import { CartRoutes } from 'features/carts';
import { CheckoutRoutes } from 'features/checkout';
import { ClientRoutes } from 'features/clients';
import { CustomLinksRoutes } from 'features/customLinks';
import { EnhancementStoreRoutes } from 'features/enhancements-store/routes';
import { DashboardRoutes } from 'features/dashboards';
import { ExternalLinkRoutes } from 'features/external-links';
import { MessagingRoutes } from 'features/messaging';
import { OnboardingRoutes } from 'features/esthetician-onboarding';
import { OrdersRoutes } from 'features/orders';
import { ProductRoutes } from 'features/products';
import { ProStoreRoutes } from 'features/pro-stores';
import { PromoRoutes } from 'features/promos';
import { ReportRoutes } from 'features/reports';
import { RoutinesRoutes } from 'features/routines';
import { SurveyRoutes } from 'features/surveys';
import { UserRoutes } from 'features/users';
import { NotificationRoutes } from 'features/notifications';
import { VendorRoutes } from 'features/vendors';
import { CategoryRoutes } from 'features/categories';
import { CommissionRoutes } from 'features/commission/routes';
import { TasksRoutes } from 'features/tasks/routes';
import { ToolRoutes } from 'features/tools/routes';
import { TagRoutes } from 'features/tags/routes';
import { FindEstieRoutes } from 'features/find-esties/routes';
import { BusinessRoutes } from 'features/businesses/routes';
import { ProgressPhotoRoutes } from 'features/progress-photos/routes';

export const AppRoutes = [
  ...AccountRoutes,
  ...AuthRoutes,
  ...BrandEducationRoutes,
  ...CartRoutes,
  ...CategoryRoutes,
  ...CheckoutRoutes,
  ...ClientRoutes,
  ...CommissionRoutes,
  ...CustomLinksRoutes,
  ...EnhancementStoreRoutes,
  ...DashboardRoutes,
  ...ExternalLinkRoutes,
  ...FindEstieRoutes,
  ...MessagingRoutes,
  ...NotificationRoutes,
  ...OnboardingRoutes,
  ...OrdersRoutes,
  ...NotificationRoutes,
  ...ProgressPhotoRoutes,
  ...ProStoreRoutes,
  ...ProductRoutes,
  ...PromoRoutes,
  ...ReportRoutes,
  ...RoutinesRoutes,
  ...SurveyRoutes,
  ...TasksRoutes,
  ...BusinessRoutes,
  ...ToolRoutes,
  ...TagRoutes,
  ...UserRoutes,
  ...VendorRoutes,
];
